import BigNumber from 'bignumber.js'
import fromPairs from 'lodash/fromPairs'
import { BigNumber as EthersBigNumber } from '@ethersproject/bignumber'
import { ChainId } from '@zinuswap/sdk'
import { getPoolConfig, getZinuPoolSousId, isZinuPoolSousId } from 'config/constants/pools'
import sousChefV2 from 'config/abi/sousChefV2.json'
import erc20ABI from 'config/abi/erc20.json'
import multicall, { multicallv2, multicallv3 } from 'utils/multicall'
import { BIG_ZERO } from '@zinuswap/utils/bigNumber'
import chunk from 'lodash/chunk'




export const fetchPoolsBlockLimits = async (chainId) => {
  const poolsConfig = await getPoolConfig(chainId);  
  const livePoolsWithEnd = poolsConfig.filter((p) => p.sousId !== getZinuPoolSousId(chainId) && !p.isFinished)

  const startEndBlockCalls = livePoolsWithEnd.flatMap((poolConfig) => {
    return [
      {
        address: poolConfig.contractAddress,
        name: 'startBlock',
      },
      {
        address: poolConfig.contractAddress,
        name: 'bonusEndBlock',
      },
    ]
  })

  const startEndBlockRaw = await multicall(sousChefV2, startEndBlockCalls, chainId)
  const startEndBlockResult = startEndBlockRaw.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / 2)

    if (!resultArray[chunkIndex]) {
      // eslint-disable-next-line no-param-reassign
      resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, [])

  return livePoolsWithEnd.map((zinuPoolConfig, index) => {
    const [[startBlock], [endBlock]] = startEndBlockResult[index]
    return {
      sousId: zinuPoolConfig.sousId,
      startBlock: startBlock.toNumber(),
      endBlock: endBlock.toNumber(),
    }
  })
}



export const fetchPoolsTotalStaking = async (chainId) => {
  const poolsConfig = await getPoolConfig(chainId);

  // const poolsBalanceOf = poolsConfig.map((poolConfig) => {
  //   return {
  //     address: poolConfig.stakingToken.address,
  //     name: 'balanceOf',
  //     params: [poolConfig.contractAddress],
  //   }
  // })
  
  // const poolsTotalStaked = await multicall(erc20ABI, poolsBalanceOf, chainId)
  
  const poolsBalanceOf = poolsConfig.map((poolConfig) => {
    if( isZinuPoolSousId(poolConfig.sousId) ) {
      return {
        abi: erc20ABI,
        address: poolConfig.stakingToken.address,
        name: 'balanceOf',
        params: [poolConfig.contractAddress],
      }
    }

    return {
      abi: sousChefV2,
      address: poolConfig.contractAddress,
      name: 'stakedTokenSupply'
    }
  })
  
  const poolsTotalStaked = await multicallv3({calls: poolsBalanceOf, chainId})  

  return poolsConfig.map((p, index) => ({
    sousId: p.sousId,
    totalStaked: new BigNumber(poolsTotalStaked[index]).toJSON(),
  }))
}

export const fetchPoolsStakingLimits = async (
  poolsWithStakingLimit: number[],
  chainId: ChainId
): Promise<{ [key: string]: { stakingLimit: BigNumber; numberBlocksForUserLimit: number } }> => {
  const poolsConfig = await getPoolConfig(chainId);
  const validPools = poolsConfig
    .filter((p) => p.stakingToken.symbol !== 'BNB' && !p.isFinished)
    .filter((p) => !poolsWithStakingLimit.includes(p.sousId))
    
  // Get the staking limit for each valid pool
  const poolStakingCalls = validPools
    .map((validPool) => {
      return ['hasUserLimit', 'poolLimitPerUser', 'numberBlocksForUserLimit'].map((method) => ({
        address: validPool.contractAddress,
        name: method,
      }))
    })
    .flat()
    
  const poolStakingResultRaw = await multicallv2({
    abi: sousChefV2,
    calls: poolStakingCalls,
    options: { requireSuccess: false },
    chainId
  })
  const chunkSize = poolStakingCalls.length / validPools.length
  const poolStakingChunkedResultRaw = chunk(poolStakingResultRaw.flat(), chunkSize)
  return fromPairs(
    poolStakingChunkedResultRaw.map((stakingLimitRaw, index) => {
      const hasUserLimit = stakingLimitRaw[0]
      const stakingLimit = hasUserLimit && stakingLimitRaw[1] ? new BigNumber(stakingLimitRaw[1].toString()) : BIG_ZERO
      const numberBlocksForUserLimit = stakingLimitRaw[2] ? (stakingLimitRaw[2] as EthersBigNumber).toNumber() : 0
      return [validPools[index].sousId, { stakingLimit, numberBlocksForUserLimit }]
    }),
  )
}

