import BigNumber from 'bignumber.js'
import { SerializedLockedVaultUser, SerializedVaultUser } from 'state/types'
import { getZinuVaultAddress, getZinuFlexibleSideVaultAddress } from 'utils/addressHelpers'
import zinuVaultAbi from 'config/abi/zinuVaultV2.json'
import zinuFlexibleSideVaultAbi from 'config/abi/zinuFlexibleSideVaultV2.json'
import { multicallv2 } from 'utils/multicall'
import { ChainId } from '@zinuswap/sdk'




export const fetchVaultUser = async (account: string, chainId: ChainId): Promise<SerializedLockedVaultUser> => {
  try {
    const zinuVaultAddress = getZinuVaultAddress(chainId)
    const calls = ['userInfo', 'calculatePerformanceFee', 'calculateOverdueFee'].map((method) => ({
      address: zinuVaultAddress,
      name: method,
      params: [account],
    }))

    const [userContractResponse, [currentPerformanceFee], [currentOverdueFee]] = await multicallv2({
      abi: zinuVaultAbi,
      calls,
      chainId
    })
    
    return {
      isLoading: false,
      userShares: new BigNumber(userContractResponse.shares.toString()).toJSON(),
      lastDepositedTime: userContractResponse.lastDepositedTime.toString(),
      lastUserActionTime: userContractResponse.lastUserActionTime.toString(),
      zinuAtLastUserAction: new BigNumber(userContractResponse.zinuAtLastUserAction.toString()).toJSON(),
      userBoostedShare: new BigNumber(userContractResponse.userBoostedShare.toString()).toJSON(),
      locked: userContractResponse.locked,
      lockEndTime: userContractResponse.lockEndTime.toString(),
      lockStartTime: userContractResponse.lockStartTime.toString(),
      lockedAmount: new BigNumber(userContractResponse.lockedAmount.toString()).toJSON(),
      currentPerformanceFee: new BigNumber(currentPerformanceFee.toString()).toJSON(),
      currentOverdueFee: new BigNumber(currentOverdueFee.toString()).toJSON(),
    }
  } catch (error) {
    return {
      isLoading: true,
      userShares: null,
      lastDepositedTime: null,
      lastUserActionTime: null,
      zinuAtLastUserAction: null,
      userBoostedShare: null,
      lockEndTime: null,
      lockStartTime: null,
      locked: null,
      lockedAmount: null,
      currentPerformanceFee: null,
      currentOverdueFee: null,
    }
  }
}

export const fetchFlexibleSideVaultUser = async (account: string, chainId: ChainId): Promise<SerializedVaultUser> => {
  try {
    const flexibleSideZinuVaultAddress = getZinuFlexibleSideVaultAddress(chainId)
    const calls = ['userInfo'].map((method) => ({
      address: flexibleSideZinuVaultAddress,
      name: method,
      params: [account],
    }))

    const [userContractResponse] = await multicallv2({
      abi: zinuFlexibleSideVaultAbi,
      calls,
      chainId
    })

    return {      
      isLoading: false,
      userShares: new BigNumber(userContractResponse.shares.toString()).toJSON(),
      lastDepositedTime: userContractResponse.lastDepositedTime.toString(),
      lastUserActionTime: userContractResponse.lastUserActionTime.toString(),
      zinuAtLastUserAction: new BigNumber(userContractResponse.zinuAtLastUserAction.toString()).toJSON(),
    }
  } catch (error) {
    return {
      isLoading: true,
      userShares: null,
      lastDepositedTime: null,
      lastUserActionTime: null,
      zinuAtLastUserAction: null,
    }
  }
}
