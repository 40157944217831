import { createSelector } from '@reduxjs/toolkit'
import { isZinuPoolSousId } from 'config/constants/pools'
import { State, VaultKey } from '../types'
import { transformPool, transformVault } from './helpers'
import { initialPoolVaultState } from './index'
import { getVaultPosition, VaultPosition } from '../../utils/zinuPool'

const selectPoolsData = (state: State) => state.pools.data
const selectPoolData = (sousId) => (state: State) => state.pools.data.find((p) => p.sousId === sousId)
const selectUserDataLoaded = (state: State) => state.pools.userDataLoaded
const selectVault = (key: VaultKey) => (state: State) => key ? state.pools[key] : initialPoolVaultState

export const makePoolWithUserDataLoadingSelector = (sousId) =>
  createSelector([selectPoolData(sousId), selectUserDataLoaded], (pool, userDataLoaded) => {
    return { pool: transformPool(pool), userDataLoaded }
  })

export const poolsWithUserDataLoadingSelector = createSelector(
  [selectPoolsData, selectUserDataLoaded],
  (pools, userDataLoaded) => {
    return { pools: pools.map(transformPool), userDataLoaded }
  },
)

export const makeVaultPoolByKey = (key) => createSelector([selectVault(key)], (vault) => transformVault(key, vault))

export const poolsWithVaultSelector = createSelector(
  [
    poolsWithUserDataLoadingSelector,
    makeVaultPoolByKey(VaultKey.ZinuVault),
    makeVaultPoolByKey(VaultKey.ZinuFlexibleSideVault),
  ],
  (poolsWithUserDataLoading, deserializedLockedZinuVault, deserializedFlexibleSideZinuVault) => {
    const { pools, userDataLoaded } = poolsWithUserDataLoading
    if (pools.length < 1) return {pools, userDataLoaded}
    
    const zinuPool = pools.find((pool) => !pool.isFinished && isZinuPoolSousId(pool.sousId))
    const withoutZinuPool = pools.filter((pool) => !isZinuPoolSousId(pool.sousId))

    const zinuAutoVault = {
      ...zinuPool,
      ...deserializedLockedZinuVault,
      vaultKey: VaultKey.ZinuVault,
      userData: { ...zinuPool.userData, ...deserializedLockedZinuVault.userData },
    }

    const lockedVaultPosition = getVaultPosition(deserializedLockedZinuVault.userData)
    const hasFlexibleSideSharesStaked = deserializedFlexibleSideZinuVault.userData.userShares.gt(0)

    const zinuAutoFlexibleSideVault =
      lockedVaultPosition > VaultPosition.Flexible || hasFlexibleSideSharesStaked
        ? [
            {
              ...zinuPool,
              ...deserializedFlexibleSideZinuVault,
              vaultKey: VaultKey.ZinuFlexibleSideVault,
              userData: { ...zinuPool.userData, ...deserializedFlexibleSideZinuVault.userData },
            },
          ]
        : []

    // return { pools: [zinuAutoVault, ...zinuAutoFlexibleSideVault, ...withoutZinuPool], userDataLoaded } // COMINGSOON-SD
    return { pools: [...withoutZinuPool], userDataLoaded }
  },
)

export const makeVaultPoolWithKeySelector = (vaultKey) =>
  createSelector(poolsWithVaultSelector, ({ pools }) => pools.find((p) => p.vaultKey === vaultKey))
