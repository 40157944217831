import { ChainId } from '@zinuswap/sdk'
import { Pool } from '@zinuswap/uikit'
import addresses from 'config/constants/contracts'
import { VaultKey } from 'state/types'

export const getAddress = (address: Pool.Address, chainId: number): string => {
  return address[chainId] ? address[chainId] : address[ChainId.BSC]
}

export const getMasterChefAddress = (chainId: number) => {
  return getAddress(addresses.masterChef, chainId)
}
export const getMulticallAddress = (chainId: number) => {
  return getAddress(addresses.multiCall, chainId)
}

export const getVaultPoolAddress = (vaultKey: VaultKey, chainId) => {
  if (!vaultKey) {
    return null
  }
  return getAddress(addresses[vaultKey], chainId)
}

export const getZinuVaultAddress = (chainId: number) => {
  return getAddress(addresses.zinuVault, chainId)
}

export const getZinuFlexibleSideVaultAddress = (chainId: number) => {
  return getAddress(addresses.zinuFlexibleSideVault, chainId)
}

export const getZapAddress = (chainId: number) => {
  return getAddress(addresses.zap, chainId)
}

export const getBZinuFarmBoosterAddress = (chainId: number) => {
  return getAddress(addresses.bZinuFarmBooster, chainId)
}

export const getBZinuFarmBoosterProxyFactoryAddress = (chainId: number) => {
  return getAddress(addresses.bZinuFarmBoosterProxyFactory, chainId)
}
