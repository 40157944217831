import { ChainId, ERC20Token, WETH9 } from '@zinuswap/sdk'
import { USDC_GOERLI, ZINU_GOERLI } from './common'

export const goerliTestnetTokens = {
  zinu: ZINU_GOERLI,
  weth: WETH9[ChainId.GOERLI],
  celr: new ERC20Token(ChainId.GOERLI, '0x5D3c0F4cA5EE99f8E8F59Ff9A5fAb04F6a7e007f', 18, 'CELR', 'CelerToken', ''),
  leet: new ERC20Token(ChainId.GOERLI, '0xBd509651E6374c327d24b9d7E3Ea46704f6F31E8', 18, 'LEET', 'Leet Token', ''),
  usdc: USDC_GOERLI,
  syrup: new ERC20Token(
    ChainId.GOERLI,
    '0x09806097Ee48B83C03eAc963f972Ad2465Ac7417',
    18,
    'SYRUP',
    'SyrupBar Token',
    'https://zinustaking.com/',
  ),
}
