import { BigNumber } from '@ethersproject/bignumber'
import { ChainId } from '@zinuswap/sdk'
import { SerializedWrappedToken } from '@zinuswap/token-lists'
import { SerializedPoolConfig } from '@zinuswap/uikit/src/widgets/Pool'
import Trans from 'components/Trans'
import { VaultKey } from 'state/types'
import { bscTokens } from '@zinuswap/tokens'


export const MAX_LOCK_DURATION = 31536000
export const UNLOCK_FREE_DURATION = 604800
export const ONE_WEEK_DEFAULT = 604800
export const BOOST_WEIGHT = BigNumber.from('20000000000000')
export const DURATION_FACTOR = BigNumber.from('31536000')


let logged = false

export const vaultPoolConfig = {
  [VaultKey.ZinuVaultV1]: {
    name: <Trans>Auto ZINU</Trans>,
    description: <Trans>Automatic restaking</Trans>,
    autoCompoundFrequency: 5000,
    gasLimit: 380000,
    tokenImage: {
      primarySrc: `/images/56/tokens/${bscTokens.zinu.address}.svg`,
      secondarySrc: '/images/tokens/autorenew.svg',
    },
  },
  [VaultKey.ZinuVault]: {
    name: <Trans>Stake ZINU</Trans>,
    description: <Trans>Stake, Earn – And more!</Trans>,
    autoCompoundFrequency: 5000,
    gasLimit: 800000,
    tokenImage: {
      primarySrc: `/images/56/tokens/${bscTokens.zinu.address}.svg`,
      secondarySrc: '/images/tokens/autorenew.svg',
    },
  },
  [VaultKey.ZinuFlexibleSideVault]: {
    name: <Trans>Flexible ZINU</Trans>,
    description: <Trans>Flexible staking on the side.</Trans>,
    autoCompoundFrequency: 5000,
    gasLimit: 1100000, // CHECKED-SD
    tokenImage: {
      primarySrc: `/images/56/tokens/${bscTokens.zinu.address}.svg`,
      secondarySrc: '/images/tokens/autorenew.svg',
    },
  },
} as const


export const getPoolConfig = async (chainId: ChainId) => {
  try {
    return (await import(`/${chainId}.ts`)).default.filter(
      (f: SerializedPoolConfig<SerializedWrappedToken>) => f.sousId !== null,
    ) as SerializedPoolConfig<SerializedWrappedToken>[]
  } catch (error) {
    if (!logged) {
      console.error('Cannot get pool config', error, chainId)
      logged = true
    }
    return []
  }
}

export const getLivePoolConfig = async (chainId: ChainId) => {
  try {
    return (await import(`/${chainId}.ts`)).livePools.filter(
      (f: SerializedPoolConfig<SerializedWrappedToken>) => f.sousId !== null,
    ) as SerializedPoolConfig<SerializedWrappedToken>[]
  } catch (error) {
    if (!logged) {
      console.error('Cannot get pool config', error, chainId)
      logged = true
    }
    return []
  }
}

export const getZinuPoolSousId = (chainId: ChainId) => {
  if (chainId === ChainId.BSC) return 0;
  if (chainId === ChainId.BSC_TESTNET) return 1000;
  if (chainId === ChainId.ETHEREUM) return 2000;
  if (chainId === ChainId.GOERLI) return 3000;

  return 0;
}

export const isZinuPoolSousId = (sousId) => {
  if (sousId === 0 || sousId === 1000 || sousId === 2000 || sousId === 3000) return true;

  return false;
}