import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    // <Svg viewBox="0 0 96 96" {...props}>
    //   <circle cx={48} cy={48} r={48} fill="url(#paint0_linear_10493)" />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M47.858 79.875c-9.342-.007-16.866-2.249-22.124-6.275-5.321-4.075-8.144-9.857-8.144-16.4 0-6.304 2.817-10.85 6.004-13.923 2.497-2.408 5.253-3.95 7.172-4.838a99.818 99.818 0 01-1.46-4.876c-.648-2.41-1.284-5.237-1.284-7.309 0-2.452.535-4.915 1.977-6.829 1.523-2.021 3.816-3.104 6.574-3.104 2.156 0 3.986.8 5.42 2.179 1.369 1.318 2.28 3.07 2.91 4.895 1.106 3.208 1.537 7.238 1.657 11.26h2.643c.12-4.022.551-8.052 1.657-11.26.63-1.825 1.541-3.577 2.91-4.895 1.434-1.38 3.264-2.18 5.42-2.18 2.758 0 5.051 1.084 6.574 3.105 1.442 1.914 1.977 4.377 1.977 6.83 0 2.071-.636 4.898-1.284 7.308a99.707 99.707 0 01-1.46 4.876c1.919.888 4.675 2.43 7.172 4.838 3.187 3.073 6.004 7.619 6.004 13.923 0 6.543-2.823 12.325-8.144 16.4-5.257 4.026-12.782 6.268-22.124 6.275h-.047z"
    //     fill="#633001"
    //   />
    //   <path
    //     d="M36.573 18.653c-4.04 0-5.9 3.045-5.9 7.256 0 3.347 2.16 10.05 3.048 12.66.199.587-.114 1.23-.686 1.458-3.238 1.29-12.794 6.012-12.794 16.828 0 11.393 9.711 19.983 27.619 19.997h.043c17.908-.014 27.619-8.604 27.619-19.997 0-10.816-9.556-15.539-12.794-16.828a1.176 1.176 0 01-.686-1.458c.887-2.61 3.048-9.313 3.048-12.66 0-4.211-1.86-7.256-5.9-7.256-5.816 0-7.266 8.322-7.37 17.254a1.084 1.084 0 01-1.074 1.08h-5.73c-.59 0-1.067-.484-1.074-1.08-.103-8.932-1.553-17.254-7.369-17.254z"
    //     fill="#D1884F"
    //   />
    //   <path
    //     d="M47.903 73.202c-13.158 0-27.64-7.115-27.662-16.326v.043c0 11.403 9.727 19.997 27.662 19.997s27.661-8.594 27.661-19.997v-.043c-.022 9.21-14.503 16.326-27.661 16.326z"
    //     fill="#FEDC90"
    //   />
    //   <path
    //     d="M40.592 54.047c0 3.11-1.455 4.73-3.25 4.73-1.794 0-3.249-1.62-3.249-4.73 0-3.11 1.455-4.73 3.25-4.73 1.794 0 3.249 1.62 3.249 4.73zM61.712 54.047c0 3.11-1.455 4.73-3.25 4.73-1.794 0-3.248-1.62-3.248-4.73 0-3.11 1.454-4.73 3.249-4.73 1.794 0 3.25 1.62 3.25 4.73z"
    //     fill="#633001"
    //   />
    //   <defs>
    //     <linearGradient id="paint0_linear_10493" x1={48} y1={0} x2={48} y2={96} gradientUnits="userSpaceOnUse">
    //       <stop stopColor="#53DEE9" />
    //       <stop offset={1} stopColor="#1FC7D4" />
    //     </linearGradient>
    //   </defs>
    // </Svg>
    <Svg viewBox="0 0 600 600" {...props}>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#FD7701" d="M597.699,284.255c2.566,66.655-13.203,128.588-52.063,182.95
   c-67.885,94.968-161.381,140.334-277.376,127.444C144.404,580.887,60.334,511.105,17.636,394.101
   c-23.239-63.681-21.211-128.635,0.361-192.869c7.311-21.766,17.631-42.14,29.5-61.752l-0.004,0.004
   c0.854-1.322,1.709-2.644,2.564-3.967c0.648-0.982,1.296-1.966,1.944-2.949c0.457-0.667,0.914-1.333,1.371-2
   c10.063-13.53,20.173-27.022,32.255-38.875c0.593-0.581,1.337-1.006,2.011-1.504c-14.66,26.404-23.472,54.752-28.091,84.494
   c-0.488,0.59-1.112,1.111-1.447,1.778c-27.045,53.896-35.876,110.736-25.149,169.98c36.521,201.696,271.161,292.012,433.654,166.93
   c46.432-35.741,77.74-82.296,94.076-138.726c21.254-77.035,11.33-149.796-29.924-218.262c-5.176-20.705-12-40.794-21.699-59.878
   c-6.029-11.865-14.951-21.379-24.018-30.828l0.018-0.019c33.281,25.883,59.344,57.82,78.918,95.028
   C584.314,199.346,595.4,240.625,597.699,284.255z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#FD7701" d="M130.438,53.497c36.707-25.471,77.134-41.921,121.26-48.633
   C337.252-8.15,414.576,11.739,483.492,64.14c0.574,0.437,1.047,1.008,1.566,1.517l-0.018,0.019
   c-1.223-0.683-2.494-1.295-3.662-2.061c-10.281-6.733-21.244-12.891-33.756-10.198c-6.711,1.445-3.225,9.803-4.861,14.877
   c-42.18-25.754-87.955-39.425-137.379-40.341c-56.134-1.042-107.665,14.211-154.782,44.613c-1.866-6.159,2.273-13.819-4.491-18.717
   C141.051,50.186,135.665,53.798,130.438,53.497z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#815815" d="M130.438,53.497c5.227,0.301,10.613-3.311,15.672,0.352
   c6.764,4.898,2.625,12.559,4.49,18.717c0.682,2.548,1.363,5.097,2.044,7.646c1.875-1.162,3.714-2.391,5.634-3.473
   c5.505-3.104,9.198-12.509,17.745-5.164c4.119,4.762,8.238,9.524,12.356,14.286c3.04,4.236,6.081,8.473,9.121,12.709
   c-1.163,1.478-2.326,2.956-3.489,4.434c-1.581,1.806-3.162,3.611-4.743,5.417c-2.528-8.242-7.015-15.434-11.423-22.784
   c-8.404-14.015-13.942,2.15-20.983,1.564c-1.706-0.142-3.457,0.245-5.187,0.388c1.821,0.788,3.665,1.528,5.459,2.373
   c11.388,5.363,19.552,14.615,28.127,23.369c-1.34,1.805-2.68,3.611-4.019,5.417c-1.899-2.292-3.679-4.698-5.735-6.839
   c-1.58-1.645-3.506-2.958-5.277-4.419c1.13,1.899,2.336,3.759,3.373,5.708c1.597,3.003,3.077,6.069,4.607,9.108
   c-1.363,1.758-2.726,3.517-4.089,5.275c-2.882-11.844-9.479-21.402-19.226-28.357c-5.64-4.024-12.259-7.106-19.666-3.635
   c-0.92,0.112-1.839,0.225-2.759,0.337c-3.268,0.26-5.827,1.631-7.322,4.67l-0.12,0.118c-0.289,0.273-0.577,0.547-0.866,0.821
   c-0.228,0.219-0.455,0.438-0.683,0.657c-0.686,0.633-1.372,1.266-2.058,1.9c-0.071,0.102-0.158,0.188-0.26,0.257
   c-0.31,0.348-0.621,0.696-0.931,1.044c-0.542,0.573-1.084,1.145-1.626,1.718c-5.679,4.989-9.608,11.206-12.662,18.037
   c-0.217,0.472-0.433,0.944-0.65,1.415c-0.288,0.35-0.575,0.7-0.863,1.05l-0.017,0.017c-0.651,0.972-1.301,1.944-1.952,2.917
   c-0.052,0.137-0.088,0.278-0.108,0.424c-0.451,0.686-0.902,1.372-1.354,2.058c-6.65,17.237-11.49,34.919-13.097,53.393
   c-1.89,13.276,1.002,26.063,3.982,38.84c-3.207,3.644-6.415,7.288-9.623,10.931c-0.243,0.15-0.486,0.299-0.729,0.449
   c0,0,0.012,0.159,0.012,0.159c-0.391-7.756-0.998-15.441-4.5-22.608c-2.045-4.184-3.744-8.536-5.599-12.812
   c-1.062,4.461-2.489,8.873-3.116,13.394c-1.683,12.127-2.313,24.224,3.793,35.555c-1.813,2.557-3.627,5.113-5.44,7.67
   c-2.313-9.12-5.46-18.123-6.764-27.386c-2.613-18.562-2.083-37.273-0.368-55.932c4.619-29.742,13.431-58.09,28.091-84.494
   l-0.038,0.09c0.19-0.361,0.381-0.722,0.572-1.083C98.985,73.417,112.237,60.527,130.438,53.497z M137.16,71.787
   c0.521,5.622,0.701,10.97,6.28,13.823c0.42,0.214,0.934,0.244,1.404,0.36c0.035-0.193,0.169-0.494,0.093-0.563
   c-4.655-4.196-2.418-9.954-3.365-14.978c-0.635-3.373-0.955-6.805-1.416-10.211C136.362,63.519,138.442,68.239,137.16,71.787z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#023D67" d="M50.057,135.517c-0.855,1.323-1.709,2.645-2.564,3.967
   C47.631,137.698,47.764,135.909,50.057,135.517z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#023D67" d="M53.372,130.567c-0.457,0.667-0.914,1.333-1.371,2
   C52.458,131.9,52.915,131.234,53.372,130.567z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#FD7701" d="M88.173,89.195c-0.191,0.36-0.382,0.721-0.572,1.083
   C87.792,89.917,87.982,89.556,88.173,89.195z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#004B80" d="M59.548,174.682c-1.715,18.659-2.245,37.37,0.368,55.932
   c1.304,9.263,4.451,18.266,6.763,27.384c-0.532,1.485-1.064,2.973-1.596,4.46c-0.909,5.831,0.594,11.349,2.187,16.854
   c0.097,0.923,0.193,1.846,0.29,2.769c0.154,0.721,0.309,1.442,0.463,2.163l0.017,0.03c0.162,0.729,0.324,1.459,0.487,2.188
   l0.005,0.015c0.701,8.465,1.245,16.947,2.19,25.384c0.33,2.94,1.653,5.769,2.523,8.649c1.189,0.354,3.682-1.498,3.109,1.836
   c-0.588,5.37-1.175,10.739-1.763,16.109c0.139,5.145-0.395,10.406,2.608,15.055c-0.135,2.006-0.269,4.012-0.403,6.019
   c-0.591,8.307-1.183,16.614-1.774,24.921c0.116,10.334,0.233,20.669,0.35,31.004c1.793,17.481,7.966,33.233,18.638,47.211
   c6.681,10.38,15.299,18.942,25.385,25.906c28.593,19.742,62.245,26.811,94.613,36.817c50.279,15.544,100.692,25.024,152.456,4.285
   c23.615-9.461,48.537-15.658,72.865-23.335c34.316-12.16,60.217-33.691,73.164-68.351c11.793-31.564,5.719-64.07,1.926-96.036
   c-2.984-25.148-5.635-49.347,7.502-72.958c8.227-14.788,12.754-31.289,12.705-48.612l-0.018,0.053
   c0.248-5.337,0.494-10.674,0.742-16.01c0.012-0.174,0.025-0.347,0.037-0.522c0.061-3.183,0.119-6.365,0.18-9.547
   c0.838,0.83,1.955,1.514,2.469,2.51c12.209,23.603,19.904,48.763,24.342,74.82c4.139,24.301,5.139,48.832,1.842,73.407
   c-1.32,9.832-2.367,19.701-3.537,29.553c-16.336,56.43-47.645,102.985-94.076,138.726C304.113,638.452,69.472,548.137,32.952,346.44
   c-10.727-59.244-1.896-116.084,25.149-169.98C58.436,175.793,59.06,175.271,59.548,174.682z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#815815" d="M535.387,203.902c-0.012,0.174-0.025,0.347-0.037,0.522
   c-0.248,5.337-0.494,10.673-0.742,16.009l0.018-0.053c-0.441,3.301-0.889,6.602-1.324,9.904
   c-2.576,19.498-11.783,36.569-19.826,54.06c-1.02,2.217-2.395,4.271-3.605,6.4c-1.344-2.081-2.664-4.178-4.049-6.231
   c-0.316-0.469-0.854-0.791-1.289-1.18c-0.156,0.508-0.504,1.04-0.443,1.522c1.586,12.909,1.93,26.137,5.1,38.649
   c6.734,26.586,5.33,51.081-13.26,72.765c-0.195,0.326-0.389,0.651-0.582,0.978l0.018-0.005c-1.375,0.249-2.748,0.497-4.123,0.746
   c0.498-1.548,0.764-3.223,1.529-4.624c5.609-10.268,11.838-20.678,12.809-32.321c1.473-17.701-3.246-35.045-6.805-52.275
   c-1.027-4.97-2.314-15.441-7.104-13.425c-8.344,3.513-16.928,11.314-19.313,20.391c-4.578,17.44-11.861,26.544-29.781,30.522
   c-0.844,0.188-1.799-0.123-2.701-0.201c0.514-0.687,1.016-1.384,1.547-2.056c3.811-4.823,11.34-5.795,13.145-12.696
   c0.449-0.458,0.9-0.915,1.35-1.372c0.211-0.252,0.424-0.504,0.637-0.756c4.139-5.121,6.791-10.745,5.943-17.554
   c3.484,0.165-9.549-15.265,4.697-4.979c0.275,0.2,0.961,0.259,1.166,0.07c8.875-8.245,20.232-14.051,25.539-25.791
   c0.531-1.178,0.377-2.665,0.543-4.009c-0.943,0.788-1.764,1.879-2.848,2.316c-14.471,5.828-30.723,3.097-45.16,9.579
   c-7.816,3.51-16.154,5.86-24.258,8.733c-0.293,0.109-0.584,0.218-0.877,0.327c0.072,0.104,0.146,0.208,0.221,0.312
   c0.211-0.222,0.422-0.443,0.633-0.665c3.377,0.732,6.795-3.285,10.148,0.089c-15.186,4.903-30.373,9.807-45.561,14.709
   c-5.359,2.391-10.719,4.781-16.078,7.171c-7.383,2.28-14.77,4.544-22.115,6.94c-0.166,0.054-0.109,1.773,0.293,2.443
   c4.674,7.758,12.955,6.99,20.285,8.47l-0.037-0.05c0.191,0.192,0.381,0.385,0.572,0.578c0.346,0.263,0.691,0.526,1.039,0.79
   c7.02,7.056,16.457,7.926,25.445,9.948c-20.504-1.352-40.504-4.126-54.85-21.234c-3.146-3.752-4.23-8.468-2.461-13.145
   c1.57-4.149,5.947-4.251,9.525-4.87c6.98-1.208,14.326-1.04,21.012-3.095c35.809-11.004,71.488-22.425,108.145-30.415
   c6.791-1.48,14.961-1.959,16.955-10.205c1.49-6.168-3.791-10.323-7.963-14.282c-2.625-2.125-5.25-4.251-7.877-6.376
   c-0.021-1.578,0.545-3.506-0.154-4.681c-10.746-18.056-23.922-34.241-37.211-50.509c-18.15-22.218-45.516-32.751-64.42-53.662
   c-0.217-0.02-0.434-0.039-0.652-0.059c-0.314-0.187-0.629-0.373-0.943-0.559c-0.1-0.068-0.197-0.072-0.295-0.009
   c-0.389-0.246-0.777-0.491-1.166-0.737c1.074-1.324,2.625-2.481,3.156-3.996c7.813-22.301,21.355-40.83,37.609-57.603
   c3.938-4.232,7.996-5.228,13.283-1.786c4.307,2.803,6.684,8.398,12.633,8.762c2.191,1.927,4.428,0.809,5.316-0.977
   c0.883-1.771,0.408-4.219,0.531-6.37c-1.082,1.372-2.148,2.755-3.248,4.11c-0.549,0.675-1.162,1.299-1.746,1.946
   c0.479-3.519,0.959-7.038,1.438-10.556c1.637-5.074-1.85-13.432,4.861-14.877c12.512-2.693,23.475,3.464,33.756,10.198
   c1.168,0.766,2.439,1.378,3.662,2.061c9.066,9.449,17.988,18.963,24.018,30.828c9.699,19.084,16.523,39.174,21.697,59.878
   c1.291,9.021,2.582,18.043,3.873,27.065C534.883,190.265,535.135,197.084,535.387,203.902z M420.713,122.093
   c-0.162,0.392-0.324,0.783-0.486,1.174c-0.164,0.574-0.328,1.147-0.492,1.721c-7.338,7.611-9.299,24.729-2.217,29.844
   c16.58,11.978,32.811,23.674,44.463,41.342c17.258,26.168,34.797,52.023,45.963,81.568c0.885,2.339,3.107,4.065,4.703,1.421
   c8.289-13.741,15.139-28.221,17.443-44.253c3.01-20.941,0.654-41.746-2.598-62.512c-2.486-15.882-4.963-31.685-13.65-45.667
   c-0.354-0.569-1.182-0.842-1.789-1.253c-0.285,0.628-0.904,1.316-0.805,1.877c1.367,7.805,2.568,15.652,4.32,23.373
   c5.742,25.299,3.832,49.579-9.074,72.503c-0.402,0.716-1.238,1.189-1.871,1.776c-0.512-0.681-1.023-1.361-1.537-2.034
   c6.08-31.367,6.113-62.12-9.877-91.045c-7.486-13.54-14.84-28.201-28.992-35.26C447.561,88.361,431.559,98.729,420.713,122.093z
    M422.934,78.051c-1.182,0.701-2.107,0.988-2.648,1.609c-14.033,16.133-23.838,34.463-28.518,55.403
   c-0.375,1.671-0.662,3.792,1.859,4.384c2.246,0.527,3.154-1.011,4.037-2.709c8.721-16.744,17.139-33.608,34.543-43.648
   c6.689-3.86,6.303-4.455,1.42-7.516c-1.301-0.817-2.354-2.023-3.625-2.901C427.686,81.072,425.291,79.583,422.934,78.051z
    M457.029,69.027c-0.77-1.687-2.182-4.779-3.592-7.871c-0.373,1.394-0.771,2.781-1.111,4.184c-1.697,7.02,3.156,15.965-6.1,20.793
   c-0.148,0.077-0.014,0.698-0.01,1.065c0.492-0.088,1.016-0.102,1.475-0.277C455.191,84.051,456.627,77.679,457.029,69.027z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#023D67" d="M534.629,183.447c-1.291-9.022-2.582-18.044-3.873-27.065
   c41.256,68.466,51.18,141.227,29.926,218.262c1.17-9.852,2.217-19.721,3.537-29.553c3.297-24.575,2.297-49.105-1.842-73.407
   c-4.438-26.057-12.133-51.217-24.342-74.82c-0.514-0.996-1.631-1.681-2.469-2.51C535.254,190.719,534.941,187.083,534.629,183.447z"
      />
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#023D67" d="M442.762,68.294c-0.479,3.519-0.959,7.038-1.436,10.554
   c-0.285,0.43-0.57,0.861-0.855,1.292c-5.949-0.364-8.326-5.959-12.633-8.762c-5.287-3.441-9.346-2.446-13.283,1.786
   c-11.432-5.171-22.787-10.544-35.115-13.36c-1.51-2.219,2.162-7.819-2.076-6.894c-4.803,1.05-13.988-7.057-14.529,5.403
   c-3.232-0.489-6.465-0.978-9.697-1.467c0.662-7.567-5.277-3.212-8.1-4.483c-0.641-0.443-1.23-1.153-1.932-1.289
   c-20.445-3.951-41.078-4.739-61.769-2.911c-9.271,0.819-18.911-0.322-27.643,4.2c-1.466,0.03-3.319-0.506-4.334,0.187
   c-5.017,3.42-16.154-7.938-16.383,6.755c-4.161,1.115-8.306,3.113-12.485,3.188c-15.438,0.277-26.845,20.062-44.469,9.082
   c-8.547-7.346-12.24,2.06-17.745,5.164c-1.92,1.082-3.758,2.311-5.634,3.473c-0.681-2.549-1.363-5.097-2.044-7.646
   c47.118-30.402,98.648-45.655,154.782-44.613C354.807,28.87,400.582,42.54,442.762,68.294z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#004B80" d="M176.023,71.576c17.624,10.98,29.031-8.805,44.469-9.082
   c4.179-0.075,8.324-2.073,12.485-3.188c1.138,2.171-2.058,5.961,1.606,6.752c4.044,0.873,8.39,0.35,12.604,0.435
   c-2.862,3.515-5.709,7.041-8.591,10.54c-1.715,2.082-4.499,3.896-5.012,6.226c-0.992,4.493-0.731,9.266-0.89,13.926
   c-0.005,0.146,1.086,0.471,1.667,0.473c11.287,0.028,22.574-0.033,33.859,0.066c2.233,0.019,2.011-1.385,1.729-2.53
   c-0.948-3.849,2.704-9.452-1.829-11.343c-4.175-1.741-9.547-0.615-14.392-0.751c3.228-3.887,6.453-7.777,9.685-11.661
   c0.889-1.069,1.665-2.284,2.723-3.15c5.206-4.263,2.74-10.078,3.22-15.294c-3.178-0.183-6.355-0.398-9.536-0.538
   c-2.041-0.089-4.085-0.065-6.128-0.092c8.732-4.521,18.372-3.38,27.643-4.2c20.691-1.828,41.323-1.04,61.769,2.911
   c0.701,0.135,1.291,0.846,1.932,1.289c-7.504-1.528-9.301,1.891-8.449,8.828c0.664,5.399,0.135,10.944,0.137,16.424
   c0.008,14.313,6.773,21.267,20.766,21.347c14.047,0.08,21.75-7.514,21.826-21.5c0.031-5.886,0.082-11.772,0.123-17.659
   c12.328,2.816,23.684,8.188,35.115,13.36c-16.254,16.774-29.797,35.302-37.609,57.604c-0.531,1.515-2.082,2.672-3.16,3.996
   c-3.885-0.832-7.764-1.664-11.645-2.496c-0.352-0.055-0.701-0.11-1.053-0.165c-0.305-0.098-0.609-0.196-0.914-0.293
   c-0.006-0.15-0.082-0.224-0.227-0.222c-0.467-0.145-0.932-0.288-1.398-0.432c-8.344-1.033-16.688-2.067-25.031-3.1
   c-26.631,0.127-53.259,0.254-79.888,0.381c-0.577-0.61-1.153-1.221-1.73-1.832c-2.589-4.415-7.98-1.667-11.464-4.753
   c-7.831-6.94-16.236-13.232-24.407-19.788c-0.274-0.165-0.548-0.329-0.823-0.494c-0.223,0.014-0.446,0.029-0.67,0.042
   c-0.252-0.093-0.504-0.187-0.756-0.28c0.775-2.873,8.137-5.634-0.427-8.623c-6.402-2.233-9.934,2.362-14.644,4.274
   c-3.443-3.708-6.886-7.416-10.328-11.123C184.261,81.1,180.142,76.338,176.023,71.576z M283.162,68.161
   c-0.153,0.06-0.307,0.119-0.461,0.178c-0.091-0.28-0.183-0.56-0.274-0.84c-0.075-0.146-0.15-0.292-0.225-0.438
   c0.316-0.176,0.631-0.354,0.947-0.53c11.155-0.135,4.159-8.415,4.959-12.593c0.599-3.126-4.179-0.875-6.478-1.424
   c-8.32-1.985-10.577,1.462-9.926,9.745c0.864,10.976,0.198,22.071,0.264,33.114c0.004,0.765,0.548,2.136,0.981,2.194
   c4.5,0.612,8.815,0.14,11.4-4.188c0.805-1.348,1.022-4.693,0.856-4.739c-8.697-2.379-7.6-8.738-6.374-15.176
   c0.712-1.265,1.425-2.53,2.138-3.795c0.733-0.2,1.467-0.399,2.201-0.599c0.044-0.221,0.088-0.441,0.13-0.665
   c0.18-0.181,0.36-0.363,0.54-0.544C283.614,67.96,283.388,68.061,283.162,68.161z M297.911,86.348c-4.405,2.87-3.15,6.77-0.361,9.13
   c2.085,1.765,5.865,1.526,8.89,2.18c0.236-3.098,0.564-6.192,0.675-9.295c0.08-2.202-0.152-4.414-0.205-6.622
   c-0.014-0.6,0.119-1.202,0.186-1.802c0.391,0.474,0.889,0.896,1.158,1.432c4.93,9.866,9.469,20.302,23.836,16.25
   c0.762-0.214,1.854-1.213,1.857-1.855c0.113-13.749,1.271-27.651-0.488-41.171c-0.691-5.311-9.197-0.979-14.029-2.063
   c-2.363-0.53-1.357,1.765-1.324,2.739c0.168,4.798,0.545,9.588,0.813,14.384c0.033,0.593-0.154,1.199-0.24,1.798
   c-0.377-0.502-0.766-0.997-1.129-1.509c-5.498-7.769-6.504-20.979-21.275-17.515c-1.55,0.364-4.526,0.42-4.537,0.708
   c-0.166,4.351,0.053,8.717,0.182,13.08c1.097-0.161,2.793,0.058,3.18-0.552c1.171-1.847,2.913-3.696,4.642-2.612
   c6.967,4.37,1.298,11.058,2.48,16.571c0.035,0.161-1.2,0.595-1.844,0.9c-0.414,0.042-0.828,0.084-1.242,0.126
   c0.034,0.201,0.069,0.403,0.104,0.604c0.355-0.095,0.71-0.189,1.064-0.283c-0.855,1.687-1.71,3.374-2.565,5.061
   c-0.173,0.225-0.346,0.45-0.52,0.674C297.449,86.586,297.679,86.467,297.911,86.348z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#8B898A" d="M87.899,186.42c1.608-18.474,6.449-36.156,13.098-53.394
   c0.451-0.686,0.902-1.372,1.354-2.057c0.02-0.146,0.056-0.287,0.108-0.424c0.651-0.973,1.302-1.944,1.953-2.917
   c0,0,0.017-0.017,0.016-0.017c0.288-0.351,0.576-0.701,0.863-1.05c0.217-0.471,0.433-0.943,0.649-1.415
   c4.22-6.012,8.441-12.025,12.663-18.037c0.542-0.573,1.084-1.145,1.625-1.717c0.31-0.348,0.621-0.696,0.932-1.045
   c0.103-0.07,0.189-0.156,0.257-0.258c0.685-0.633,1.373-1.266,2.061-1.899c0.228-0.219,0.456-0.438,0.682-0.657
   c0.288-0.274,0.578-0.548,0.867-0.821c0,0,0.12-0.118,0.119-0.118c2.44-1.557,4.882-3.113,7.323-4.67
   c0.92-0.112,1.839-0.225,2.76-0.337c11.963-1.13,21.202,4.111,27.795,13.408c4.215,5.944,6.83,13.022,10.163,19.592
   c-1.01,1.133-2.02,2.266-3.029,3.399c-1.312,1.469-2.625,2.938-3.938,4.406l0.039-0.015c-0.157,0.016-0.313,0.032-0.47,0.048
   c-4.103,4.584-8.206,9.169-12.309,13.753c-0.246,0.357-0.492,0.715-0.737,1.073c-2.499,0.131-3.735,1.724-4.488,3.877
   c-0.089,0.128-0.168,0.263-0.237,0.403l0.026-0.018c-0.833,0.804-1.667,1.608-2.501,2.413c-4.125,4.034-8.39,7.937-12.344,12.133
   c-6.55,6.951-12.604,14.312-17.012,22.874c-0.052,0.157-0.067,0.318-0.047,0.482l0.004-0.058c-0.991,1.155-1.983,2.31-2.975,3.464
   l0.045-0.033c-0.424,0.34-0.848,0.68-1.271,1.019c-4.448,6.565-8.895,13.129-13.343,19.693c-0.575-0.602-1.434-1.107-1.678-1.822
   c-1.507-4.396-2.875-8.839-4.289-13.267c-1.495,5.557,0.42,10.699,1.953,15.882c0.276,0.932,0.653,1.833,0.983,2.749
   c-0.659,0.808-1.318,1.615-1.977,2.422C89.392,211.473,88.386,198.986,87.899,186.42z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M170.158,131.987c1.01-1.133,2.02-2.267,3.029-3.399
   c0.311-0.336,0.622-0.672,0.933-1.008c1.363-1.758,2.727-3.517,4.088-5.275c1.01-1.186,2.022-2.371,3.034-3.557
   c1.339-1.806,2.679-3.611,4.019-5.417c1.036-1.186,2.071-2.37,3.106-3.554c0.32-0.137,0.64-0.275,0.959-0.412l-0.074-0.006
   c0.79,0.036,1.579,0.072,2.369,0.107c-0.27,0.965-0.539,1.929-0.808,2.894l0.009-0.008c-2.227,1.72-5.222,2.876-3.802,6.802
   c0.879,2.429,1.315,5.04,1.716,7.605c0.121,0.773-0.544,1.668-0.849,2.508c3.505,0.895,2.588-3.685,5.139-4.259
   c0.446-0.1,0.376-2.497,0.545-3.833c-0.617-0.047-1.234-0.091-1.851-0.142c-0.696-0.057-1.391-0.12-2.086-0.18
   c1.457-2.24,2.914-4.479,4.371-6.719c0.385,0.294,1.108,0.593,1.104,0.883c-0.057,4.644,3.856,2.787,6.034,3.739l-0.021-0.017
   c-0.544,1.265-1.088,2.529-1.631,3.795c-0.253,0.614-0.506,1.229-0.76,1.843c0.391-0.503,0.781-1.006,1.171-1.509
   c5.315-0.448,8.87-3.692,12.026-7.569l0.013,0.013c-2.452-5.193-0.091-9.476,2.592-13.697c0.223-0.014,0.446-0.029,0.67-0.043
   c0.275,0.165,0.548,0.329,0.822,0.494c8.171,6.556,16.577,12.848,24.407,19.788c3.484,3.087,8.875,0.339,11.463,4.753
   c-9.529,6.677-20.468,9.716-31.604,12.246c-4.02,2.674-6.284-1.384-9.333-2.433c-0.009-0.15-0.01-0.301-0.005-0.452
   c6.941-3.005,13.884-6.003,20.82-9.017c2.135-0.927,6.13-2.083,6.057-2.809c-0.35-3.447-2.96,0.739-4.328-0.742
   c-0.329-0.355-0.729-0.646-1.095-0.967c-6.826,0.082-13.651,0.163-20.477,0.244c0,0,0.011-0.01,0.011-0.01
   c-0.255,0.196-0.565,0.351-0.752,0.599c-0.373,0.496-0.677,1.043-1.01,1.569c1.097-0.291,2.195-0.583,3.292-0.874
   c-1.001,1.209-2.003,2.417-3.005,3.626c-0.49,0.327-0.98,0.654-1.471,0.981c-0.166,0.165-0.331,0.33-0.495,0.495l-0.01-0.009
   c0.165-0.165,0.33-0.331,0.495-0.496c0.491-0.326,0.982-0.653,1.474-0.979c3.703,1.588,7.767-4.426,11.309,0.539
   c-2.401,1.308-4.69,2.923-7.229,3.852c-4.114,1.505-7.058,6.297-12.357,4.491c-0.364-0.084-0.729-0.169-1.093-0.253
   c0.024,0.438,0.049,0.875,0.073,1.313c-2.426,2.423-4.851,4.846-7.276,7.269c-5.092,0.683-8.84,3.636-12.212,7.226
   c1.9,1.353,3.801,2.705,5.701,4.057c0.536,0.199,1.073,0.397,1.609,0.596c0.285-0.352,0.571-0.703,0.856-1.055
   c0.673,0.391,1.345,0.782,2.019,1.173c-0.231,0.309-0.416,0.671-0.7,0.92c-2.465,2.159-4.948,4.298-7.425,6.444
   c-0.751,0.255-1.505,0.511-2.258,0.765c-0.187-0.285-0.374-0.57-0.561-0.854c0.026,0.611,0.053,1.223,0.08,1.834
   c-0.214,0.274-0.428,0.549-0.643,0.824c-0.569,0.216-1.14,0.433-1.71,0.649c-0.02-0.194-0.04-0.389-0.06-0.583
   c0.31-0.502,0.621-1.004,0.931-1.506c-4.039-0.777-2.143-2.301-0.478-3.796c0.375-0.206,0.749-0.412,1.124-0.618
   c-0.045-0.058-0.128-0.168-0.13-0.167c-0.339,0.251-0.673,0.511-1.006,0.77c1.007-3.611-3.265-0.894-3.547-2.959
   c-0.585,1.125-1.17,2.251-1.755,3.376l-0.116,0.109c-0.465-0.004-0.93-0.007-1.395-0.011c-0.021-0.261-0.043-0.522-0.065-0.784
   c-0.129-0.406-0.258-0.813-0.387-1.22c-0.192,0.417-0.384,0.834-0.577,1.251c-0.167,0.581-0.334,1.161-0.501,1.742l0.022-0.019
   c-0.336,0.17-0.671,0.341-1.007,0.511l0.021-0.01c-0.655,0.494-1.311,0.988-1.966,1.482l0.002-0.008
   c-0.164,0.166-0.329,0.331-0.493,0.496l0.001-0.001c-0.164,0.164-0.328,0.327-0.492,0.491l-0.007,0.007
   c-0.33,0.164-0.66,0.328-0.99,0.492l0.046-0.002c-2.087-2.285-3.897-4.572-0.053-6.904c0.33,0.002,0.659,0.003,0.989,0.005
   c0,0,0.072-0.127,0.072-0.127l-0.101,0.097c-0.32,0.008-0.641,0.018-0.96,0.026c3.383-3.467,6.531-7.211,10.201-10.345
   c7.639-6.522,14.979-13.288,20.9-21.456c0.387-0.533,0.465-1.289,0.688-1.941c-0.63,0.361-1.388,0.601-1.87,1.104
   c-5.01,5.236-9.896,10.594-15.016,15.717c-1.071,1.071-2.97,1.307-4.475,1.958c-0.382,0.165-0.716,0.443-1.072,0.669
   c0.233-0.746,0.4-1.522,0.711-2.235c1.53-3.5,2.21-7.699,7.098-8.458c0.476-0.074,0.797-1.139,1.191-1.744
   c-0.671-0.388-1.304-0.891-2.025-1.125c-0.52-0.169-1.271-0.175-1.715,0.089c-0.78,0.464-1.404,1.19-2.093,1.808
   c-0.577-1.173-1.153-2.347-1.73-3.52l0.017,0.018C173.571,127.826,174.18,137.569,170.158,131.987z M187.914,126.12
   c-0.751-1.225-0.978-1.593-1.204-1.961c-0.66,0.952-1.345,1.889-1.948,2.876c-0.072,0.119,0.299,0.51,0.465,0.775
   C186.162,127.223,187.094,126.636,187.914,126.12z M203.182,122.373c-0.389,0.452-0.813,0.729-0.81,1
   c0.004,0.273,0.434,0.539,0.677,0.809c0.277-0.255,0.701-0.471,0.781-0.776C203.889,123.182,203.493,122.84,203.182,122.373z
    M203.519,131.851c-0.051-0.135-0.102-0.27-0.153-0.405c-0.072,0.069-0.217,0.162-0.204,0.202c0.043,0.126,0.145,0.232,0.224,0.346
   C203.426,131.948,203.465,131.901,203.519,131.851z M201.256,132.178c-0.072,0.036-0.144,0.071-0.215,0.106
   c0.093,0.061,0.182,0.133,0.284,0.171c0.021,0.008,0.091-0.114,0.138-0.175C201.393,132.246,201.324,132.212,201.256,132.178z
    M203.561,134.779l0.191-0.093l-0.191-0.092L203.561,134.779z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M98.599,217.503c4.448-6.564,8.896-13.128,13.343-19.693
   c0.424-0.34,0.848-0.679,1.271-1.019l-0.045,0.033c4.491,1.85,1.728-2.529,2.975-3.464c0,0-0.004,0.058-0.005,0.057
   c0.016-0.161,0.032-0.321,0.048-0.482c4.408-8.563,10.461-15.923,17.012-22.874c3.954-4.196,8.219-8.099,12.344-12.134
   c-0.44,1.137-2.306,2.547,0.583,3.047c0.166,0.987,0.332,1.974,0.498,2.96l-0.086,0.049l0.073-0.063
   c2.467-0.785,1.698-2.364,0.966-3.934c1.159-1.251,2.319-2.501,3.479-3.752c-0.933-0.368-1.866-0.736-2.8-1.103
   c0.753-2.153,1.989-3.746,4.486-3.878c0.21,0.786,0.422,1.574,0.634,2.361c0.416-0.198,1.211-0.513,1.191-0.576
   c-0.299-0.972-0.705-1.911-1.086-2.857c4.103-4.584,8.206-9.169,12.309-13.753c0.157-0.016,0.313-0.032,0.47-0.048l-0.039,0.015
   c2.901-0.026,5.803-0.052,8.704-0.079c-0.127-0.548-0.253-1.096-0.38-1.644c0.624-0.124,1.249-0.247,1.873-0.37
   c-0.175,1.168-0.114,3.254-0.562,3.37c-7.276,1.876-6.832,11.246-13.414,13.754c-0.353,0.134-0.574,2.09-0.155,2.531
   c2.684,2.821,2.059,4.885-0.711,7.329c-5.079,4.481-9.782,9.387-14.844,13.889c-0.871,0.775-3.188,0.938-3.174-1.42
   c0.019-2.988-1.837-1.29-2.67-1.041c-0.716,0.214-1.782,1.77-1.643,1.958c3.329,4.515-0.606,5.715-3.353,7.207
   c-1.595,0.867-3.504,1.155-5.272,1.704c-0.062,0.161-0.124,0.322-0.185,0.483l-0.001-0.013c-0.165,0.914-0.329,1.827-0.493,2.74
   c0.108,0.512,0.217,1.024,0.326,1.536c0.1,0.125,0.202,0.248,0.307,0.369c-0.051,0.589-0.102,1.178-0.153,1.767
   c-0.499,0.481-0.997,0.962-1.496,1.443c-0.45-0.141-0.9-0.282-1.35-0.422c-0.017-0.325-0.035-0.65-0.052-0.976
   c0.082-0.101,0.164-0.201,0.246-0.301c-0.167,0.015-0.335,0.031-0.502,0.046c-0.134,0.246-0.268,0.491-0.402,0.736
   c-0.87,0.424-2.121,0.629-2.521,1.324c-0.629,1.091-0.685,2.512-0.984,3.794c0.869-0.401,1.738-0.802,2.607-1.203
   c0.448-0.041,0.897-0.082,1.345-0.123c0.233,0.358,0.467,0.716,0.7,1.073c0.148,0.199,0.296,0.397,0.444,0.595
   c0.029,0.086,0.034,0.175,0.017,0.265c-0.191,0.041-0.383,0.082-0.574,0.122c-0.3,0.196-0.601,0.393-0.901,0.59
   c-0.583,0.295-1.167,0.591-1.751,0.887c0.246,0.328,0.492,0.656,0.738,0.984c0.285,0.463,0.571,0.926,0.856,1.388
   c-0.994,0.282-1.987,0.563-2.981,0.845c-1.3-1.19-2.447-3.062-3.938-3.396c-1.795-0.402,3.052,5.994-2.723,3.313
   c-3.623,8.862,1.969,4.027,4.831,3.25c-0.006,0.933,0.344,2.088-0.075,2.765c-3.584,5.784-7.308,11.481-10.99,17.206
   c-0.143-0.134-0.285-0.268-0.428-0.402c0.014,0.24,0.028,0.48,0.042,0.72c-0.841,1.919-1.683,3.838-2.524,5.757
   c-0.223-0.152-0.445-0.303-0.668-0.456c-0.065-0.064-0.13-0.128-0.195-0.192c-0.405,0.166-0.811,0.331-1.216,0.497
   c-0.102,0.099-0.204,0.199-0.306,0.298c-0.148-0.011-0.265-0.077-0.35-0.199c-0.167-0.34-0.334-0.681-0.501-1.022
   c-0.068,0.036-0.136,0.07-0.203,0.106c0.236,0.304,0.473,0.607,0.709,0.91c-0.108,0.467-0.217,0.934-0.325,1.4
   c-0.104-0.095-0.208-0.189-0.311-0.284c0.008,0.177,0.017,0.354,0.025,0.53c0.389,0.368,0.777,0.736,1.166,1.104
   c1.298,5.382-8.444,4.511-5.978,10.554c-0.162-0.018-0.323-0.043-0.483-0.075c-0.324-0.238-0.648-0.476-0.972-0.714
   c-2.155-1.291-4.208-2.807-6.485-3.827c-5.146-2.305-2.858,3.393-4.777,4.62l0.004-0.004c-3.419,4.318-8.216,2.781-12.607,2.966
   c2.322-3.384,4.644-6.769,6.965-10.153l-0.012-0.159c0.243-0.15,0.486-0.299,0.729-0.449c3.208-3.644,6.416-7.288,9.621-10.931
   c0.569-0.597,1.14-1.195,1.711-1.792c0.659-0.807,1.317-1.614,1.976-2.422C96.579,219.866,97.589,218.685,98.599,217.503z
    M94.195,237.45c-0.09,0.092-0.179,0.183-0.269,0.274c0.167,0.584,0.336,1.169,0.504,1.753c0.199-0.188,0.602-0.458,0.564-0.55
   C94.788,238.412,94.473,237.938,94.195,237.45z M99.603,234.822c0.932-0.629,1.863-1.259,2.794-1.89
   c0.32-0.557,0.64-1.114,0.959-1.671c1.256-1.83,0.447-2.971-1.26-3.834c-0.243-0.122-0.435-0.344-0.65-0.519
   c-0.283,0.897-0.565,1.795-0.848,2.693c-0.101,0.1-0.201,0.2-0.3,0.301c-1.495-1.825-2.99-3.649-4.485-5.475
   c-0.408,1.035-1.248,2.131-1.11,3.087c0.16,1.109,1.033,2.836,1.851,2.996c2.464,0.482,5.083,0.311,2.752,3.956
   c-0.152,0.148-0.305,0.296-0.457,0.445C99.101,234.881,99.352,234.852,99.603,234.822z M106.712,216.969
   c-0.879-0.554-2.091-1.792-2.583-1.552c-3.423,1.666-1.957,5.066-2.268,7.778c-0.089,0.778,1.118,1.705,1.731,2.563
   c1.219-1.122,2.438-2.243,3.656-3.364c1.347-0.44,2.666-1.019,4.045-1.294c4.434-0.882-3.954-4.576,1.307-5.616
   c1.578-0.312,2.754-2.66,4.114-4.075c-1.317-0.061-2.853-0.584-3.909-0.089c-2.416,1.134-1.921-0.528-2.058-1.73
   c1.072,0.093,2.144,0.186,3.215,0.278c0.013-0.413,0.025-0.826,0.038-1.24c-1.08,0.329-2.159,0.658-3.238,0.987
   c-0.276,3.939-3.261-3.086-3.532,0.872c0.077,0.076,0.154,0.153,0.231,0.229C107.212,212.8,106.962,214.884,106.712,216.969z
    M98.03,223.998c0.982-0.837,2.108-1.343,2.218-2.014c0.122-0.745-0.685-1.641-1.088-2.471c-0.647,1.093-1.321,2.172-1.91,3.296
   C97.18,222.943,97.573,223.321,98.03,223.998z M106.77,227.668c-0.664-0.176-0.89-0.31-1.076-0.266
   c-0.195,0.046-0.35,0.261-0.521,0.402c0.232,0.112,0.462,0.303,0.699,0.312C106.074,228.124,106.284,227.923,106.77,227.668z
    M112.556,217.08c-0.115-0.087-0.246-0.26-0.342-0.242c-0.122,0.022-0.215,0.199-0.321,0.309c0.127,0.068,0.251,0.183,0.38,0.187
   C112.365,217.336,112.46,217.188,112.556,217.08z M152.521,163.542c-0.083,0.108-0.165,0.217-0.247,0.325
   c0.119,0.046,0.258,0.153,0.351,0.122c0.112-0.038,0.184-0.193,0.274-0.297C152.777,163.64,152.655,163.589,152.521,163.542z
    M119.642,197.066c-0.032-0.147-0.063-0.294-0.096-0.442c-0.152,0.102-0.336,0.18-0.439,0.318c-0.036,0.049,0.125,0.245,0.196,0.374
   C119.416,197.237,119.527,197.157,119.642,197.066z M124.756,200.603l0.075-0.104l-0.12-0.017L124.756,200.603z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M87.181,243.99c1.919-1.227-0.369-6.925,4.777-4.62
   c2.276,1.02,4.33,2.536,6.484,3.827c-0.127,0.34-0.254,0.679-0.381,1.019c0.452-0.102,0.903-0.204,1.354-0.305
   c0.16,0.032,0.321,0.057,0.483,0.075c-0.028,3.313,1.003,7.508-4.886,6.013c-0.626-0.159-1.146-0.738-1.715-1.122
   c0.26,0.722,0.616,1.426,0.761,2.171c0.396,2.031,0.704,4.08,1.046,6.122c-0.298-0.075-0.597-0.149-0.895-0.224
   c0.178,0.202,0.355,0.405,0.533,0.607c-0.674,1.264-1.348,2.527-2.021,3.79c-2.352,0.945-2.978,4.889-6.527,3.759l0.01,0.009
   c-0.707-0.271-1.413-0.543-2.12-0.814c0.539,1.745,1.077,3.489,1.616,5.233c0,0,0.014-0.012,0.014-0.012
   c1.974-1.333,2.808,0.425,3.915,1.443c0.969,0.679,1.938,1.357,2.908,2.036c0.052,0.65,0.104,1.3,0.157,1.95
   c-0.276,0.003-0.553,0.005-0.829,0.008c-6.222,1.049-3.022,4.885-2.358,7.971c0.111,0.075,0.222,0.151,0.333,0.227
   c-0.019-0.167-0.038-0.335-0.057-0.502c0.096-0.253,0.192-0.505,0.289-0.759c3.668,0.563-2.753,2.717,0.526,3.342l-0.021-0.015
   c-0.087,1.102-0.174,2.203-0.261,3.305c-0.091,0.08-0.182,0.159-0.271,0.24c-0.402-0.072-0.805-0.144-1.207-0.215l-0.008-0.001
   c-4.136-1.679-0.653,2.472-2.15,2.811c0.175,0.266,0.351,0.532,0.525,0.799c-0.205,1.713,1.441,4.969-2.813,3.419
   c-0.017-0.006-0.653,1.318-0.761,2.057c-0.073,0.499,0.274,1.06,0.433,1.592c0.138-0.159,0.401-0.325,0.394-0.477
   c-0.159-3.148,1.707-0.703,2.59-0.946c0.095,0.631,0.43,1.344,0.249,1.882c-2.73,8.125-5.528,16.228-8.31,24.336
   c-0.459-1.011-0.917-2.022-1.376-3.033c0.414-3.41,0.829-6.82,1.243-10.231c3.365-7.098-7.384-2.642-6.387-7.802l-0.049-0.047
   c0.472-0.788,0.945-1.576,1.417-2.364l0.135-0.133c3.301-6.639-3.924-9.171-5.436-13.933c0,0-0.005-0.015-0.006-0.015
   c-0.162-0.729-0.324-1.458-0.486-2.188l-0.017-0.03c-0.154-0.721-0.309-1.442-0.463-2.163c-0.097-0.923-0.193-1.846-0.291-2.77
   c1.521-5.91-0.087-11.414-2.185-16.854c0.532-1.488,1.063-2.976,1.596-4.46c1.814-2.555,3.627-5.112,5.44-7.668
   c0.818-1.125,1.638-2.251,2.458-3.376c4.392-0.186,9.188,1.352,12.607-2.966L87.181,243.99z M79.995,289.115
   c0.206-4.749,0.438-9.497,0.581-14.247c0.016-0.524-0.479-1.063-0.737-1.596c-0.246,0.164-0.701,0.321-0.707,0.494
   c-0.196,5.063-0.347,10.128-0.504,15.192c-3.411,1.47-0.667,4.39-1.34,6.603c-0.768,2.524,0.438,5.648,0.786,8.511
   c2.993-2.06,2.233-5.22,2.276-8.085C80.384,293.699,80.124,291.406,79.995,289.115z M86.639,294.606
   c-0.594-1.062-1.088-1.945-1.583-2.828c-0.212,0.311-0.67,0.719-0.593,0.915c0.323,0.816,0.795,1.577,1.271,2.32
   C85.76,295.054,86.316,294.755,86.639,294.606z M77.467,283.163c-0.349,0.647-0.666,1.014-0.718,1.415
   c-0.029,0.229,0.372,0.515,0.58,0.775c0.275-0.315,0.691-0.596,0.778-0.957C78.17,284.134,77.8,283.767,77.467,283.163z
    M84.262,267.257c-0.26-0.201-0.44-0.445-0.593-0.429c-0.164,0.017-0.303,0.278-0.453,0.433c0.163,0.148,0.31,0.385,0.492,0.416
   C83.841,267.698,84.02,267.451,84.262,267.257z M87.641,281.366c-0.074-0.131-0.148-0.263-0.223-0.394
   c-0.084,0.098-0.256,0.221-0.237,0.29c0.052,0.184,0.187,0.345,0.288,0.516C87.524,281.645,87.579,281.512,87.641,281.366z
    M85.835,285.834c-0.08,0.116-0.251,0.27-0.221,0.34c0.056,0.135,0.226,0.223,0.349,0.33c0.037-0.138,0.108-0.278,0.098-0.412
   C86.054,286,85.933,285.917,85.835,285.834z M88.688,287.871c-0.067-0.121-0.134-0.243-0.201-0.364
   c-0.061,0.046-0.181,0.112-0.173,0.137c0.041,0.129,0.115,0.25,0.177,0.373C88.552,287.97,88.614,287.924,88.688,287.871z
    M78.786,275.768l0.17-0.09l-0.168-0.094L78.786,275.768z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#AC7825" d="M188.368,109.775c-1.035,1.185-2.071,2.369-3.106,3.554
   c-8.575-8.753-16.739-18.004-28.127-23.368c-1.794-0.845-3.638-1.585-5.459-2.373c1.73-0.144,3.48-0.53,5.187-0.388
   c7.042,0.586,12.579-15.579,20.983-1.564c4.408,7.35,8.896,14.542,11.423,22.784c-0.005,0.312-0.01,0.624-0.015,0.936l0.074,0.006
   C189.007,109.5,188.688,109.638,188.368,109.775z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#AC7825" d="M74.577,246.953c-0.82,1.125-1.64,2.251-2.458,3.376
   c-6.105-11.332-5.475-23.429-3.792-35.556c0.627-4.521,2.054-8.933,3.116-13.394c1.855,4.276,3.554,8.628,5.599,12.812
   c3.503,7.167,4.11,14.852,4.5,22.608C79.221,240.184,76.899,243.568,74.577,246.953z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M189.253,109.357c0.005-0.312,0.01-0.624,0.015-0.936
   c1.581-1.806,3.163-3.611,4.744-5.417c6.033-7.145,12.869-4.768,19.766-1.67c0.252,0.093,0.503,0.187,0.756,0.28
   c-2.682,4.222-5.042,8.504-2.591,13.698l-0.013-0.013c-3.157,3.876-6.711,7.121-12.026,7.569c-0.138-0.111-0.275-0.223-0.412-0.334
   c0.544-1.265,1.088-2.529,1.632-3.794c1.353-2.095,2.966-4.074,3.965-6.327c0.682-1.536,0.803-3.5,0.544-5.175
   c-0.087-0.564-2.113-0.829-3.253-1.231c-0.206,0.589-0.412,1.177-0.618,1.766c-0.056,0.068-0.113,0.135-0.171,0.2
   c-0.148,3.595-0.298,7.189-0.446,10.784c-2.178-0.952-6.091,0.905-6.034-3.739c0.004-0.29-0.72-0.588-1.104-0.883
   c-1.457,2.24-2.914,4.479-4.371,6.719c0.695,0.06,1.391,0.123,2.086,0.18c0.617,0.051,1.233,0.095,1.851,0.142
   c-0.169,1.336-0.099,3.733-0.545,3.833c-2.55,0.574-1.634,5.153-5.139,4.259c0.305-0.84,0.969-1.734,0.849-2.508
   c-0.401-2.565-0.837-5.177-1.716-7.605c-1.42-3.926,1.575-5.082,3.802-6.802c0.007,0.074-0.002,0.155,0.025,0.22
   c0.021,0.048,0.091,0.074,0.138,0.111c-0.058-0.108-0.115-0.216-0.173-0.323c0.269-0.965,0.538-1.929,0.808-2.894
   C190.832,109.429,190.042,109.393,189.253,109.357z M193.18,109.794c0.081,0.079,0.161,0.159,0.239,0.236
   C193.339,109.952,193.26,109.873,193.18,109.794z M198.675,100.694l0.17,0.027l-0.041-0.159L198.675,100.694z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M213.778,101.333c-6.897-3.097-13.733-5.474-19.766,1.67
   c1.163-1.477,2.326-2.956,3.486-4.431c0.401-0.527,0.805-1.058,1.209-1.588c4.709-1.912,8.242-6.507,14.644-4.274
   C221.915,95.699,214.553,98.46,213.778,101.333z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#AC7825" d="M137.16,71.787c1.282-3.548-0.798-8.268,2.996-11.568
   c0.461,3.406,0.781,6.838,1.416,10.211c0.947,5.024-1.29,10.781,3.365,14.978c0.076,0.069-0.058,0.37-0.093,0.563
   c-0.47-0.116-0.984-0.146-1.404-0.36C137.861,82.756,137.681,77.409,137.16,71.787z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M174.12,127.579c-0.311,0.336-0.623,0.672-0.933,1.008
   c-3.333-6.57-5.948-13.648-10.163-19.592c-6.593-9.297-15.832-14.538-27.795-13.408c7.407-3.472,14.026-0.39,19.666,3.634
   C164.642,106.177,171.238,115.735,174.12,127.579z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M93.592,223.468c-0.571,0.598-1.142,1.195-1.711,1.792
   c-2.979-12.778-5.871-25.564-3.98-38.84C88.386,198.986,89.392,211.473,93.592,223.468z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M181.242,118.747c-1.012,1.186-2.023,2.371-3.034,3.557
   c-1.529-3.039-3.009-6.104-4.606-9.107c-1.037-1.949-2.243-3.809-3.373-5.708c1.771,1.461,3.698,2.774,5.277,4.419
   C177.563,114.049,179.343,116.455,181.242,118.747z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#8B898A" d="M198.708,96.984c-0.404,0.53-0.808,1.061-1.209,1.588
   c-3.039-4.238-6.079-8.475-9.119-12.711C191.822,89.569,195.265,93.276,198.708,96.984z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M118.603,107.11c-4.221,6.012-8.442,12.025-12.663,18.037
   C108.995,118.316,112.924,112.099,118.603,107.11z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M132.469,95.924c-2.441,1.557-4.883,3.114-7.323,4.67
   C126.642,97.555,129.202,96.184,132.469,95.924z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M104.412,127.629c-0.651,0.972-1.302,1.944-1.953,2.917
   C103.11,129.574,103.761,128.602,104.412,127.629z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M123.478,102.191c-0.688,0.633-1.375,1.266-2.061,1.899
   C122.106,103.457,122.792,102.824,123.478,102.191z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M102.351,130.97c-0.452,0.686-0.903,1.371-1.354,2.057
   C101.449,132.341,101.9,131.655,102.351,130.97z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M121.16,104.348c-0.312,0.349-0.623,0.697-0.932,1.045
   C120.54,105.044,120.85,104.696,121.16,104.348z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M105.291,126.562c-0.288,0.35-0.576,0.7-0.863,1.05
   C104.716,127.262,105.003,126.912,105.291,126.562z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M125.027,100.713c-0.289,0.273-0.579,0.547-0.867,0.821
   C124.45,101.26,124.738,100.986,125.027,100.713z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#AC7825" d="M82.26,236.191c-0.243,0.15-0.486,0.299-0.729,0.449
   C81.774,236.491,82.017,236.341,82.26,236.191z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M94.009,462.664c-10.671-13.978-16.845-29.729-18.638-47.212
   c3.126-5.07,7.595-8.585,9.354-7.472c9.533,6.031,16.039,15.122,22.176,24.034c8.881,12.896,18.927,20.446,35.132,14.672
   c7.102-2.531,12.934-9.84,21.32-7.328c20.131,6.029,39.967,13.047,60.124,18.984c18.714,5.512,34.917-7.322,52.826-8.288
   c0.411,0.096,0.822,0.19,1.232,0.286c0.859-0.078,1.717-0.154,2.576-0.231c1.034-0.103,2.068-0.205,3.102-0.308
   c0.438-0.056,0.875-0.11,1.313-0.166c6.912-1.29,13.789-3.999,20.822-0.532c1.603,0.219,3.205,0.438,4.808,0.657
   c0.514,0.064,1.025,0.13,1.539,0.195c3.291,0.484,6.594,0.89,9.869,1.466c21.061,3.7,41.711,13.08,63.541,2.008
   c9.861-5.002,22.113-5.936,31.027-12.018c15.734-10.734,27.447,6.425,41.48,3.521c1.926-0.398,3.871-0.705,5.809-1.055
   c-11.09,12.026-23.262,23.292-28.004,39.786c-5.65,2.371-6.988,9.93-13.74,11.141c-7.742,1.388-15.463,2.976-23.096,4.868
   c-24.648,6.113-47.43,18.604-73.959,18.622c-24.402,0.017-49.226,2.139-72.878-3.011c-31.555-6.871-62.542-16.896-93.155-27.372
   c-24.784-8.482-44.931-24.892-62.669-44.073c-1.605-1.735-3.552-3.341-5.688-2.045c-1.204,0.73-2.306,3.347-1.924,4.671
   C89.891,451.957,92.057,457.28,94.009,462.664z M372.943,472.348c0.578,0.173,1.158,0.508,1.732,0.494
   c14.605-0.346,28.424-4.477,42.121-9.014c5.152-1.706,11.414-4.507,8.563-10.908c-2.186-4.903-8.4-1.991-12.775-1.002
   c-20.162,4.563-39.314,16.512-61.201,8.18c-36.516-13.311-73.045-13.106-109.588-0.113c-17.107,5.374-34.495,7.628-52.366,4.849
   c-0.802-0.125-1.729,0.545-2.598,0.844c0.672,0.641,1.247,1.651,2.034,1.86c4.083,1.087,8.857,0.98,12.199,3.136
   c6.338,4.089,17.136-3.611,20.288,7.928c-5.451-4.532-11.814-1.122-17.712-1.961c-17.808-2.535-34.171-11.472-52.451-11.66
   c-1.896-0.02-3.803,1.041-5.705,1.6c0.634,1.421,0.962,3.113,1.948,4.221c9.019,10.126,21.037,14.599,33.746,17.927
   c26.073,6.827,50.928,18.591,78.241,20.313c27.641,1.742,55.476,3.322,82.966-0.162c22.998-2.915,45.064-10.851,67.314-17.506
   c12.295-3.678,24.307-6.803,26.438-22.131c0.176-1.266-0.297-3.015-1.141-3.894c-0.58-0.605-2.396-0.358-3.535-0.041
   c-11.143,3.103-22.113,7.031-33.42,9.28c-13.438,2.673-28.814-0.087-36.787,15.399c-0.291,0.064-0.582,0.129-0.873,0.194
   c-0.16-0.151-0.32-0.303-0.482-0.453c0.297-0.405,0.594-0.81,0.891-1.213C364.84,483.125,368.891,477.736,372.943,472.348z
    M456.129,446.887l-0.09-0.126l-0.064,0.135L456.129,446.887z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M94.009,462.664c-1.952-5.384-4.118-10.707-5.701-16.198
   c-0.382-1.324,0.721-3.94,1.924-4.671c2.137-1.296,4.083,0.31,5.688,2.045c17.738,19.182,37.885,35.591,62.669,44.073
   c30.613,10.477,61.601,20.501,93.155,27.372c23.652,5.149,48.477,3.027,72.878,3.011c26.529-0.019,49.311-12.509,73.959-18.622
   c7.633-1.893,15.354-3.48,23.096-4.868c6.752-1.211,8.09-8.77,13.74-11.141c-1.232,3.668-2.557,7.308-3.672,11.011
   c-0.992,3.292-4.703,6.377-2.385,9.912c2.303,3.507,6.477,1.934,9.967,1.75c-24.328,7.677-49.25,13.874-72.865,23.335
   c-51.764,20.739-102.177,11.259-152.456-4.285c-32.368-10.007-66.02-17.075-94.613-36.817
   C109.308,481.606,100.689,473.044,94.009,462.664z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#AC7825" d="M75.021,384.449c0.591-8.307,1.183-16.614,1.774-24.922
   c0.262,1.751,0.481,3.512,0.797,5.255c0.241,1.332,0.438,2.709,0.949,3.946c1.155,2.795,2.772,6.797,5.972,2.47
   c4.349-5.88,4.004-13.135,3.422-20.164c0.005-0.278,0.01-0.557,0.015-0.836c0.563-3.807,1.127-7.614,1.69-11.421
   c0.03-0.65,0.06-1.3,0.09-1.949c0.141-1.148,0.282-2.296,0.423-3.445c0.021-0.321,0.042-0.643,0.064-0.964
   c0.024-0.339,0.048-0.679,0.073-1.019c0.147-1.131,0.294-2.263,0.442-3.394c0.023-0.343,0.047-0.687,0.071-1.03
   c0.024-0.746,0.048-1.491,0.072-2.237c0.235-0.42,0.47-0.841,0.705-1.261c0.03-0.587,0.06-1.174,0.089-1.761
   c0.575-3.036,1.15-6.071,1.726-9.107c0.029-0.262,0.058-0.524,0.087-0.787c0.636-3.035,1.271-6.069,1.907-9.104
   c0.032-0.229,0.064-0.458,0.097-0.688c0.247-0.048,0.494-0.097,0.741-0.146c-0.887,8.556-1.798,17.11-2.612,25.673
   c-0.066,0.702,0.527,1.467,0.813,2.203c0.375-0.65,0.942-1.257,1.095-1.956c1.619-7.393,2.946-14.858,4.83-22.181
   c1.083-4.213,3.63-5.569,7.573-2.295c7.073,5.874,12.823,11.039,14.428,22.024c2.125,14.551,17.085,17.575,29.497,21.289
   c0.634,0.19,1.447-0.218,2.177-0.348c-0.548-0.76-1.015-1.6-1.66-2.266c-4.443-4.594-12.054-5.781-14.013-13.049
   c0.471,0.309,0.942,0.618,1.414,0.926l-0.038-0.034c0.172,0.265,0.345,0.529,0.518,0.793c1.194,1.023,2.389,2.046,3.583,3.069
   c1.748,1.179,3.495,2.357,5.243,3.536c16.26,9.51,33.895,10.553,52.04,8.454c0.824-0.032,1.647-0.065,2.472-0.098
   c2.418-0.336,4.827-0.824,7.256-0.986c14.259-0.954,27.941-3.304,38.011-14.92c4.031-4.65,7.938-9.841,5.194-16.059
   c-2.092-4.74-7.523-5.239-12.479-5.51c-3.563-0.195-7.254-0.415-10.618-1.48c-17.943-5.681-35.813-11.596-53.688-17.496
   c-25.508-8.42-52.432-12.641-76.365-25.733c2.18-4.774,4.057-9.986,9.383-12.072c4.168-1.631,5.247,3.012,7.227,4.981
   c14.278,14.2,33.127,18.644,51.333,24.128c24.822,7.478,47.934,20.507,74.423,22.662c7.42,0.604,13.839,3.438,14.24,12.684
   c0.246,5.661,0.542,11.437-4.141,15.449c-14.086,12.066-31.28,16.554-49.126,18.097c-10.483,0.907-21.403-1.931-31.687-0.305
   c-17.305,2.736-31.916-1.737-46.151-10.921c-6.28-4.051-6.372-10.85-9.727-16.121c2.739-6.813,2.442-11.787-6.852-11.311
   c-0.379-0.82-0.759-1.64-1.139-2.46c-0.937,1.613-2.336,3.119-2.74,4.855c-4.224,18.182-3.732,36.443-1.129,54.823
   c1.876,13.247,14.681,31.486,24.024,30.778c16.188-1.229,27.192,7.07,37.777,17.173c-12.458,1.191-24.349-1.644-36.152-5.098
   c-19.08-5.582-35.76-15.642-51.585-27.382C78.144,384.857,77.462,379.359,75.021,384.449z M87.623,389.07
   c3.77,2.632,7.002,4.894,10.278,7.091c0.025,0.017,1.023-1.033,0.912-1.253c-2.085-4.118-4.27-8.187-6.434-12.265
   C90.965,384.554,89.551,386.464,87.623,389.07z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M439.328,506.338c-3.49,0.184-7.664,1.757-9.967-1.75
   c-2.318-3.535,1.393-6.62,2.385-9.912c1.115-3.703,2.439-7.343,3.672-11.011c4.742-16.494,16.914-27.76,28.004-39.786
   c11.838-13.322,20.875-28.572,30.084-43.704c0.125-0.149,0.252-0.298,0.377-0.447c0.166-0.163,0.332-0.326,0.496-0.49
   c0.328-0.664,0.656-1.33,0.984-1.995l-0.018,0.005c0.193-0.326,0.387-0.651,0.582-0.978c18.59-21.684,19.994-46.179,13.26-72.765
   c-3.17-12.512-3.514-25.74-5.1-38.649c-0.061-0.482,0.287-1.014,0.443-1.522c0.436,0.389,0.973,0.71,1.289,1.18
   c1.385,2.054,2.705,4.15,4.049,6.231c1.211-2.129,2.586-4.183,3.605-6.4c8.043-17.491,17.25-34.563,19.826-54.06
   c0.436-3.302,0.883-6.603,1.324-9.904c0.049,17.323-4.479,33.824-12.705,48.612c-13.137,23.611-10.486,47.81-7.502,72.958
   c3.793,31.966,9.867,64.472-1.926,96.036C499.545,472.646,473.645,494.178,439.328,506.338z M502.717,409.402
   c0.682,0.122,1.365,0.246,2.049,0.369c0.611,0.053,1.223,0.106,1.834,0.159c7.385,3.12,9.781,2.997,9.063-0.227
   c-1.109-4.979-3.645-9.388-8.088-12.023c-3.756-2.228-7.012,0.226-9.52,2.799c-1.975,2.023-5.406,3.673-4.406,7.19
   c0.244,0.853,3.318,0.899,5.09,1.316C500.064,409.125,501.391,409.264,502.717,409.402z M495.85,427.767
   c0.797,0.004,1.592,0.008,2.387,0.012c4.287,0.854,9.248,2.953,12.389-1.063c1.918-2.453-2.023-4.763-3.883-6.325
   c-8.164-6.862-15.785-5.592-22.443,2.45c-0.746,0.902-1.658,2.721-1.275,3.276c0.676,0.982,2.289,1.318,3.51,1.925
   c2.428,0.009,4.854,0.018,7.279,0.026C494.492,427.968,495.172,427.867,495.85,427.767z M483.34,447.225
   c3.189,0,6.549,0.64,9.525-0.157c4.002-1.07,9.996,1.897,11.736-2.763c1.674-4.482-4.695-4.411-7.188-6.706
   c-0.98-0.903-2.879-0.743-4.283-1.267c-8.656-3.231-15.547,0.502-22.002,5.646c-0.85,0.677-1.695,2.962-1.393,3.238
   C473.758,448.88,478.77,446.604,483.34,447.225z M464.883,463.278c4.096-0.271,6.924-0.159,9.619-0.706
   c3.801-0.771,10.523,0.383,10.582-3.356c0.072-4.654-6.578-4-10.529-4.748c-6.162-1.166-11.574,1.113-16.252,5.099
   c-0.447,0.38-0.449,2.126-0.379,2.145C460.631,462.398,463.371,462.959,464.883,463.278z M454.979,476.805
   c3.324,0.307,5.879-0.119,7.887-2.264c-2.102-1.016-4.145-2.694-6.318-2.889c-3.191-0.285-7.92,0.371-7.77,3.941
   C448.941,479.475,453.246,475.974,454.979,476.805z M509.359,385.842c-0.588,0.631-2.408,1.74-2.303,2.617
   c0.428,3.563,3.688,3.764,6.398,4.33c0.455,0.095,1.646-1.038,1.594-1.14C513.887,389.385,513.588,386.323,509.359,385.842z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#8B898A" d="M75.021,384.449c2.44-5.09,3.123,0.408,3.857,0.954
   c15.825,11.741,32.505,21.8,51.585,27.382c11.803,3.454,23.694,6.289,36.153,5.098c1.789,0.113,3.576,0.227,5.365,0.337
   c-0.624,0.434-1.251,0.87-1.878,1.307c0.425,0.3,0.849,0.599,1.273,0.897c-10.948,3.696-5.161,6.682,0.325,9.979
   c23.46,14.106,48.172,22.998,76.21,20.12c9.381-0.963,18.923-0.357,28.391-0.469c-17.91,0.966-34.112,13.8-52.826,8.288
   c-20.156-5.938-39.993-12.955-60.124-18.984c-8.386-2.512-14.218,4.797-21.32,7.328c-16.206,5.774-26.251-1.775-35.132-14.672
   c-6.137-8.912-12.643-18.003-22.176-24.034c-1.759-1.113-6.228,2.401-9.354,7.472C75.254,405.118,75.138,394.783,75.021,384.449z
    M126.74,427.097c4.368,0,5.862,0.412,6.948-0.079c2.951-1.336,5.728-3.055,8.574-4.621c-2.141-1.302-4.126-3.199-6.448-3.806
   c-11.833-3.089-23.044-7.582-33.607-13.737c-0.594-0.346-1.5-0.155-2.261-0.215c-0.012,0.46-0.205,1.021-0.011,1.364
   C106.559,417.74,116.654,424.594,126.74,427.097z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#8B898A" d="M87.936,351.034c0.582,7.029,0.927,14.284-3.422,20.164
   c-3.2,4.327-4.817,0.325-5.972-2.47c-0.511-1.236-0.708-2.614-0.949-3.946c-0.316-1.744-0.536-3.504-0.797-5.255
   c0.134-2.005,0.269-4.011,0.403-6.018c1.224-5.773,2.448-11.546,3.673-17.319c0.124,5.192,0.15,10.388,0.402,15.572
   c0.19,3.903-1.542,10.34,2.375,10.807c3.727,0.444,2.55-6.226,3.643-9.728C87.482,352.231,87.72,351.636,87.936,351.034z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M72.465,302.954c-0.997,5.16,9.752,0.704,6.387,7.802
   c-1.87,3.252-3.739,6.503-5.608,9.754c-0.869-2.88-2.192-5.708-2.522-8.648c-0.945-8.437-1.489-16.919-2.19-25.384
   c1.511,4.761,8.737,7.293,5.436,13.933c0,0-0.177-0.047-0.177-0.047l0.042,0.18c-0.472,0.788-0.945,1.576-1.417,2.364
   c0,0-0.056,0.105-0.056,0.105L72.465,302.954z M71.958,305.386c-0.024,0.065-0.048,0.13-0.073,0.196
   c0.022-0.067,0.045-0.134,0.068-0.201L71.958,305.386z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M77.609,320.986c0.458,1.011,0.917,2.022,1.376,3.033
   c0.864-0.213,1.728-0.427,2.591-0.64c0.116,0.112,0.232,0.224,0.349,0.337c-0.067,3.073-3.333,5.893-0.728,9.179
   c-0.116,0.5-0.232,1.002-0.349,1.503c-1.395-0.6-2.339-0.622-2.705,1.298c-0.395,2.074-1.179,4.073-1.793,6.106
   c-0.354-1.398-0.709-2.796-1.063-4.194c-0.13,0.061-0.31,0.088-0.381,0.19c-0.137,0.196-0.213,0.435-0.314,0.657
   c0.588-5.37,1.175-10.739,1.763-16.109C76.772,321.893,77.191,321.44,77.609,320.986z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M74.591,338.455c0.102-0.222,0.177-0.46,0.314-0.657
   c0.071-0.102,0.251-0.129,0.381-0.19c0.354,1.398,0.709,2.796,1.063,4.194c0.614-2.033,1.398-4.032,1.793-6.106
   c0.366-1.92,1.31-1.897,2.705-1.298c0.008,0.597,0.016,1.194,0.023,1.792c-1.224,5.774-2.448,11.547-3.672,17.32
   C74.196,348.861,74.73,343.6,74.591,338.455z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M77.609,320.986c-0.418,0.454-0.837,0.907-1.255,1.36
   c0.573-3.334-1.919-1.482-3.109-1.836c1.868-3.251,3.737-6.502,5.607-9.754C78.438,314.166,78.023,317.576,77.609,320.986z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M65.083,262.458c2.098,5.44,3.706,10.944,2.185,16.854
   C65.678,273.806,64.175,268.288,65.083,262.458z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M534.629,183.447c0.313,3.636,0.625,7.271,0.938,10.907
   c-0.061,3.182-0.119,6.364-0.18,9.547C535.135,197.084,534.883,190.265,534.629,183.447z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M534.607,220.434c0.248-5.336,0.494-10.672,0.742-16.009
   C535.102,209.76,534.855,215.097,534.607,220.434z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M68.04,284.274c0.162,0.729,0.324,1.458,0.486,2.188
   C68.364,285.733,68.202,285.003,68.04,284.274z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M67.56,282.081c0.154,0.721,0.309,1.442,0.463,2.163
   C67.869,283.523,67.714,282.802,67.56,282.081z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#C59426" d="M171.98,418.223c-1.787-0.113-3.574-0.227-5.363-0.34
   c-10.586-10.103-21.591-18.401-37.778-17.173c-9.343,0.708-22.148-17.531-24.024-30.778c-2.604-18.38-3.095-36.641,1.129-54.823
   c0.404-1.737,1.803-3.243,2.74-4.855c0.38,0.82,0.76,1.64,1.139,2.461c-1.964,4.175-0.722,8.051,1.76,11.36
   c0.603,0.804,3.337,0.009,5.091-0.05c3.355,5.271,3.447,12.069,9.727,16.121c14.235,9.184,28.846,13.657,46.151,10.921
   c10.284-1.626,21.204,1.212,31.687,0.305c17.847-1.543,35.04-6.031,49.126-18.097c4.683-4.012,4.387-9.788,4.141-15.449
   c-0.401-9.246-6.82-12.08-14.24-12.684c-26.489-2.155-49.601-15.184-74.423-22.662c-18.206-5.485-37.055-9.929-51.333-24.128
   c-1.98-1.97-3.059-6.612-7.227-4.981c-5.326,2.085-7.203,7.298-9.382,12.073c-0.942,1.093-1.883,2.185-2.825,3.278
   c-0.377-1.141-0.754-2.282-1.131-3.422c0.094-0.711,0.188-1.422,0.283-2.133c3.511-4.027,3.996-10.362,9.699-12.717
   c6.494-4.469,12.987-8.939,19.481-13.408c9.064-2.151,15.675-7.733,21.591-14.68c15.833-18.596,34.592-34.14,54.271-48.28
   c21.841-15.694,47.443-22.462,73.768-26.516c3.1-0.477,6.134-1.38,9.199-2.086c-2.852-0.968-5.707-1.926-8.552-2.913
   c-0.576-0.2-1.111-0.514-1.666-0.776c0.704-0.298,1.391-0.65,2.114-0.888c15.947-5.235,32.543-1.551,48.729-3.542
   c1.588,1.398,3.055,2.969,4.781,4.164c10.818,7.481,24.346,6.679,36.037,11.652c4.898,2.084,10.469,3.307,11.65-4.802
   c0-1.264-0.002-2.528-0.004-3.792c0.043-1.006,0.086-2.012,0.129-3.019c0.639,1.779,1.502,3.512,1.875,5.345
   c1.295,6.341-0.176,13.469,6.699,17.967c18.082,11.832,34.146,26.098,49.063,41.685c13.246,13.842,23.965,30.479,42.539,38.419
   c2.627,2.125,5.252,4.25,7.877,6.376c3.117,6.98-1.654,9.058-6.682,11.755c-18.48,9.915-38.67,15.038-58.525,20.923
   c-23.164,6.867-45.053,18.422-69.805,19.578c-5.674,0.265-12.805-0.867-14.486,6.006c-1.998,8.172-2.346,16.426,4.65,23.938
   c12.074,12.968,29.41,13.015,43.996,19.67c14.66,6.689,30.344,4.333,45.752,3.185c26.521-1.973,40.697-12.046,44.785-31.743
   c3.4,2.128,6.502,1.573,7.473-2.305c0.898-3.599,4.105-8.283-2.088-10.746c1.617-1.869,2.893-4.748,4.91-5.427
   c7.482-2.521,6.215,3.99,6.709,7.674c2.795,20.867,7.264,42.02-4.572,61.699c-4.867,8.096-9.533,17.119-19.35,20.13
   c-4.461,1.367-10.047-1.026-14.568,0.25c-10.715,3.023-18.566,11.334-27.91,16.965c-1.121,0.675-1.963,1.81-2.934,2.73
   c1.084,0.742,2.17,1.484,3.254,2.227c-0.967,0.341-1.936,0.683-2.898,1.023c-7.865,0.278-15.529-0.6-22.746-4.021
   c-0.363-1.148-3.422-2.233-0.57-3.466c10.186-4.4,18.471-11.335,23.443-20.947c3.672-7.096,11.65-16.128,0.744-23.193
   c-14.668-9.503-40.912-9-51.309-0.781c-7.012,5.542-14.721,5.734-20.979-0.713c-16.488-16.987-24.285-37.009-18.092-60.742
   c1.15-4.407,5.227-12.863,8.678-9.946c9.965,8.426,18.914,3.319,27.482,0.308c28.279-9.94,56.334-20.756,82.039-36.36
   c5.055-3.068,18.15-7.069,7.34-17.646c-5.477-5.359-13.736-4.434-20.996-5.113c-17.674-1.654-25.785-17.469-38.816-25.983
   c-2.037-1.331-3.877-3.686-6.865-2.176c1.09-5.009,0.607-9.729-2.754-13.829c-12.836-15.659-26.057-18.431-45.248-9.524
   c0.145-1.652,0.867-3.495-1.908-3.783c-22.461-2.329-44.889-6.927-67.521-2.405c-8.936,1.786-18.99-0.221-26.493,7.046
   c-0.722-0.349-1.458-0.671-2.163-1.049c-13.488-7.219-26.213-6.651-38.26,3.362c-7.808,6.49-12.761,13.654-7.58,24.121
   c-9.393-1.886-13.38,6.901-19.723,10.656c-8.167,4.835-13.589,13.683-24.777,14.205c-6.702,0.313-15.927-0.934-18.926,6.74
   c-2.054,5.253,2.152,11.286,6.981,15.397c15.938,13.569,35.869,19.52,54.314,28.218c15.913,7.504,31.483,17.973,50.744,11.032
   c1.109-0.399,3.548,0.83,4.364,2.001c7.091,10.175,7.658,21.698,5.334,33.256c-2.765,13.757-10.562,25.064-19.509,35.594
   c-0.265,0.199-0.53,0.398-0.794,0.597c-8.572,5.798-17.239,4.131-22.804-3.166c-2.359-3.094-1.463-8.558-7.664-7.971
   c-20.279,1.92-40.533,3.376-60.919,1.024c-3.227-0.373-9.54-0.302-7.209,6.228c7.095,19.881,21.233,33.812,38.822,44.778
   c1.639,1.021,12.299,1.096,2.154,6.638c-0.522,0.06-1.044,0.117-1.566,0.177c-0.292,0.103-0.582,0.205-0.873,0.308
   C177.055,417.914,174.518,418.067,171.98,418.223z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#AC7825" d="M427.119,417.866c-1.086-0.742-2.172-1.484-3.256-2.227
   c0.971-0.921,1.813-2.056,2.934-2.73c9.344-5.631,17.195-13.941,27.91-16.965c4.521-1.276,10.107,1.117,14.568-0.25
   c9.816-3.012,14.482-12.035,19.35-20.13c11.836-19.679,7.367-40.832,4.572-61.699c-0.494-3.684,0.773-10.195-6.709-7.674
   c-2.018,0.679-3.293,3.558-4.91,5.428c-9.26,1.269-9.523,6.25-5.385,13.049c-4.088,19.697-18.264,29.77-44.785,31.743
   c-15.408,1.147-31.092,3.504-45.752-3.185c-14.586-6.656-31.922-6.703-43.996-19.67c-6.996-7.513-6.648-15.767-4.65-23.938
   c1.682-6.873,8.813-5.741,14.486-6.006c24.752-1.156,46.641-12.711,69.805-19.578c19.855-5.885,40.045-11.008,58.525-20.923
   c5.027-2.697,9.799-4.775,6.682-11.755c4.172,3.959,9.453,8.114,7.963,14.282c-1.994,8.246-10.164,8.725-16.955,10.205
   c-36.656,7.989-72.336,19.41-108.145,30.415c-6.686,2.055-14.031,1.887-21.012,3.095c-3.578,0.619-7.955,0.721-9.525,4.87
   c-1.77,4.677-0.686,9.393,2.461,13.145c14.346,17.107,34.346,19.882,54.85,21.234c0.338,0.037,0.674,0.074,1.012,0.111
   c1.607-0.002,3.215-0.004,4.824-0.006c14.895,0.145,29.34-1.645,42.271-9.892c1.705-1.067,3.41-2.133,5.115-3.2
   c0.881-0.729,1.762-1.456,2.643-2.184c0.35-0.27,0.699-0.54,1.051-0.81c0.186-0.241,0.369-0.482,0.555-0.724l-0.016,0.019
   c0.322-0.205,0.645-0.409,0.967-0.614c-1.805,6.9-9.334,7.873-13.145,12.696c-0.531,0.672-1.033,1.37-1.547,2.056
   c0.902,0.078,1.857,0.389,2.701,0.201c17.92-3.978,25.203-13.082,29.781-30.522c2.385-9.076,10.969-16.878,19.313-20.391
   c4.789-2.016,6.076,8.455,7.104,13.425c3.559,17.23,8.277,34.575,6.805,52.275c-0.971,11.643-7.199,22.053-12.809,32.321
   c-0.766,1.401-1.031,3.076-1.529,4.624c1.375-0.249,2.748-0.497,4.123-0.746c-0.328,0.665-0.656,1.331-0.982,1.995
   c-0.166,0.163-0.332,0.327-0.498,0.49c-0.125,0.149-0.252,0.298-0.379,0.447c-1.072,0.371-2.209,0.614-3.207,1.131
   C470.477,411.563,449.775,418.441,427.119,417.866z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#AC7825" d="M503.086,222.964c0.514,0.681,1.025,1.361,1.537,2.042
   c0.633-0.586,1.469-1.06,1.871-1.776c12.906-22.924,14.816-47.204,9.074-72.503c-1.752-7.721-2.953-15.568-4.32-23.373
   c-0.1-0.561,0.52-1.249,0.805-1.877c0.607,0.411,1.436,0.684,1.789,1.253c8.688,13.982,11.164,29.785,13.65,45.667
   c3.252,20.767,5.607,41.571,2.598,62.512c-2.305,16.032-9.154,30.513-17.443,44.253c-1.596,2.645-3.818,0.918-4.703-1.421
   c-11.166-29.544-28.705-55.399-45.963-81.568c-11.652-17.668-27.883-29.364-44.463-41.342c-7.082-5.115-5.121-22.232,2.215-29.842
   c-0.145,1.144-0.287,2.287-0.432,3.43c0.605-0.811,1.211-1.622,1.816-2.434c-0.242,1.688-0.057,3.597-0.795,5.031
   c-6.32,12.289-4.457,21.708,8.527,28.461c5.426,2.822,10.465,6.806,14.879,11.086c19.709,19.108,32.67,42.877,45.445,66.763
   c1.117,2.088,3.625,4.763,5.527,4.794c2.951,0.049,1.891-3.696,2.646-5.786c1.5-4.146,3.029-8.282,4.545-12.422
   C502.291,223.597,502.689,223.28,503.086,222.964z M509.617,262.75c0.605,0.722,1.211,1.445,1.818,2.168
   c1.143-1.073,2.285-2.147,3.428-3.22c1.197-2.3,2.393-4.601,3.59-6.901c1.313-4.727,2.656-9.446,3.924-14.186
   c0.605-2.265,1.363-4.609-0.836-6.543c-1.301,1.653-2.688,3.247-3.887,4.972c-3.758,5.41-5.488,15.536-15.334,6.413
   c-0.207-0.192-1.035-0.127-1.076-0.01c-0.172,0.497-0.385,1.18-0.172,1.579c2.43,4.574,4.934,9.108,7.42,13.653
   C508.867,261.366,509.242,262.058,509.617,262.75z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#AC7825" d="M478.631,244.979c-18.574-7.94-29.293-24.577-42.539-38.419
   c-14.916-15.587-30.98-29.854-49.063-41.685c-6.875-4.499-5.404-11.626-6.699-17.967c-0.373-1.833-1.236-3.566-1.875-5.347
   c-0.137-0.578-0.273-1.153-0.41-1.729c-0.148-0.222-0.299-0.444-0.447-0.666c-0.252-1.013-0.502-2.025-0.752-3.038
   c18.904,20.91,46.27,31.443,64.42,53.662c13.289,16.268,26.465,32.453,37.211,50.509
   C479.176,241.473,478.609,243.401,478.631,244.979z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#D6DBE0" d="M397.156,348.712c-0.338-0.037-0.674-0.074-1.012-0.111
   c-8.988-2.021-18.426-2.892-25.445-9.948c0.156-0.465,0.287-1.324,0.475-1.336c5.689-0.38-3.115-14.387,8.547-9.098
   c1.215,0.551,3.016,1.532,3.795,1.065c12.367-7.409,25.871-12.013,39.492-16.341c13.902-4.418,22.277,2.618,19.822,16.099
   c-0.543,2.979-6.967,7.799,1.422,9.773c-12.932,8.247-27.377,10.036-42.271,9.892C400.371,348.708,398.764,348.71,397.156,348.712z"
      />
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#B5BBC5" d="M444.252,338.815c-8.389-1.974-1.965-6.794-1.422-9.773
   c2.455-13.48-5.92-20.517-19.822-16.099c-13.621,4.328-27.125,8.932-39.492,16.341c-0.779,0.467-2.58-0.514-3.795-1.065
   c-11.662-5.289-2.857,8.718-8.547,9.098c-0.188,0.012-0.318,0.872-0.475,1.336c-0.348-0.264-0.693-0.527-1.041-0.791
   c-0.189-0.192-0.381-0.385-0.57-0.577l0.037,0.05c-5.041-6.429-6.039-12.51,1.537-17.853c5.359-2.39,10.719-4.78,16.078-7.171
   c0.93-0.102,1.893-0.083,2.783-0.324c15.445-4.199,30.834-8.616,46.361-12.482c2.766-0.689,6.432-3.08,9.246,1.008
   c7.574,11.004,5.967,23.026,4.236,35.103C447.662,336.682,445.957,337.748,444.252,338.815z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M454.566,331.303c-0.322,0.205-0.645,0.409-0.967,0.614
   c1.969-10.883,1.918-21.589-2.125-32.124c-1.32-3.437-3.256-4.597-6.664-3.691c-4.17,0.5-8.34,1-12.51,1.5
   c-3.354-3.374-6.771,0.643-10.148-0.089l0.023,0.026c8.104-2.873,16.441-5.223,24.258-8.733c14.438-6.482,30.689-3.751,45.16-9.579
   c1.084-0.437,1.904-1.528,2.848-2.316c-0.166,1.344-0.012,2.832-0.543,4.009c-5.307,11.74-16.664,17.546-25.539,25.791
   c-0.205,0.189-0.891,0.13-1.166-0.07c-14.246-10.286-1.213,5.144-4.697,4.979c0.848,6.81-1.805,12.434-5.945,17.554
   c-0.211,0.252-0.424,0.504-0.635,0.756C455.467,330.388,455.016,330.845,454.566,331.303z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#AC7825" d="M422.934,78.051c2.357,1.532,4.752,3.021,7.068,4.621
   c1.271,0.878,2.324,2.084,3.625,2.901c4.883,3.062,5.27,3.656-1.42,7.516c-17.404,10.041-25.822,26.904-34.543,43.648
   c-0.883,1.698-1.791,3.236-4.037,2.709c-2.521-0.592-2.234-2.713-1.859-4.384c4.68-20.939,14.484-39.27,28.518-55.403
   C420.826,79.039,421.752,78.752,422.934,78.051z M393.232,134.25c0.811,1.179,1.57,2.312,1.586,2.3
   c4.422-2.867,4.168-8.124,6.172-12.251c0.082-0.168-0.291-0.556-0.451-0.842C398.113,127.041,395.688,130.623,393.232,134.25z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M421.117,125.985c-0.605,0.812-1.211,1.623-1.816,2.434
   c0.145-1.143,0.287-2.286,0.432-3.43c0.166-0.575,0.33-1.148,0.494-1.724c0.162-0.392,0.324-0.782,0.486-1.173
   c10.846-23.364,26.848-33.732,43.504-25.426c14.152,7.059,21.506,21.72,28.992,35.26c15.99,28.926,15.957,59.679,9.877,91.041
   c-0.396,0.313-0.795,0.629-1.193,0.95c0.41-3.809,0.625-7.655,1.266-11.43c5.205-30.639-0.703-59.38-15.6-86.237
   c-6-10.816-13.213-21.292-23.916-28.116c-12.85-8.191-32.479,2.728-40.504,21.965c-0.672,1.869-1.346,3.738-2.02,5.607
   C421.117,125.8,421.115,125.893,421.117,125.985z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#8B898A" d="M432.301,297.602c4.17-0.5,8.34-1,12.51-1.5
   c1.875,0.94,3.875,1.233,5.016,3.708c5.068,10.989,4.953,22.19,2.184,33.622c-0.881,0.728-1.762,1.455-2.643,2.184
   c1.73-12.076,3.338-24.097-4.236-35.102c-2.814-4.088-6.48-1.698-9.246-1.008c-15.527,3.867-30.916,8.283-46.361,12.482
   c-0.891,0.242-1.854,0.222-2.783,0.324C401.928,307.409,417.115,302.505,432.301,297.602z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M370.662,319.482c-7.576,5.343-6.578,11.424-1.537,17.853
   c-7.33-1.479-15.611-0.712-20.285-8.47c-0.402-0.67-0.459-2.389-0.293-2.443C355.893,324.026,363.279,321.762,370.662,319.482z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#B5BBC5" d="M362.141,132.268c3.881,0.832,7.76,1.664,11.645,2.496
   c0.393,0.246,0.781,0.492,1.166,0.737c0.324,0.623,0.652,1.246,0.979,1.869c0.08,0.601,0.158,1.201,0.234,1.799
   c-0.299-0.057-0.596-0.113-0.895-0.168c-2.414-3.84-6.459-4.438-10.385-4.869c-2.986-0.327-8.707-1.108-8.211,1.772
   c1.088,6.314,7.539,8.548,13.297,10.214c3.846,1.112,5.293-1.325,5.545-4.78c0.105,1.547,0.867,3.619,0.154,4.514
   c-0.969,1.214-3.422,2.498-4.619,2.059c-5.229-1.917-10.359-4.208-15.285-6.815c-5.422-2.871-9.697-6.226,0.082-9.187
   c0.9-0.251,1.801-0.502,2.701-0.754c0.467,0.145,0.932,0.288,1.398,0.432c0.096,0.053,0.172,0.127,0.227,0.223
   c0.305,0.098,0.609,0.196,0.91,0.293C361.436,132.158,361.787,132.213,362.141,132.268z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#AC7825" d="M457.029,69.027c-0.402,8.652-1.838,15.024-9.338,17.893
   c-0.459,0.175-0.982,0.189-1.475,0.277c-0.004-0.367-0.139-0.988,0.01-1.065c9.256-4.828,4.402-13.773,6.1-20.793
   c0.34-1.402,0.738-2.79,1.111-4.184C454.848,64.248,456.26,67.34,457.029,69.027z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M440.471,80.141c0.285-0.431,0.57-0.862,0.855-1.292
   c0.582-0.645,1.195-1.269,1.744-1.944c1.1-1.355,2.166-2.738,3.248-4.11c-0.123,2.151,0.352,4.599-0.531,6.37
   C444.898,80.95,442.662,82.067,440.471,80.141z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#D8A730" d="M376.166,139.171c-0.078-0.6-0.156-1.2-0.236-1.794
   c-0.145-0.238-0.289-0.481-0.434-0.725c0.232-0.194,0.465-0.388,0.699-0.582c0.217,0.019,0.434,0.038,0.65,0.058
   c0.25,1.013,0.5,2.026,0.75,3.036c0.148,0.221,0.299,0.445,0.449,0.669c0.137,0.576,0.273,1.151,0.41,1.729
   c-0.043,1.008-0.086,2.014-0.129,3.022C377.605,142.78,376.887,140.976,376.166,139.171z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#E8C371" d="M375.496,136.651c0.145,0.244,0.289,0.487,0.434,0.725
   c-0.326-0.629-0.654-1.252-0.979-1.875c0.102-0.063,0.199-0.06,0.301,0.009C375.334,135.891,375.414,136.271,375.496,136.651z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M422.152,297.513c-0.211,0.222-0.422,0.443-0.633,0.665
   c-0.074-0.104-0.148-0.208-0.221-0.312c0.293-0.109,0.584-0.218,0.877-0.327L422.152,297.513z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#AC7825" d="M420.713,122.093c-0.162,0.391-0.324,0.781-0.486,1.173
   C420.389,122.875,420.551,122.484,420.713,122.093z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#B5BBC5" d="M375.496,136.651c-0.082-0.381-0.162-0.761-0.244-1.142
   c0.313,0.186,0.627,0.372,0.941,0.559C375.961,136.264,375.729,136.458,375.496,136.651z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#AC7825" d="M495.928,396.27c-0.195,0.326-0.389,0.651-0.582,0.978
   C495.539,396.921,495.732,396.596,495.928,396.27z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M369.088,337.286c0.189,0.192,0.381,0.385,0.57,0.577
   C369.469,337.671,369.279,337.478,369.088,337.286z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#AC7825" d="M455.916,329.931c0.211-0.252,0.424-0.504,0.635-0.756
   C456.34,329.427,456.127,329.678,455.916,329.931z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#FD7701" d="M379.439,59.805c-0.041,5.886-0.092,11.772-0.123,17.659
   c-0.076,13.986-7.779,21.58-21.826,21.5c-13.992-0.08-20.758-7.034-20.766-21.347c-0.002-5.48,0.527-11.025-0.137-16.424
   c-0.852-6.938,0.945-10.356,8.449-8.828c2.822,1.271,8.762-3.084,8.1,4.484c-0.035,7.265-0.162,14.531-0.061,21.794
   c0.041,2.969,1.336,5.23,4.816,5.292c3.445,0.061,4.939-2.002,4.988-5.065c0.104-6.85-0.018-13.704-0.047-20.556
   c0.541-12.46,9.727-4.354,14.529-5.403C381.602,51.986,377.93,57.586,379.439,59.805z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#FD7701" d="M253.694,52.364c2.043,0.027,4.088,0.003,6.128,0.092
   c3.18,0.139,6.357,0.354,9.536,0.538c-0.48,5.216,1.986,11.031-3.22,15.294c-1.058,0.866-1.834,2.081-2.723,3.15
   c-3.232,3.884-6.458,7.773-9.685,11.661c4.845,0.136,10.216-0.99,14.392,0.751c4.533,1.891,0.88,7.494,1.829,11.343
   c0.282,1.145,0.504,2.549-1.729,2.53c-11.286-0.099-22.573-0.038-33.859-0.066c-0.581-0.001-1.672-0.327-1.667-0.473
   c0.159-4.66-0.102-9.433,0.89-13.926c0.514-2.33,3.297-4.144,5.012-6.226c2.882-3.499,5.729-7.025,8.591-10.54
   c-4.214-0.085-8.56,0.438-12.604-0.435c-3.665-0.791-0.469-4.581-1.606-6.752c0.229-14.693,11.366-3.335,16.383-6.755
   C250.375,51.858,252.228,52.395,253.694,52.364z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#004B80" d="M362.834,58.314c0.029,6.852,0.15,13.706,0.047,20.556
   c-0.049,3.063-1.543,5.126-4.988,5.065c-3.48-0.062-4.775-2.323-4.816-5.292c-0.102-7.264,0.025-14.53,0.061-21.794
   C356.369,57.337,359.602,57.825,362.834,58.314z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#AC7825" d="M325.861,137.359c-16.186,1.992-32.782-1.693-48.729,3.542
   c-0.724,0.237-1.411,0.589-2.114,0.888c0.554,0.262,1.09,0.576,1.666,0.776c2.845,0.987,5.7,1.945,8.552,2.913
   c-3.064,0.706-6.099,1.609-9.199,2.086c-26.325,4.053-51.926,10.821-73.768,26.516c-19.678,14.14-38.438,29.684-54.271,48.28
   c-5.916,6.947-12.527,12.529-21.591,14.68c1.001-1.846,1.725-3.925,3.047-5.502c9.794-11.682,19.476-23.47,29.634-34.831
   c18.101-20.244,38.644-37.622,62.324-51.093c8.216-4.674,16.581-9.086,24.877-13.618l0.041-0.017
   c0.335-0.128,0.669-0.257,1.004-0.385c2.098-1.053,4.196-2.106,6.294-3.159c26.629-0.127,53.257-0.254,79.888-0.381
   c-3.451,1.845-7.445,3.172-7.699,8.068C325.834,136.534,325.848,136.947,325.861,137.359z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#FD7701" d="M300.376,80.522c0.644-0.306,1.879-0.739,1.844-0.9
   c-1.182-5.513,4.487-12.201-2.48-16.571c-1.729-1.084-3.471,0.765-4.642,2.612c-0.387,0.61-2.083,0.392-3.18,0.552
   c-0.129-4.362-0.348-8.729-0.182-13.08c0.011-0.287,2.987-0.344,4.537-0.708c14.771-3.465,15.777,9.746,21.275,17.515
   c0.363,0.513,0.752,1.007,1.129,1.509c0.086-0.599,0.273-1.205,0.24-1.798c-0.268-4.796-0.645-9.586-0.813-14.384
   c-0.033-0.975-1.039-3.27,1.324-2.739c4.832,1.084,13.338-3.248,14.029,2.063c1.76,13.521,0.602,27.423,0.488,41.171
   c-0.004,0.642-1.096,1.641-1.857,1.855c-14.367,4.052-18.906-6.384-23.836-16.25c-0.27-0.536-0.768-0.958-1.158-1.432
   c-0.066,0.601-0.199,1.203-0.186,1.802c0.053,2.208,0.285,4.42,0.205,6.622c-0.111,3.103-0.439,6.197-0.675,9.295
   c-3.025-0.654-6.805-0.416-8.89-2.18c-2.789-2.36-4.044-6.26,0.361-9.13c-0.06-0.105-0.118-0.211-0.174-0.318
   c0.855-1.687,1.71-3.374,2.566-5.06C300.403,80.833,300.427,80.684,300.376,80.522z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#FD7701" d="M278.832,73.464c-1.226,6.438-2.323,12.796,6.374,15.176
   c0.167,0.046-0.051,3.391-0.856,4.739c-2.584,4.328-6.9,4.8-11.4,4.188c-0.433-0.058-0.977-1.429-0.981-2.194
   c-0.066-11.043,0.6-22.138-0.264-33.114c-0.651-8.283,1.606-11.73,9.926-9.745c2.298,0.548,7.076-1.703,6.478,1.424
   c-0.8,4.178,6.196,12.458-4.959,12.593c-0.315,0.177-0.631,0.353-0.947,0.529c0.075,0.146,0.15,0.292,0.225,0.438
   c-0.409,0.125-0.819,0.251-1.229,0.376c-0.099,0.977-3.503-1.866-1.626,1.415C280.472,70.861,275.862,71.496,278.832,73.464z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#8B898A" d="M325.818,136.125c0.254-4.898,4.248-6.225,7.699-8.07
   c8.344,1.033,16.688,2.067,25.031,3.1c-0.9,0.251-1.801,0.503-2.701,0.754c-7.967-1.311-14.275,4.073-21.602,5.355
   C332.035,132.461,328.707,135.918,325.818,136.125z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M253.629,128.437c-2.098,1.053-4.196,2.106-6.294,3.159
   c-0.334,0.128-0.669,0.256-1.004,0.385c0,0-0.041,0.017-0.041,0.017c-22.482,7.536-43.396,18.067-62.23,32.572
   c-0.108-0.14-0.216-0.28-0.323-0.42c2.478-2.145,4.96-4.284,7.426-6.443c0.284-0.249,0.469-0.611,0.7-0.92
   c-0.673-0.391-1.346-0.782-2.019-1.173c-0.073-0.128-0.153-0.253-0.239-0.373c-0.059-0.219-0.119-0.438-0.178-0.658
   c0.602,0.121,1.203,0.243,1.804,0.365c3.625-1.455,7.251-2.91,10.877-4.365c0.143-0.031,0.281-0.074,0.416-0.129
   c0.497-0.258,0.994-0.518,1.491-0.777c5.426-3.608,10.853-7.216,16.278-10.825c11.136-2.529,22.075-5.569,31.604-12.246
   C252.476,127.215,253.052,127.826,253.629,128.437z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#815815" d="M278.832,73.464c-2.969-1.968,1.64-2.603,0.741-4.175
   c-1.877-3.281,1.527-0.438,1.626-1.415c-0.076,0.598-0.153,1.196-0.229,1.794C280.257,70.934,279.544,72.199,278.832,73.464z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M280.97,69.668c0.076-0.598,0.153-1.196,0.229-1.794
   c0.41-0.125,0.819-0.251,1.229-0.376c0.091,0.28,0.183,0.561,0.274,0.841c0.156,0.244,0.313,0.487,0.47,0.731
   C282.437,69.27,281.703,69.469,280.97,69.668z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#AC7825" d="M300.376,80.522c0.051,0.162,0.027,0.311-0.073,0.448
   c-0.355,0.094-0.71,0.188-1.065,0.283c-0.035-0.202-0.069-0.403-0.104-0.604C299.548,80.606,299.962,80.564,300.376,80.522z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M282.202,67.06c0.316-0.176,0.631-0.353,0.947-0.529
   C282.833,66.706,282.518,66.883,282.202,67.06z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#E8C371" d="M362.141,132.268c-0.354-0.055-0.705-0.11-1.057-0.165
   C361.439,132.158,361.789,132.213,362.141,132.268z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#FD7701" d="M297.911,86.348c-0.231,0.119-0.462,0.238-0.693,0.357
   c0.173-0.224,0.347-0.449,0.519-0.674C297.793,86.137,297.851,86.243,297.911,86.348z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#815815" d="M283.171,69.07c-0.157-0.244-0.313-0.487-0.47-0.731
   c0.154-0.06,0.308-0.119,0.461-0.178c0.048,0.082,0.096,0.165,0.142,0.248C283.259,68.629,283.215,68.849,283.171,69.07z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#815815" d="M283.162,68.161c0.226-0.1,0.452-0.2,0.678-0.3
   c-0.18,0.181-0.36,0.363-0.538,0.546C283.258,68.325,283.21,68.243,283.162,68.161z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#E8C371" d="M360.174,131.81c-0.055-0.095-0.131-0.169-0.227-0.223
   C360.092,131.585,360.168,131.659,360.174,131.81z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M216.027,102.064c-0.273-0.165-0.546-0.329-0.822-0.494
   C215.479,101.735,215.753,101.899,216.027,102.064z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M106.926,250.448c-5.704,2.355-6.188,8.69-9.7,12.717
   c-1.048-0.455-2.095-0.909-3.142-1.364c-0.454-0.153-0.909-0.305-1.364-0.457c0.674-1.264,1.348-2.527,2.022-3.791
   c0.121-0.128,0.242-0.256,0.363-0.384c-0.343-2.042-0.65-4.09-1.046-6.122c-0.146-0.745-0.501-1.449-0.761-2.171
   c0.569,0.384,1.089,0.963,1.715,1.122c5.889,1.495,4.858-2.7,4.886-6.013c-2.466-6.043,7.276-5.172,5.978-10.555
   c0.725-0.897,1.45-1.795,2.175-2.693c0.841-1.919,1.683-3.837,2.524-5.757c0.129-0.106,0.258-0.212,0.387-0.318
   c3.681-5.723,7.405-11.42,10.989-17.205c0.419-0.677,0.069-1.832,0.075-2.765c0.61-1.055,1.221-2.11,1.831-3.166
   c0.994-0.281,1.987-0.563,2.981-0.845c0.096-0.322,0.191-0.646,0.286-0.968c0.208-0.362,0.417-0.724,0.626-1.085
   c0.394-0.276,0.788-0.551,1.182-0.827c0.556-0.861,1.112-1.721,1.668-2.581c-0.82-0.242-1.64-0.485-2.459-0.728
   c-0.044,0.453-0.087,0.906-0.131,1.358c-0.233-0.357-0.466-0.715-0.699-1.073c-0.27-0.891-0.54-1.783-0.809-2.674
   c-0.179,0.932-0.358,1.865-0.537,2.797c-0.869,0.401-1.738,0.802-2.607,1.203c0.299-1.282,0.354-2.703,0.984-3.794
   c0.4-0.694,1.651-0.899,2.521-1.324c0.237,0.165,0.474,0.329,0.711,0.494c0.45,0.141,0.899,0.281,1.35,0.422l0.02,0.034
   c-0.091,0.113-0.124,0.24-0.098,0.382c0.253,0.49,0.506,0.979,0.758,1.468c0.1-0.299,0.198-0.597,0.298-0.896
   c2.186,0.561,3.675-0.315,4.649-2.253c0.808-0.986,1.616-1.973,2.423-2.958c0.016-0.179,0.032-0.356,0.048-0.534
   c-0.096,0.098-0.193,0.196-0.29,0.293c-0.804-0.135-1.608-0.271-2.412-0.406c-0.139-0.279-0.278-0.559-0.417-0.838
   c-0.104,0.095-0.208,0.19-0.312,0.286c0.252,0.175,0.505,0.351,0.757,0.525c-0.061,1.104-0.123,2.207-0.184,3.311
   c-1.255,0.043-2.509,0.087-3.764,0.131l0.018,0.014c0.051-0.589,0.102-1.178,0.153-1.767c0.146-0.15,0.293-0.301,0.44-0.451
   c-0.249,0.027-0.498,0.055-0.747,0.082c-0.108-0.512-0.217-1.023-0.326-1.536c1.587-0.657,1.724-1.576,0.493-2.74l0.001,0.013
   c0.062-0.161,0.124-0.322,0.185-0.483c1.768-0.548,3.677-0.837,5.272-1.704c2.747-1.492,6.681-2.692,3.353-7.207
   c-0.139-0.188,0.927-1.744,1.643-1.958c0.833-0.249,2.689-1.947,2.67,1.041c-0.015,2.358,2.303,2.195,3.174,1.42
   c5.062-4.502,9.766-9.408,14.844-13.889c2.771-2.444,3.396-4.508,0.711-7.329c-0.419-0.44-0.198-2.396,0.155-2.531
   c6.582-2.508,6.138-11.878,13.414-13.754c0.447-0.115,0.386-2.201,0.562-3.37c-0.624,0.123-1.249,0.247-1.873,0.37
   c0.127,0.548,0.253,1.096,0.38,1.644c-2.901,0.026-5.803,0.052-8.704,0.079c1.313-1.469,2.625-2.938,3.938-4.406
   c4.022,5.583,3.413-4.161,6.42-1.94l-0.017-0.018c0.577,1.173,1.153,2.346,1.73,3.52c0.689-0.617,1.313-1.344,2.093-1.808
   c0.444-0.265,1.195-0.259,1.715-0.089c0.721,0.234,1.354,0.737,2.025,1.125c-0.394,0.605-0.715,1.67-1.191,1.744
   c-4.888,0.759-5.568,4.958-7.098,8.458c-0.311,0.712-0.478,1.489-0.711,2.235c0.356-0.227,0.689-0.504,1.072-0.669
   c1.505-0.65,3.404-0.887,4.475-1.958c5.121-5.123,10.006-10.481,15.016-15.717c0.482-0.503,1.24-0.743,1.87-1.104
   c-0.222,0.652-0.301,1.409-0.688,1.941c-5.921,8.168-13.261,14.934-20.9,21.456c-3.669,3.133-6.817,6.877-10.201,10.345l0-0.001
   c-3.844,2.332-2.034,4.619,0.053,6.904c-0.897,0.938-1.795,1.875-2.692,2.813c-0.165-0.199-0.33-0.398-0.494-0.598
   c-10.098-3.087-4.298,5.883-6.603,8.706c-1.032,0.259-2.432,0.176-3.04,0.83c-7.728,8.306-15.34,16.718-22.982,25.104
   c-2.297,4.167-3.473,9.101-8.021,11.729c-0.831,1.613-1.661,3.226-2.491,4.839c-0.046,0.058-0.105,0.109-0.133,0.174
   c-0.008,0.019,0.098,0.115,0.107,0.109c0.061-0.04,0.108-0.098,0.161-0.15c0.245,0.682,0.489,1.363,0.734,2.045
   c-0.358,0.104-0.749,0.359-1.07,0.29c-3.834-0.824-1.672,3.774-3.933,4.409c-0.05,0.014,0.2,1.094,0.31,1.677
   c0.17-0.049,0.34-0.098,0.51-0.147c0.173,0.505,0.346,1.01,0.519,1.515c-0.686,1.487-0.691,2.529,1.428,2.18
   c-0.766,0.827-1.533,1.654-2.299,2.48c-1.621-1.166-3.251-2.351-2.613,1.241c-0.775-0.24-1.867-0.918-2.26-0.633
   c-0.917,0.666-1.499,1.794-2.216,2.736c1.482,0.588,2.965,1.177,4.448,1.766c-0.01,0.225-0.02,0.451-0.03,0.676
   c-1.286,0.097-2.572,0.193-3.857,0.29c-0.175,0.006-0.35,0.011-0.524,0.017c-0.167,0.052-0.333,0.104-0.499,0.156
   c-0.154-0.195-0.309-0.391-0.463-0.586c-0.365-0.305-0.838-2.365-1.085-0.061c-0.245,2.274-3.477,6.733,3.045,5.047
   c-1.212,1.135-2.424,2.27-3.637,3.404c-0.325,0.417-0.649,0.833-0.974,1.249c0.576-0.092,1.151-0.183,1.727-0.274
   C106.904,249.778,106.915,250.112,106.926,250.448z M101.89,242.185c0.056-0.027,0.14-0.042,0.16-0.086
   c0.02-0.043-0.023-0.116-0.039-0.176c-0.085,0.048-0.17,0.096-0.255,0.144C101.8,242.106,101.845,242.146,101.89,242.185z
    M131.733,193.971c-0.106-0.194-0.176-0.321-0.246-0.448c-0.168,0.119-0.369,0.215-0.489,0.371
   c-0.044,0.057,0.102,0.259,0.161,0.396C131.347,194.185,131.535,194.081,131.733,193.971z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#B5BBC5" d="M98.599,217.503c-1.01,1.181-2.021,2.363-3.031,3.543
   c-0.33-0.916-0.707-1.817-0.983-2.749c-1.533-5.184-3.448-10.325-1.953-15.882c1.414,4.428,2.782,8.871,4.289,13.267
   C97.166,216.396,98.024,216.901,98.599,217.503z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M148.254,155.129c0.934,0.367,1.867,0.735,2.8,1.103
   c-1.159,1.251-2.319,2.501-3.479,3.752l0.028,0.021c0.048-0.283,0.097-0.565,0.146-0.849c0.019-0.136,0.039-0.273,0.059-0.41
   c-0.122,0.036-0.242,0.071-0.364,0.106c-0.274,0.542-0.548,1.083-0.823,1.624l-0.021,0.025c-0.163,0.163-0.326,0.326-0.489,0.489
   l0.014-0.017c-2.89-0.5-1.023-1.91-0.583-3.047c0.833-0.804,1.667-1.608,2.501-2.413c0.086,0.367,0.173,0.733,0.258,1.1
   c0.17-0.113,0.339-0.226,0.509-0.338c-0.265-0.248-0.529-0.496-0.793-0.743C148.086,155.392,148.166,155.258,148.254,155.129z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M116.143,193.359c-1.247,0.936,1.517,5.314-2.975,3.464
   C114.16,195.669,115.152,194.514,116.143,193.359z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M153.479,150.179c0.381,0.946,0.787,1.886,1.086,2.857
   c0.02,0.063-0.775,0.378-1.191,0.576c-0.212-0.788-0.423-1.575-0.634-2.361C152.988,150.895,153.234,150.537,153.479,150.179z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M113.214,196.791c-0.424,0.34-0.848,0.679-1.271,1.019
   C112.366,197.471,112.79,197.131,113.214,196.791z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M166.259,136.378c-0.157,0.016-0.313,0.032-0.47,0.048
   C165.945,136.41,166.102,136.394,166.259,136.378z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M116.186,192.935c-0.016,0.161-0.032,0.321-0.048,0.482
   C116.119,193.252,116.134,193.092,116.186,192.935z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M163.13,169.224c0.897-0.938,1.795-1.875,2.692-2.813l-0.046,0.002
   c0.33-0.164,0.66-0.328,0.99-0.492l0.007-0.007c0.164-0.164,0.328-0.327,0.492-0.491l-0.001,0.001
   c0.165-0.165,0.329-0.331,0.493-0.496l-0.002,0.008c0.656-0.494,1.311-0.988,1.966-1.482l-0.021,0.01
   c0.336-0.17,0.671-0.341,1.007-0.511l-0.022,0.019c0.51-0.33,1.02-0.659,1.53-0.989c0.465,0.004,0.93,0.007,1.395,0.011
   c0,0,0.16,0.06,0.16,0.06l-0.044-0.168c0.585-1.125,1.17-2.251,1.755-3.376c0.283,2.065,4.555-0.651,3.547,2.959l0.013,0.015
   c-1.665,1.495-3.562,3.019,0.477,3.796c-0.31,0.502-0.621,1.004-0.931,1.506c0.02,0.194,0.04,0.389,0.06,0.583
   c-0.688,0.659-1.375,1.317-2.063,1.976c-4.898,4.332-9.797,8.664-14.696,12.995c-0.346,0.419-0.692,0.838-1.039,1.257l0.03-0.029
   c-5.13,0.653-2.575-4.666-4.846-6.235c2.304-2.823-3.495-11.793,6.603-8.706c-0.356,0.487-0.713,0.973-1.069,1.459
   C162.088,169.797,162.608,169.511,163.13,169.224z M168.87,170.953c-0.404-0.309-0.696-0.531-0.987-0.753
   c-0.554,0.87-1.134,1.726-1.625,2.63c-0.058,0.107,0.376,0.481,0.582,0.732C167.48,172.74,168.119,171.917,168.87,170.953z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M232.41,122.432c0.366,0.321,0.766,0.611,1.095,0.967
   c1.367,1.48,3.978-2.706,4.328,0.742c0.073,0.726-3.922,1.881-6.057,2.809c-6.937,3.013-13.879,6.012-20.82,9.016
   c-0.167,0.147-0.335,0.296-0.502,0.444c-1.674,0.962-3.347,1.925-5.019,2.888c-2.599,0.537-2.514,3.522-3.751,4.527
   c-2.354,1.911-5.877,2.383-8.915,3.452c-0.282,0.028-0.564,0.056-0.846,0.084c-0.145-0.152-0.291-0.305-0.436-0.458
   c0.801-0.705,1.603-1.409,2.404-2.114c2.426-2.423,4.851-4.846,7.276-7.269c0.34-0.354,0.68-0.707,1.02-1.06
   c5.299,1.806,8.243-2.986,12.357-4.491c2.539-0.929,4.828-2.544,7.229-3.852C225.106,125.826,230.44,127.278,232.41,122.432z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M232.41,122.432c-1.97,4.846-7.304,3.394-10.638,5.685
   c-3.542-4.965-7.606,1.049-11.309-0.539l0.006,0.008c1.002-1.208,2.004-2.417,3.005-3.626c-1.097,0.292-2.195,0.583-3.292,0.874
   c0.333-0.526,0.637-1.073,1.01-1.569c0.187-0.248,0.498-0.402,0.752-0.599l-0.011,0.01
   C218.759,122.595,225.584,122.514,232.41,122.432z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M205.433,139.297c1.673-0.963,3.346-1.925,5.02-2.888l0.008,0.012
   c0.162,0.002,0.324,0.004,0.486,0.007l0.013-0.01c3.049,1.049,5.314,5.107,9.333,2.433c-5.426,3.608-10.852,7.216-16.278,10.825
   c-0.108-0.296-0.217-0.592-0.326-0.889c-0.073-0.071-0.146-0.143-0.218-0.214c-0.315,0.627-0.632,1.253-0.947,1.88
   c-0.135,0.056-0.273,0.099-0.416,0.13c-0.265-2.573,1.281-3.853,3.382-4.737c0.271,0.104,0.539,0.213,0.813,0.305
   c0.021,0.007,0.083-0.111,0.127-0.171c-0.279-0.089-0.558-0.179-0.836-0.268c4.438-0.255-1.242-5.788,3.162-6.061
   C207.649,139.532,206.541,139.415,205.433,139.297z M211.631,142.248c0.668-0.716,1.17-1.198,1.575-1.752
   c0.061-0.083-0.336-0.501-0.522-0.764c-0.52,0.438-1.083,0.84-1.527,1.345C211.06,141.187,211.385,141.667,211.631,142.248z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M193.889,144.788c-0.802,0.705-1.603,1.41-2.403,2.115
   c-0.378,0.338-0.756,0.677-1.135,1.016c0.523-0.187,1.047-0.373,1.57-0.559c0.282-0.028,0.564-0.057,0.846-0.085
   c-0.512,2.557-1.023,5.114-1.535,7.671c-0.601-0.122-1.203-0.244-1.801-0.365c-0.131,0.168-0.265,0.336-0.399,0.503
   c0.191,0.051,0.383,0.103,0.574,0.154c0.086,0.12,0.166,0.245,0.239,0.373c-0.286,0.352-0.571,0.703-0.856,1.055
   c-0.09-0.068-0.179-0.138-0.268-0.207c-0.446-0.13-0.892-0.261-1.338-0.391l-0.004,0.002c-1.9-1.352-3.801-2.705-5.701-4.057
   C185.049,148.424,188.797,145.471,193.889,144.788z M188.452,154.208c0.104-0.599,0.208-1.199,0.312-1.798
   c-0.417,0.119-0.834,0.237-1.252,0.355c0.255,0.423,0.512,0.847,0.769,1.271c-0.043,0.059-0.103,0.113-0.122,0.178
   c-0.008,0.026,0.083,0.118,0.112,0.11C188.337,154.308,188.392,154.249,188.452,154.208z M189.241,148.171
   c-0.772,0.654-1.372,1.16-1.971,1.667c0.263,0.249,0.735,0.731,0.757,0.711c0.578-0.518,1.123-1.076,1.625-1.668
   C189.702,148.824,189.438,148.501,189.241,148.171z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M187.914,126.12c-0.82,0.516-1.752,1.103-2.686,1.689
   c-0.166-0.265-0.538-0.656-0.465-0.775c0.603-0.987,1.288-1.924,1.948-2.876C186.937,124.527,187.163,124.896,187.914,126.12z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M176.583,169.344c0.688-0.659,1.375-1.317,2.063-1.976
   c0.57-0.217,1.141-0.434,1.71-0.65c0.146,0.153,0.293,0.307,0.439,0.46C179.391,167.9,177.987,168.623,176.583,169.344z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M180.356,166.718c0.214-0.275,0.428-0.55,0.643-0.824l-0.011,0.011
   c0.168-0.336,0.337-0.672,0.505-1.007l-0.012,0.017c0.753-0.254,1.506-0.51,2.258-0.765c0.106,0.139,0.214,0.279,0.322,0.419
   c-1.088,0.87-2.177,1.74-3.265,2.61C180.649,167.025,180.502,166.872,180.356,166.718z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M203.182,122.373c0.312,0.467,0.707,0.809,0.648,1.032
   c-0.08,0.305-0.504,0.521-0.781,0.776c-0.243-0.27-0.673-0.536-0.677-0.809C202.368,123.102,202.792,122.825,203.182,122.373z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M171.185,161.229c0.192-0.417,0.384-0.834,0.577-1.251
   c0.129,0.407,0.258,0.813,0.387,1.22C171.828,161.208,171.507,161.219,171.185,161.229z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M169.72,163.454c-0.655,0.494-1.311,0.988-1.966,1.482
   C168.41,164.442,169.065,163.948,169.72,163.454z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M178.586,166.785c0.31-0.502,0.621-1.004,0.931-1.506
   C179.207,165.78,178.896,166.283,178.586,166.785z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M199.904,122.866c-0.39,0.503-0.781,1.006-1.171,1.509
   c0.253-0.615,0.507-1.229,0.76-1.843C199.629,122.644,199.766,122.755,199.904,122.866z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M202.186,136.46c-0.34,0.353-0.68,0.706-1.02,1.06
   c-0.024-0.438-0.049-0.875-0.073-1.313C201.457,136.291,201.821,136.375,202.186,136.46z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M180.999,165.894c-0.027-0.611-0.053-1.223-0.08-1.834
   c0.187,0.285,0.374,0.57,0.561,0.854l0.012-0.017c-0.168,0.335-0.336,0.671-0.505,1.007L180.999,165.894z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M188.988,156.667c-0.537-0.199-1.073-0.397-1.609-0.596
   l0.004-0.002c0.446,0.13,0.892,0.26,1.338,0.391C188.809,156.529,188.898,156.598,188.988,156.667z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M208.998,128.567c0.49-0.327,0.98-0.654,1.471-0.981l-0.006-0.008
   c-0.492,0.326-0.983,0.653-1.474,0.979C208.988,128.557,208.998,128.567,208.998,128.567z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M203.512,131.854c-0.046,0.048-0.086,0.094-0.125,0.14
   c-0.079-0.114-0.181-0.22-0.224-0.346c-0.013-0.04,0.131-0.133,0.204-0.202C203.417,131.581,203.468,131.715,203.512,131.854z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M179.027,161.467c0.333-0.259,0.667-0.519,1.006-0.77
   c0.001-0.001,0.084,0.109,0.13,0.167c-0.375,0.206-0.749,0.412-1.124,0.618C179.04,161.482,179.027,161.467,179.027,161.467z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M171.185,161.229c0.322-0.01,0.643-0.021,0.964-0.031
   c0.021,0.262,0.043,0.523,0.065,0.784c-0.51,0.33-1.02,0.659-1.53,0.989C170.851,162.39,171.018,161.81,171.185,161.229z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M188.368,109.775c0.32-0.137,0.639-0.275,0.959-0.412
   C189.007,109.5,188.688,109.638,188.368,109.775z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M201.256,132.178c0.068,0.034,0.137,0.068,0.207,0.102
   c-0.047,0.062-0.117,0.184-0.138,0.175c-0.102-0.038-0.191-0.11-0.284-0.171C201.112,132.25,201.184,132.214,201.256,132.178z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M170.706,162.952c-0.336,0.17-0.671,0.341-1.007,0.511
   C170.035,163.293,170.37,163.123,170.706,162.952z"/>
      <polygon fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" points="203.561,134.779 203.561,134.594 203.752,134.687 " />
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M166.758,159.512c-0.33-0.001-0.659-0.003-0.989-0.005l0,0.001
   c0.32-0.009,0.64-0.018,0.96-0.026L166.758,159.512z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M166.766,165.921c-0.33,0.164-0.66,0.328-0.99,0.492
   C166.106,166.249,166.436,166.085,166.766,165.921z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M167.756,164.928c-0.164,0.166-0.329,0.331-0.493,0.496
   C167.428,165.259,167.592,165.093,167.756,164.928z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M210.453,136.409c0.167-0.148,0.335-0.296,0.502-0.444
   c-0.005,0.151-0.003,0.302,0.005,0.452l-0.013,0.01c-0.162-0.016-0.324-0.018-0.486-0.007L210.453,136.409z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M167.265,165.423c-0.164,0.164-0.328,0.327-0.492,0.491
   C166.937,165.75,167.101,165.587,167.265,165.423z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M176.578,130.047l-0.017-0.018L176.578,130.047z" />
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M208.493,129.053l0.01,0.009L208.493,129.053z" />
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M208.503,129.062c0.165-0.165,0.33-0.33,0.495-0.495
   c0,0-0.01-0.01-0.01-0.01c-0.165,0.165-0.33,0.331-0.495,0.496L208.503,129.062z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M211.944,122.666c0,0-0.011,0.01-0.011,0.01L211.944,122.666z" />
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M211.943,115.311l-0.013-0.013L211.943,115.311z" />
      <polygon fill-rule="evenodd" clip-rule="evenodd" fill="#291913" points="166.729,159.481 166.83,159.384 166.758,159.512 " />
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M107.231,210.487c0.271-3.958,3.256,3.067,3.532-0.872
   l-0.016-0.025c0.137,1.203-0.358,2.864,2.058,1.73c1.056-0.496,2.591,0.028,3.909,0.089c-1.36,1.415-2.536,3.763-4.114,4.075
   c-5.261,1.04,3.127,4.734-1.307,5.616c-1.379,0.275-2.698,0.854-4.045,1.293c-0.179-1.809-0.357-3.616-0.536-5.424
   c0.25-2.084,0.5-4.168,0.75-6.253C107.384,210.639,107.308,210.563,107.231,210.487z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M106.712,216.969c0.179,1.808,0.357,3.616,0.536,5.424
   c-1.219,1.122-2.438,2.243-3.656,3.364c-0.613-0.858-1.82-1.785-1.731-2.563c0.311-2.712-1.155-6.113,2.268-7.778
   C104.621,215.177,105.833,216.415,106.712,216.969z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M102.397,232.933c-0.932,0.63-1.863,1.26-2.794,1.889
   c-0.098-0.12-0.197-0.239-0.298-0.357c2.333-3.644-0.287-3.473-2.751-3.955c-0.818-0.16-1.69-1.887-1.851-2.996
   c-0.138-0.956,0.702-2.052,1.11-3.087c1.495,1.825,2.99,3.649,4.485,5.474c0.131,0.03,0.263,0.061,0.394,0.091
   c-0.031-0.13-0.063-0.261-0.094-0.392c0.283-0.898,0.565-1.795,0.848-2.693c0.215,0.175,0.408,0.397,0.65,0.519
   c1.708,0.863,2.517,2.004,1.26,3.834c-0.105-0.102-0.226-0.177-0.363-0.227c-0.036-0.118-0.083-0.232-0.142-0.341
   c-0.269-0.353-0.537-0.707-0.806-1.06c-0.063,0.392-0.126,0.783-0.189,1.175c0.018,0.151,0.051,0.299,0.1,0.444
   C102.103,231.812,102.25,232.373,102.397,232.933z M101.664,228.564c0.134,0.069,0.265,0.148,0.406,0.198
   c0.021,0.007,0.09-0.122,0.137-0.188c-0.126-0.068-0.247-0.149-0.381-0.192C101.796,228.372,101.723,228.493,101.664,228.564z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M123.857,201.528c-0.61,1.056-1.221,2.111-1.831,3.166
   c-2.862,0.777-8.453,5.612-4.831-3.25c5.775,2.681,0.928-3.715,2.723-3.313C121.41,198.466,122.557,200.338,123.857,201.528z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M98.03,223.998c-0.457-0.677-0.85-1.054-0.78-1.189
   c0.588-1.124,1.262-2.204,1.91-3.296c0.403,0.83,1.209,1.726,1.088,2.471C100.138,222.654,99.012,223.16,98.03,223.998z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M110.763,209.615c1.08-0.329,2.159-0.658,3.238-0.987
   c-0.013,0.414-0.025,0.827-0.038,1.24c-1.072-0.092-2.144-0.185-3.215-0.278C110.748,209.59,110.763,209.615,110.763,209.615z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M146.623,163.934c-0.166-0.986-0.332-1.973-0.498-2.96
   l-0.014,0.017c0.163-0.163,0.326-0.326,0.489-0.489l0.021-0.025c0.328-0.158,0.655-0.314,0.982-0.472l-0.028-0.021
   c0.732,1.57,1.501,3.149-0.966,3.934L146.623,163.934z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M128.009,195.853c0.044-0.453,0.087-0.905,0.131-1.358
   c0.82,0.243,1.64,0.486,2.459,0.728c-0.556,0.86-1.112,1.72-1.668,2.581l0.02,0.027c-0.353-0.332-0.705-0.664-1.057-0.996
   c0.191-0.041,0.383-0.082,0.575-0.122c0.048-0.022,0.097-0.045,0.145-0.067c-0.054-0.066-0.108-0.132-0.162-0.198
   C128.306,196.25,128.158,196.051,128.009,195.853z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M108.051,230.739c-0.725,0.897-1.45,1.795-2.175,2.693
   c-0.389-0.368-0.777-0.736-1.166-1.104c0.096-0.082,0.191-0.164,0.286-0.247c0.108-0.466,0.217-0.933,0.325-1.4l-0.005,0.006
   c0.085,0.122,0.202,0.188,0.35,0.199c0.232,0.094,0.465,0.189,0.697,0.284c-0.13-0.194-0.26-0.388-0.391-0.582
   c0.405-0.166,0.811-0.331,1.216-0.497c0.064,0.065,0.129,0.129,0.195,0.192C107.606,230.436,107.829,230.587,108.051,230.739z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M128.932,197.803c-0.394,0.276-0.788,0.551-1.181,0.828
   c-0.587,0.22-1.175,0.439-1.763,0.658l-0.007,0.006c-0.246-0.328-0.492-0.656-0.738-0.984c0.583-0.295,1.167-0.591,1.75-0.887
   c0.653,0.136,1.306,0.271,1.958,0.406L128.932,197.803z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M125.964,194.902c0.179-0.933,0.357-1.865,0.537-2.797
   c0.27,0.892,0.54,1.783,0.809,2.674C126.861,194.82,126.413,194.861,125.964,194.902z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M94.195,237.45c0.278,0.488,0.592,0.962,0.8,1.479
   c0.037,0.092-0.365,0.362-0.564,0.55c-0.168-0.584-0.336-1.169-0.504-1.753C94.016,237.633,94.106,237.542,94.195,237.45z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M130.433,184.05c1.231,1.164,1.094,2.083-0.493,2.74
   C130.104,185.876,130.268,184.963,130.433,184.05z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M106.77,227.668c-0.486,0.255-0.696,0.456-0.898,0.448
   c-0.236-0.009-0.466-0.2-0.699-0.312c0.172-0.141,0.327-0.356,0.521-0.402C105.879,227.359,106.106,227.492,106.77,227.668z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M99.415,243.911c-0.451,0.102-0.902,0.203-1.354,0.305
   c0.127-0.34,0.254-0.679,0.381-1.019C98.767,243.436,99.091,243.673,99.415,243.911z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M127.124,199.716c-0.095,0.322-0.19,0.646-0.286,0.968
   c-0.286-0.462-0.571-0.925-0.856-1.388c0,0,0.007-0.006,0.006-0.006C126.367,199.431,126.746,199.573,127.124,199.716z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M102.994,231.033c0.137,0.05,0.258,0.125,0.363,0.227
   c-0.32,0.557-0.64,1.114-0.959,1.672c-0.147-0.56-0.295-1.121-0.442-1.681C102.301,231.179,102.647,231.106,102.994,231.033z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M128.952,197.831c-0.653-0.135-1.306-0.271-1.958-0.406
   c0.301-0.197,0.602-0.394,0.902-0.59C128.248,197.167,128.6,197.499,128.952,197.831z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M128.923,191.904c0.499-0.48,0.997-0.962,1.496-1.443l-0.018-0.014
   c-0.359,0.469-0.719,0.938-1.078,1.408c-0.136-0.015-0.263,0.012-0.38,0.083L128.923,191.904z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#8B898A" d="M105.321,230.681c-0.236-0.303-0.473-0.606-0.709-0.91
   c0.067-0.036,0.135-0.07,0.203-0.106c0.167,0.341,0.334,0.682,0.501,1.022L105.321,230.681z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M112.555,217.094c-0.095,0.094-0.19,0.242-0.282,0.239
   c-0.128-0.003-0.253-0.119-0.38-0.187c0.106-0.11,0.199-0.287,0.321-0.309C112.31,216.82,112.441,216.993,112.555,217.094z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M152.526,163.54c0.128,0.049,0.25,0.1,0.373,0.151
   c-0.09,0.104-0.162,0.26-0.274,0.297c-0.093,0.031-0.232-0.076-0.351-0.122C152.356,163.758,152.438,163.65,152.526,163.54z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M119.641,197.072c-0.114,0.085-0.226,0.166-0.338,0.245
   c-0.071-0.128-0.232-0.324-0.196-0.374c0.104-0.138,0.288-0.216,0.44-0.318C119.579,196.772,119.61,196.919,119.641,197.072z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M110.575,224.982c-0.014-0.24-0.028-0.48-0.042-0.72
   c0.143,0.134,0.285,0.269,0.428,0.402C110.833,224.77,110.704,224.876,110.575,224.982z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M104.71,232.328c-0.008-0.177-0.017-0.354-0.025-0.53
   c0.103,0.095,0.207,0.189,0.311,0.284C104.901,232.164,104.806,232.246,104.71,232.328z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#8B898A" d="M127.573,191.481c-0.237-0.165-0.474-0.329-0.711-0.494
   c0.134-0.246,0.268-0.491,0.402-0.736c0.084,0.086,0.17,0.171,0.257,0.254C127.539,190.831,127.556,191.156,127.573,191.481z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M99.603,234.822c-0.251,0.03-0.502,0.059-0.754,0.088
   c0.152-0.148,0.305-0.297,0.457-0.445C99.406,234.583,99.505,234.702,99.603,234.822z"/>
      <polygon fill-rule="evenodd" clip-rule="evenodd" fill="#291913" points="146.61,163.919 146.537,163.982 146.623,163.934 " />
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M87.185,243.986l-0.004,0.004L87.185,243.986z" />
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M93.927,237.725c0.089-0.092,0.179-0.183,0.269-0.274
   C94.106,237.542,94.016,237.633,93.927,237.725z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M130.619,183.58c-0.062,0.161-0.124,0.322-0.185,0.483
   C130.495,183.902,130.557,183.741,130.619,183.58z"/>
      <polygon fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" points="124.756,200.603 124.711,200.482 124.831,200.499 " />
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M128.453,196.448c0.054,0.066,0.108,0.132,0.162,0.198
   c-0.048,0.022-0.097,0.045-0.145,0.067C128.487,196.623,128.482,196.534,128.453,196.448z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#8B898A" d="M127.264,190.251c0.167-0.015,0.335-0.031,0.502-0.046
   c-0.082,0.1-0.164,0.2-0.246,0.301C127.434,190.423,127.348,190.338,127.264,190.251z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M107.188,230.091c0.065,0.064,0.13,0.128,0.195,0.192
   C107.317,230.22,107.252,230.156,107.188,230.091z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M81.577,323.38c-0.864,0.213-1.728,0.427-2.591,0.64
   c2.782-8.108,5.58-16.21,8.31-24.336c0.181-0.539-0.154-1.251-0.249-1.882c-0.884,0.243-2.749-2.203-2.59,0.946
   c0.007,0.152-0.256,0.317-0.394,0.477c-0.159-0.533-0.505-1.093-0.433-1.592c0.108-0.738,0.744-2.063,0.761-2.057
   c4.255,1.55,2.609-1.706,2.814-3.419c0.144-0.121,0.287-0.241,0.431-0.362c-0.319-0.146-0.638-0.291-0.957-0.437
   c1.498-0.339-1.986-4.49,2.15-2.811c0.011,0.213,0.022,0.426,0.033,0.639c0.095-0.023,0.19-0.046,0.285-0.068
   c-0.103-0.19-0.206-0.379-0.309-0.569c0.402,0.072,0.805,0.144,1.207,0.215c0.134,0.121,0.268,0.242,0.402,0.362
   c-0.043-0.201-0.087-0.401-0.13-0.602c0.087-1.102,0.174-2.203,0.262-3.305l0.021,0.015c-3.279-0.625,3.142-2.778-0.526-3.342
   c0.063-0.071,0.127-0.14,0.193-0.208c4.274,0.449,1.168-4.321,3.641-5.185c0.172-0.06,0.184-0.575,0.271-0.878
   c-0.565,0.262-1.13,0.525-1.696,0.788c0.072-0.487,0.143-0.974,0.214-1.461c-0.052-0.649-0.104-1.299-0.156-1.95
   c0.112-0.108,0.224-0.216,0.335-0.326c0.947-0.227,1.894-0.455,2.841-0.682c0.675-0.642,1.351-1.284,2.025-1.925
   c0.232,2.312,0.465,4.624,0.697,6.936c-0.476-0.427-0.916-2.345-1.588-0.367c-2.663,7.834-5.128,15.735-7.667,23.609
   c-0.025,0.142-0.051,0.286-0.077,0.431c-0.451,1.65-0.902,3.301-1.352,4.952c-0.051,0.276-0.101,0.552-0.151,0.828
   c-2.135,8.814-4.27,17.628-6.405,26.442c-2.605-3.286,0.661-6.105,0.728-9.179c0.105-0.285,0.211-0.567,0.318-0.852
   C82.021,323.037,81.798,323.208,81.577,323.38z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M97.74,270.064c-0.675,0.642-1.35,1.283-2.026,1.926
   c-0.725-0.163-1.448-0.326-2.172-0.489l0.029,0.01c-0.333-0.16-0.667-0.32-1-0.48l-0.012-0.001c-0.474-0.9-0.947-1.8-1.42-2.701
   c-0.169,0.738-0.339,1.475-0.509,2.213l0.014-0.03c-0.167,0.157-0.335,0.314-0.503,0.472c-0.17-0.007-0.34-0.015-0.511-0.022
   c-1.107-1.018-1.941-2.776-3.915-1.443l-0.014,0.012c-0.539-1.744-1.077-3.488-1.616-5.232c0.707,0.271,1.413,0.543,2.12,0.814
   c-0.185,0.205-0.369,0.411-0.554,0.616c0.053,0.042,0.106,0.084,0.16,0.126c0.128-0.25,0.256-0.501,0.385-0.751
   c3.549,1.13,4.175-2.814,6.527-3.759c0.453,0.153,0.908,0.305,1.362,0.458c3.811-1.171-4.08,7.226,2.859,3.498
   c0.377,1.141,0.754,2.281,1.131,3.421C97.963,269.167,97.852,269.615,97.74,270.064z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M79.995,289.115c0.129,2.291,0.389,4.584,0.354,6.873
   c-0.043,2.865,0.716,6.025-2.276,8.085c-0.348-2.863-1.553-5.987-0.786-8.511c0.672-2.213-2.071-5.133,1.34-6.603
   C79.083,289.011,79.539,289.063,79.995,289.115z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M79.995,289.115c-0.456-0.052-0.912-0.104-1.368-0.157
   c0.158-5.064,0.309-10.129,0.504-15.192c0.006-0.173,0.461-0.33,0.707-0.494c0.258,0.533,0.752,1.072,0.737,1.596
   C80.433,279.618,80.201,284.366,79.995,289.115z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M89.508,282.926c-0.664-3.086-3.864-6.922,2.359-7.972
   c0.105,0.483,0.21,0.966,0.315,1.449c-0.639,1.759-1.277,3.519-1.916,5.279c-0.065,0.068-0.13,0.138-0.193,0.208
   c-0.097,0.253-0.193,0.506-0.289,0.759C89.691,282.742,89.6,282.834,89.508,282.926z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M89.63,270.961c0.17,0.007,0.34,0.015,0.511,0.022
   c0.167-0.157,0.335-0.314,0.503-0.472l-0.014,0.03c0.643,0.163,1.286,0.325,1.929,0.488l0.012,0.001c0.333,0.16,0.667,0.32,1,0.48
   l-0.029-0.01c-0.223,0.39-0.445,0.78-0.668,1.17c-0.111,0.109-0.223,0.218-0.335,0.326C91.569,272.318,90.6,271.64,89.63,270.961z"
      />
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M86.639,294.606c-0.323,0.148-0.879,0.448-0.904,0.408
   c-0.476-0.744-0.948-1.504-1.271-2.32c-0.078-0.196,0.381-0.604,0.593-0.915C85.551,292.662,86.045,293.545,86.639,294.606z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M77.467,283.163c0.333,0.604,0.704,0.971,0.64,1.233
   c-0.087,0.361-0.503,0.642-0.778,0.957c-0.208-0.26-0.609-0.545-0.58-0.775C76.801,284.177,77.118,283.811,77.467,283.163z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M84.262,267.257c-0.242,0.194-0.421,0.441-0.555,0.419
   c-0.182-0.031-0.329-0.268-0.492-0.416c0.15-0.155,0.289-0.416,0.453-0.433C83.821,266.812,84.001,267.056,84.262,267.257z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M87.637,281.373c-0.058,0.139-0.113,0.272-0.167,0.405
   c-0.102-0.17-0.236-0.332-0.288-0.516c-0.02-0.069,0.152-0.192,0.237-0.29C87.493,281.104,87.567,281.235,87.637,281.373z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M85.85,285.832c0.083,0.085,0.204,0.168,0.211,0.261
   c0.01,0.134-0.061,0.274-0.098,0.412c-0.123-0.107-0.292-0.195-0.349-0.33C85.584,286.104,85.755,285.95,85.85,285.832z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#8B898A" d="M86.679,291.357c0.319,0.146,0.638,0.291,0.957,0.437
   c-0.144,0.121-0.287,0.242-0.431,0.362C87.03,291.889,86.854,291.623,86.679,291.357z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M88.838,288.548c0.103,0.19,0.206,0.379,0.309,0.569
   c-0.095,0.022-0.19,0.045-0.285,0.068c-0.011-0.213-0.022-0.426-0.033-0.639L88.838,288.548z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#8B898A" d="M92.182,276.404c-0.105-0.483-0.21-0.966-0.315-1.449
   c0.275-0.003,0.552-0.005,0.828-0.008c-0.071,0.487-0.143,0.974-0.214,1.461C92.381,276.386,92.282,276.385,92.182,276.404z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M94.743,257.554c-0.178-0.202-0.355-0.405-0.533-0.607
   c0.298,0.075,0.597,0.149,0.895,0.224C94.984,257.298,94.864,257.426,94.743,257.554z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M88.681,287.875c-0.067,0.05-0.129,0.096-0.191,0.142
   c-0.062-0.123-0.136-0.243-0.177-0.373c-0.008-0.025,0.112-0.09,0.173-0.137C88.553,287.628,88.621,287.75,88.681,287.875z"/>
      <polygon fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" points="78.786,275.768 78.787,275.583 78.956,275.677 " />
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M85.715,269.518c0,0-0.014,0.013-0.014,0.012
   S85.715,269.518,85.715,269.518z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M89.784,282.65c0.019,0.167,0.038,0.335,0.057,0.502
   c-0.111-0.076-0.221-0.151-0.333-0.227C89.6,282.834,89.691,282.742,89.784,282.65z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M201.145,118.754c0.148-3.595,0.298-7.189,0.446-10.784
   c0.239,0.273,0.463,0.562,0.728,0.807c0.051,0.048,0.262-0.078,0.398-0.124c-0.319-0.294-0.637-0.589-0.955-0.884
   c0.206-0.588,0.412-1.177,0.618-1.766c1.14,0.402,3.166,0.667,3.253,1.231c0.259,1.675,0.138,3.639-0.544,5.175
   c-0.999,2.252-2.613,4.231-3.965,6.327L201.145,118.754z"/>
      <polygon fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" points="198.675,100.694 198.804,100.562 198.845,100.721 " />
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M190.814,112.358c0.058,0.107,0.115,0.215,0.173,0.323
   c-0.047-0.037-0.117-0.063-0.138-0.111c-0.028-0.065-0.019-0.146-0.025-0.22L190.814,112.358z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M193.42,110.032c-0.08-0.079-0.16-0.159-0.241-0.238
   C193.26,109.874,193.34,109.953,193.42,110.032z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M193.18,109.794c0.08,0.079,0.16,0.158,0.24,0.237
   C193.34,109.953,193.26,109.874,193.18,109.794z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#B5BBC5" d="M276.304,450.055c-9.468,0.111-19.01-0.494-28.391,0.469
   c-28.038,2.878-52.75-6.014-76.21-20.12c-5.486-3.298-11.273-6.283-0.327-9.98c9.337,0.265,17.935-2.712,26.567-5.804
   c14.169-5.076,25.839-14.349,38.309-22.29c5.634,10.459,13.441,19.171,22.613,26.508c12.831,10.264,25.741,21.85,43.978,17.589
   c12.191-2.849,23.087-9.129,31.314-19.125c2.145-1.96,4.291-3.92,6.436-5.88c0.365-0.378,0.73-0.757,1.094-1.135
   c0.033-0.108,0.104-0.178,0.215-0.209c0.518-0.537,1.035-1.076,1.553-1.614l0.049-0.043c2.051-0.473,3.324-1.642,3.438-3.846
   l0.039-0.039c4.66-2.716,7.262-7.082,9.439-11.787c1.078,0.301,2.389,0.307,3.199,0.945c13.873,10.925,28.949,19.604,45.863,24.915
   c6.057,1.542,12.111,3.079,18.166,4.63c1.027,0.263,2.043,0.58,3.064,0.872c-0.643,1.013-1.051,2.39-1.967,2.985
   c-21.225,13.806-43.725,23.974-69.758,23.195c-14.434-0.431-28.836-3.058-43.291-0.335c-0.514-0.065-1.025-0.131-1.539-0.195
   c-1.602-0.22-3.205-0.438-4.808-0.657c-7.034-3.467-13.91-0.758-20.821,0.532c-0.438,0.055-0.875,0.11-1.313,0.166
   c-1.034,0.103-2.067,0.205-3.101,0.307c-0.953-0.967-1.854-1.35-2.577,0.232C277.125,450.245,276.715,450.15,276.304,450.055z
    M249.41,438.644c3.103-1.146,7.341-2.711,11.579-4.276c-1.002-2.494-1.494-5.393-3.095-7.413
   c-7.321-9.237-14.69-18.477-22.769-27.028c-1.168-1.237,2.211-6.943-2.529-3.644c-14.896,10.366-30.435,19.31-47.812,24.82
   c-0.547,0.173-0.959,0.772-1.434,1.173c0.208,0.195,0.511,0.599,0.608,0.554c4.803-2.211,7.091,2.339,10.593,3.704
   C211.711,433.224,229.671,436.636,249.41,438.644z M357.639,435.051c8.596-1.177,20.719-4.64,32.383-10.059
   c4.834-2.246,4.258-5.799,1.691-7.654c-10.178-7.358-20.873-14.004-31.438-20.815c-0.645-0.415-2.348-0.358-2.699,0.115
   c-7.57,10.239-14.912,20.648-22.566,30.824c-1.902,2.528-0.773,4.765,0.047,6.665c0.471,1.093,3.125,1.714,4.803,1.727
   C344.414,435.889,348.973,435.486,357.639,435.051z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#8B898A" d="M361.254,489.985c7.973-15.486,23.35-12.727,36.787-15.399
   c11.307-2.249,22.277-6.178,33.42-9.28c1.139-0.317,2.955-0.564,3.535,0.041c0.844,0.879,1.316,2.628,1.141,3.894
   c-2.131,15.328-14.143,18.453-26.438,22.131c-22.25,6.655-44.316,14.591-67.314,17.506c-27.49,3.484-55.325,1.904-82.966,0.162
   c-27.313-1.722-52.168-13.485-78.241-20.313c-12.708-3.328-24.727-7.801-33.746-17.927c-0.986-1.107-1.314-2.8-1.948-4.221
   c1.902-0.559,3.809-1.619,5.705-1.6c18.28,0.188,34.643,9.125,52.451,11.66c5.897,0.839,12.261-2.571,17.712,1.961
   c3.874,4.029,7.749,8.059,11.623,12.087c3.292,4.007,8.295,3.805,12.546,4.15c18.551,1.513,37.176,3.559,55.731,3.206
   c20.095-0.383,40.378-0.929,60.125-5.804C364.502,491.469,361.182,490.751,361.254,489.985z M367.881,488.797
   c0.133-0.193,0.277-0.354,0.35-0.543c0.02-0.047-0.268-0.276-0.283-0.263c-0.162,0.132-0.309,0.288-0.42,0.462
   C367.512,488.475,367.715,488.638,367.881,488.797z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#B5BBC5" d="M361.254,489.985c-0.072,0.766,3.248,1.483,0.123,2.255
   c-19.746,4.875-40.029,5.421-60.125,5.804c-18.556,0.353-37.18-1.693-55.731-3.206c-4.251-0.346-9.254-0.144-12.545-4.15
   c-0.494-1.034-0.989-2.069-1.483-3.104c-3.117-4.749-6.295-9.46-9.284-14.288c-0.467-0.754-0.226-2.924-0.054-2.954
   c4.592-0.797,9.215-1.436,13.853-1.904c0.193-0.02,0.892,1.89,0.812,2.856c-0.2,2.426-0.712,4.827-1.1,7.238
   c0.726-1.821,1.421-3.655,2.207-5.45c0.136-0.312,0.622-0.529,0.991-0.67c9.816-3.732,19.383-8.495,29.509-10.972
   c30.52-7.466,59.484-1.327,87.316,11.981c0.592,2.283,1.186,4.567,1.777,6.852c-0.281-2.432-0.545-4.863-0.852-7.291
   c-0.072-0.562-0.291-1.104-0.441-1.655c4.35-3.579,9.322-2.663,13.516-0.562c2.414,1.211-0.258,4.08-1.334,5.891
   c-2.402,4.034-5.063,7.914-7.619,11.856c-0.297,0.405-0.594,0.81-0.889,1.215c-0.467,0.321-0.936,0.642-1.404,0.962
   c0.629-0.17,1.256-0.339,1.885-0.509C360.672,490.114,360.963,490.05,361.254,489.985z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#8B898A" d="M311.695,449.956c14.455-2.723,28.857-0.096,43.291,0.335
   c26.033,0.778,48.533-9.39,69.758-23.195c0.916-0.596,1.324-1.973,1.967-2.985c-1.021-0.292-2.037-0.609-3.064-0.872
   c-6.055-1.551-12.109-3.088-18.166-4.63c15.822,2.373,17.334,2.396,18.734,0.281c0.967-0.341,1.936-0.683,2.902-1.023
   c22.658,0.575,43.359-6.304,63.18-16.561c0.998-0.517,2.135-0.76,3.207-1.131c-9.207,15.132-18.244,30.382-30.082,43.704
   c-1.938,0.35-3.883,0.656-5.809,1.055c-14.033,2.903-25.746-14.256-41.48-3.521c-8.914,6.082-21.166,7.016-31.027,12.018
   c-21.83,11.072-42.48,1.692-63.541-2.008C318.289,450.846,314.986,450.44,311.695,449.956z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M241.795,459.984c36.543-12.993,73.072-13.197,109.59,0.113
   c0.701,1.669,1.402,3.338,2.104,5.006c0.053,0.395,0.107,0.79,0.16,1.186c-0.143,0.127-0.287,0.255-0.43,0.383
   c-7.82-1.963-15.641-3.925-23.461-5.888c-1.133-0.209-2.264-0.417-3.395-0.625c-0.502-0.131-1.006-0.262-1.508-0.393
   c-26.251-4.687-52.064-2.912-77.442,5.256c-2.793,0.446-5.587,0.892-8.38,1.338c0.072-0.169,0.145-0.338,0.216-0.507
   c0.338-0.889,0.676-1.776,1.014-2.666C240.774,462.12,241.284,461.053,241.795,459.984z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M353.488,465.104c-0.701-1.669-1.402-3.338-2.104-5.007
   c21.885,8.332,41.037-3.616,61.199-8.18c4.375-0.989,10.59-3.901,12.775,1.002c2.852,6.401-3.41,9.202-8.563,10.908
   c-13.697,4.537-27.516,8.668-42.121,9.014c-0.574,0.014-1.154-0.321-1.732-0.494c0.061-0.156,0.121-0.313,0.182-0.469
   c0.342-0.379,0.686-0.759,1.029-1.138c3.471-0.324,6.973-0.46,10.393-1.074c1.184-0.213,2.156-1.609,3.225-2.465
   c-0.396-0.383-0.85-1.142-1.178-1.09c-3.717,0.575-7.416,1.275-11.117,1.941C368.146,467.07,360.818,466.087,353.488,465.104z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M241.795,459.984c-0.511,1.068-1.021,2.136-1.532,3.202
   c-7.311,1.426-14.622,2.853-21.933,4.279c0-0.001-0.011,0.01-0.011,0.01c0.362,1.083,0.724,2.167,1.085,3.251
   c4.029,5.619,8.059,11.238,12.088,16.856c0.494,1.035,0.989,2.07,1.483,3.104c-3.875-4.028-7.75-8.058-11.624-12.087
   c-3.152-11.539-13.949-3.839-20.288-7.928c-3.342-2.155-8.117-2.049-12.199-3.136c-0.788-0.209-1.362-1.22-2.034-1.86
   c0.869-0.299,1.795-0.969,2.598-0.844C207.3,467.612,224.688,465.358,241.795,459.984z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#8B898A" d="M373.125,471.879c-0.061,0.155-0.121,0.313-0.182,0.469
   c-4.053,5.389-8.104,10.777-12.154,16.165c2.557-3.943,5.217-7.823,7.619-11.857c1.076-1.811,3.748-4.68,1.334-5.891
   c-4.193-2.102-9.166-3.018-13.516,0.563c-0.506-1.55-1.162-4.06,1.084-3.728C362.695,468.395,369.451,464.762,373.125,471.879z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#8B898A" d="M305.348,449.104c1.603,0.219,3.207,0.438,4.808,0.657
   C308.553,449.541,306.951,449.322,305.348,449.104z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#8B898A" d="M277.536,450.341c0.723-1.582,1.624-1.199,2.577-0.232
   C279.253,450.187,278.395,450.263,277.536,450.341z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#8B898A" d="M283.213,449.802c0.438-0.056,0.876-0.111,1.313-0.166
   C284.088,449.691,283.651,449.746,283.213,449.802z"/>
      <polygon fill-rule="evenodd" clip-rule="evenodd" fill="#8B898A" points="456.129,446.887 455.975,446.896 456.039,446.761 " />
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#815815" d="M97.74,270.064c0.112-0.449,0.223-0.897,0.335-1.345
   c0.942-1.091,1.884-2.183,2.826-3.276c23.933,13.091,50.857,17.312,76.365,25.732c17.874,5.9,35.744,11.815,53.688,17.496
   c3.364,1.065,7.056,1.285,10.618,1.48c4.955,0.271,10.387,0.771,12.479,5.51c2.744,6.218-1.163,11.409-5.194,16.059
   c-10.07,11.616-23.752,13.966-38.011,14.92c-2.43,0.163-4.838,0.65-7.256,0.986l-0.008-0.013c0.012-0.104-0.012-0.2-0.07-0.287
   c5.758-1.233,11.405-2.747,16.208-6.399c0.462-0.23,0.924-0.461,1.387-0.691c0.217-0.195,0.434-0.392,0.651-0.587
   c0.148-0.119,0.296-0.238,0.445-0.357c0.193-0.175,0.385-0.352,0.578-0.527l-0.065,0.047c0.181-0.182,0.363-0.365,0.544-0.548
   l-0.068,0.017c4.704-1.625,9.767-2.627,14.003-5.064c3.589-2.065,5.349-5.955-0.159-8.462c-4.668-2.126-9.569-3.743-14.367-5.585
   l0.093,0.03c-0.34-0.083-0.681-0.167-1.021-0.25c-4.569-2.026-9.138-4.052-13.708-6.079c-15.199-4.798-30.399-9.595-45.598-14.393
   c-1.905-1.162-3.81-2.324-5.714-3.485c2.793,0.535,5.592,1.049,8.379,1.616c1.356,0.275,2.678,0.818,4.042,0.936
   c1.762,0.152,3.55,0.001,5.326-0.02c-1.63-0.963-3.148-2.28-4.907-2.835c-19.91-6.275-39.548-13.789-60.925-13.554
   c-2.604,0.028-5.217-0.684-7.826-1.054c1.841,3.351-0.973,8.052,3.761,10.793c3.53,2.044,6.04,5.83,9.544,7.943
   c4.94,2.979,6.898,15.493,16.284,5.614c0.214,0.389,0.684,0.842,0.605,1.158c-2.462,9.896,0.703,18.143,7.352,25.389
   c1.958,7.269,9.57,8.456,14.013,13.049c0.645,0.667,1.111,1.506,1.66,2.266c-0.73,0.129-1.543,0.538-2.177,0.348
   c-12.412-3.714-27.372-6.738-29.497-21.289c-1.604-10.985-7.354-16.15-14.428-22.024c-3.943-3.274-6.49-1.918-7.573,2.295
   c-1.884,7.323-3.211,14.789-4.83,22.181c-0.153,0.699-0.72,1.306-1.095,1.956c-0.287-0.735-0.88-1.5-0.813-2.203
   c0.814-8.563,1.726-17.117,2.611-25.672c0.076-0.953,0.152-1.906,0.229-2.859c1.596-7.258,4.09-14.437,1.98-22.028
   C98.205,274.688,97.972,272.376,97.74,270.064z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#D6DBE0" d="M219.717,340.927c-4.803,3.653-10.45,5.166-16.207,6.399
   c-0.165,0.048-0.331,0.097-0.497,0.145c-0.633,0.084-1.266,0.169-1.898,0.253c-18.146,2.099-35.78,1.057-52.04-8.454
   c1.202-1.292,1.836-2.449,1.25-4.533c-4.77-16.978,1.98-26.74,16.881-24.2c12.474,2.126,25,5.487,35.399,12.424
   c5.748,3.834,17.388-0.738,17.714,11.805C220.379,336.989,219.025,338.844,219.717,340.927z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M138.356,330.98c-6.649-7.246-9.814-15.492-7.352-25.389
   c0.079-0.316-0.391-0.769-0.605-1.158c-9.385,9.878-11.344-2.636-16.284-5.614c-3.504-2.113-6.014-5.899-9.544-7.943
   c-4.734-2.741-1.919-7.442-3.761-10.793c2.609,0.37,5.222,1.082,7.826,1.054c21.376-0.235,41.015,7.278,60.925,13.554
   c1.758,0.555,3.277,1.872,4.907,2.835c-1.776,0.021-3.564,0.172-5.326,0.02c-1.364-0.118-2.686-0.661-4.042-0.936
   c-2.788-0.567-5.586-1.081-8.379-1.616c1.904,1.162,3.809,2.323,5.712,3.485c-8.907-1.006-19.774-8.61-22.758,8.264
   c-1.516,8.574-0.794,16.784,0.095,25.164C139.299,331.598,138.828,331.289,138.356,330.98z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#B5BBC5" d="M219.717,340.927c-0.692-2.083,0.662-3.938,0.604-6.16
   c-0.327-12.543-11.966-7.971-17.714-11.805c-10.399-6.937-22.926-10.297-35.399-12.424c-14.901-2.54-21.651,7.222-16.881,24.2
   c0.585,2.083-0.049,3.24-1.25,4.533c-1.748-1.179-3.495-2.357-5.244-3.536c-0.438-2.728-0.811-5.468-1.323-8.182
   c-4.373-23.161,4.979-32.166,26.758-25.811c12.905,3.767,25.843,7.423,38.766,11.128c4.569,2.026,9.138,4.052,13.708,6.079
   c0.34,0.083,0.68,0.166,1.02,0.25l-0.093-0.03c7.455,6.172,6.434,12.575,0.522,19.112c0,0,0.068-0.017,0.067-0.017
   c-0.181,0.182-0.363,0.365-0.544,0.548c0,0,0.065-0.047,0.065-0.048c-0.193,0.175-0.386,0.352-0.578,0.527
   c-0.148,0.119-0.296,0.238-0.445,0.357c-0.217,0.196-0.435,0.392-0.651,0.587C220.642,340.466,220.179,340.697,219.717,340.927z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#E8C371" d="M81.197,332.896c2.135-8.814,4.27-17.628,6.404-26.439
   c-0.047,3.603-0.163,7.204-0.11,10.803c0.025,1.709,0.371,3.414,0.572,5.121c0.621-1.312,1.324-2.592,1.845-3.942
   c0.98-2.542,1.855-5.125,2.774-7.69c0.267,0.359,0.534,0.718,0.801,1.077c-0.029,0.262-0.058,0.524-0.086,0.787
   c-0.575,3.036-1.151,6.071-1.727,9.106c-0.03,0.587-0.06,1.174-0.089,1.762c-0.235,0.42-0.47,0.841-0.705,1.26
   c-0.024,0.746-0.048,1.491-0.073,2.237c-0.024,0.343-0.047,0.687-0.07,1.029c-0.147,1.131-0.295,2.263-0.442,3.394
   c-0.025,0.339-0.049,0.679-0.072,1.019c-0.021,0.321-0.043,0.643-0.064,0.964c-0.141,1.148-0.282,2.296-0.423,3.445
   c-0.03,0.649-0.06,1.299-0.09,1.949c-0.563,3.808-1.127,7.615-1.69,11.421c-0.005,0.279-0.01,0.558-0.015,0.836
   c-0.216,0.602-0.454,1.197-0.644,1.808c-1.092,3.501,0.084,10.171-3.643,9.728c-3.917-0.467-2.185-6.904-2.375-10.807
   c-0.252-5.185-0.278-10.38-0.402-15.572c-0.008-0.598-0.016-1.195-0.024-1.792C80.965,333.897,81.081,333.396,81.197,332.896z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#8B898A" d="M208.033,312.871c-12.923-3.706-25.861-7.362-38.766-11.128
   c-21.779-6.355-31.131,2.649-26.758,25.811c0.512,2.714,0.885,5.454,1.323,8.182c-1.193-1.023-2.388-2.046-3.582-3.069
   c-0.173-0.265-0.345-0.529-0.518-0.793l0.038,0.034c-0.89-8.38-1.611-16.59-0.095-25.164c2.984-16.875,13.852-9.27,22.758-8.264
   C177.634,303.276,192.833,308.073,208.033,312.871z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#815815" d="M87.623,389.07c1.929-2.606,3.343-4.517,4.756-6.427
   c2.164,4.079,4.348,8.147,6.434,12.265c0.111,0.22-0.887,1.27-0.912,1.253C94.625,393.964,91.392,391.702,87.623,389.07z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#E8C371" d="M98.437,277c2.11,7.591-0.384,14.77-1.98,22.028
   c-0.323,1-0.646,2.002-0.969,3.004c-0.032,0.229-0.065,0.458-0.097,0.688c-0.458,0.271-0.916,0.54-1.374,0.809
   c1.321-6.673,2.665-13.342,3.927-20.026c0.119-0.63-0.277-1.358-0.434-2.04c-0.404,0.504-0.948,0.95-1.188,1.522
   c-2.409,5.741-4.767,11.503-7.14,17.258c2.539-7.876,5.004-15.777,7.667-23.611C97.521,274.656,97.96,276.573,98.437,277z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#D8A730" d="M116.674,324.025c-1.754,0.059-4.488,0.854-5.091,0.05
   c-2.482-3.309-3.725-7.186-1.76-11.36C119.116,312.238,119.413,317.212,116.674,324.025z M109.836,315.276
   c1.573,3.491,0.554,6.263,3.779,6.322c2.346,0.042,2.45-1.676,2.586-3.37c0.221-2.748-1.893-3.251-3.776-3.585
   C111.415,314.465,110.249,315.162,109.836,315.276z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#8B898A" d="M94.017,303.53c0.458-0.269,0.917-0.539,1.374-0.809
   c-0.636,3.034-1.271,6.068-1.907,9.103c-0.268-0.358-0.535-0.717-0.802-1.07C93.126,308.35,93.572,305.939,94.017,303.53z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#8B898A" d="M87.951,350.198c0.563-3.807,1.127-7.614,1.69-11.421
   C89.078,342.583,88.514,346.391,87.951,350.198z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#8B898A" d="M91.67,321.717c0.576-3.036,1.151-6.071,1.727-9.106
   C92.821,315.646,92.246,318.681,91.67,321.717z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#8B898A" d="M89.731,336.827c0.141-1.148,0.282-2.296,0.423-3.445
   C90.013,334.531,89.872,335.679,89.731,336.827z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#8B898A" d="M95.487,302.032c0.323-1.002,0.646-2.003,0.969-3.004
   c-0.077,0.953-0.153,1.906-0.229,2.859C95.981,301.935,95.734,301.984,95.487,302.032z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#815815" d="M201.116,347.725c0.632-0.084,1.265-0.168,1.897-0.252
   c0.188,0.047,0.377,0.094,0.566,0.141l0.008,0.013C202.763,347.659,201.939,347.692,201.116,347.725z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#8B898A" d="M90.733,328.006c0.023-0.343,0.046-0.686,0.07-1.029
   C90.78,327.319,90.756,327.663,90.733,328.006z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#8B898A" d="M90.876,324.739c0.235-0.419,0.47-0.84,0.705-1.26
   C91.346,323.898,91.111,324.319,90.876,324.739z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#8B898A" d="M90.218,332.418c0.023-0.339,0.047-0.679,0.072-1.019
   C90.267,331.739,90.242,332.079,90.218,332.418z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M139.732,331.872c0.172,0.265,0.345,0.529,0.518,0.793
   C140.077,332.401,139.905,332.137,139.732,331.872z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M483.34,447.22c-4.57-0.616-9.582,1.66-13.604-2.004
   c-0.303-0.276,0.543-2.562,1.393-3.238c6.455-5.144,13.346-8.877,22.002-5.646c1.404,0.523,3.303,0.363,4.283,1.267
   c2.492,2.295,8.861,2.224,7.188,6.706c-1.74,4.66-7.734,1.692-11.736,2.763C489.889,447.864,486.529,447.225,483.34,447.22z
    M480.287,446.412c5.045-0.771,12.1,0.212,15.146-4.729c1.068-1.73-6.557-4.874-11.178-4.521c-4.543,0.349-9.803,1.681-10.871,6.27
   C472.477,447.334,477.189,445.917,480.287,446.412z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#815815" d="M498.236,427.778c-0.795-0.004-1.59-0.008-2.387-0.012
   c-0.678,0.1-1.357,0.201-2.037,0.302c-2.426-0.009-4.852-0.018-7.279-0.025c-2.896-5.45,1.268-7.739,4.854-9.516
   c6.092-3.02,11.725-0.204,13.691,5.16C506.779,428.325,500.191,425.598,498.236,427.778z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#815815" d="M506.6,409.931c-0.611-0.053-1.223-0.106-1.834-0.158
   c-0.684-0.123-1.367-0.247-2.049-0.37c-1.326-0.139-2.652-0.277-3.979-0.415c-0.9-0.748-2.682-1.689-2.566-2.214
   c0.947-4.319,3.967-7.426,8.162-7.605c5.029-0.217,7.6,4.308,8.139,8.327C512.982,411.306,508.35,408.358,506.6,409.931z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M464.883,463.278c-1.512-0.319-4.252-0.88-6.959-1.567
   c-0.07-0.019-0.068-1.765,0.379-2.145c4.678-3.985,10.09-6.265,16.252-5.099c3.951,0.748,10.602,0.094,10.529,4.748
   c-0.059,3.739-6.781,2.586-10.582,3.356C471.807,463.119,468.979,463.008,464.883,463.278z M466.234,461.604
   c3.766-0.227,8.438,0.467,10.068-3.071c0.805-1.745-3.824-2.956-6.137-2.669c-3.271,0.407-7.977,0.155-8.6,4.371
   C461.156,463.02,464.717,460.865,466.234,461.604z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M498.236,427.778c1.955-2.181,8.543,0.547,6.842-4.091
   c-1.967-5.364-7.6-8.18-13.691-5.16c-3.586,1.776-7.75,4.065-4.854,9.516c-1.221-0.607-2.834-0.943-3.51-1.926
   c-0.383-0.556,0.529-2.374,1.275-3.276c6.658-8.042,14.279-9.313,22.443-2.45c1.859,1.563,5.801,3.872,3.883,6.325
   C507.484,430.731,502.523,428.632,498.236,427.778z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M506.6,409.931c1.75-1.572,6.383,1.375,5.873-2.436
   c-0.539-4.02-3.109-8.544-8.139-8.327c-4.195,0.18-7.215,3.286-8.162,7.605c-0.115,0.524,1.666,1.466,2.566,2.214
   c-1.771-0.418-4.846-0.465-5.09-1.317c-1-3.518,2.432-5.167,4.406-7.19c2.508-2.573,5.764-5.026,9.52-2.799
   c4.443,2.636,6.979,7.044,8.088,12.023C516.381,412.928,513.984,413.051,506.6,409.931z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M454.979,476.805c-1.732-0.831-6.037,2.67-6.201-1.211
   c-0.15-3.57,4.578-4.227,7.77-3.941c2.174,0.194,4.217,1.873,6.318,2.889C460.857,476.686,458.303,477.111,454.979,476.805z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M509.359,385.842c4.229,0.481,4.527,3.542,5.689,5.807
   c0.053,0.102-1.139,1.234-1.594,1.14c-2.711-0.566-5.971-0.768-6.398-4.33C506.951,387.582,508.771,386.473,509.359,385.842z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M502.717,409.402c0.682,0.123,1.365,0.247,2.049,0.37
   C504.082,409.648,503.398,409.524,502.717,409.402z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M493.813,428.068c0.68-0.101,1.359-0.202,2.037-0.302
   C495.172,427.867,494.492,427.968,493.813,428.068z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#8B898A" d="M493.883,399.728c0.166-0.163,0.332-0.327,0.498-0.49
   C494.215,399.401,494.049,399.564,493.883,399.728z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#B5BBC5" d="M126.74,427.097c-10.085-2.503-20.181-9.356-26.806-21.094
   c-0.194-0.343-0.001-0.904,0.011-1.364c0.761,0.06,1.667-0.131,2.261,0.215c10.563,6.155,21.774,10.648,33.607,13.737
   c2.322,0.606,4.307,2.504,6.448,3.806c-2.846,1.566-5.624,3.285-8.574,4.621C132.602,427.509,131.107,427.097,126.74,427.097z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#E8C371" d="M236.252,392.329c-12.47,7.941-24.14,17.214-38.309,22.29
   c-8.632,3.092-17.23,6.068-26.567,5.804c-0.422-0.298-0.847-0.597-1.271-0.896c0.627-0.437,1.254-0.873,1.878-1.307
   c2.535-0.152,5.072-0.306,7.609-0.459c0.291-0.103,0.582-0.206,0.874-0.309c0.521-0.06,1.044-0.117,1.565-0.176
   c19.21-4.008,35.981-12.795,50.586-25.826c1.028-0.18,2.057-0.359,3.086-0.539C235.886,391.384,236.069,391.856,236.252,392.329z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M71.953,305.381c-0.023,0.067-0.045,0.133-0.068,0.201
   c0.024-0.065,0.048-0.13,0.073-0.196L71.953,305.381z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M71.958,305.386l-0.005-0.005L71.958,305.386z" />
      <polygon fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" points="72.465,302.954 72.36,303.012 72.416,302.906 " />
      <polygon fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" points="73.833,300.542 73.791,300.362 73.968,300.409 " />
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#D8A730" d="M393.572,208.109c2.988-1.51,4.828,0.845,6.865,2.176
   c13.031,8.515,21.143,24.33,38.816,25.983c7.26,0.679,15.52-0.246,20.996,5.113c10.811,10.577-2.285,14.578-7.34,17.646
   c-25.705,15.604-53.76,26.42-82.039,36.36c-8.568,3.012-17.518,8.119-27.482-0.308c-3.451-2.917-7.527,5.539-8.678,9.946
   c-6.193,23.733,1.604,43.755,18.092,60.742c6.258,6.447,13.967,6.255,20.979,0.713c10.396-8.219,36.641-8.722,51.309,0.781
   c10.906,7.065,2.928,16.097-0.744,23.193c-4.973,9.612-13.258,16.547-23.443,20.947c-2.852,1.232,0.207,2.317,0.572,3.467
   c-15.537-4.68-28.98-13.026-41.197-23.521c2.061-4.963,0.072-9.166-2.494-13.259c-0.584-1.379-1.168-2.758-1.752-4.137
   c-3.369-5.473-8.723-8.266-14.375-10.657l-0.025,0.053c-0.158-0.19-0.318-0.38-0.477-0.57c-1.539-0.813-3.076-1.625-4.615-2.437
   c-0.641-0.611-1.238-1.28-1.932-1.824c-7.754-6.08-15.738-14.423-15.018-23.256c1.314-16.073,9.719-30.903,13.068-47.007
   c2.398-11.533,8.125-22.373,12.365-33.522C366.424,244.624,382.014,228.465,393.572,208.109z M360.348,295.391
   c17.461-4.075,31.539-13.385,43.752-25.83c0.748-0.762,0.688-2.318,1.004-3.503c-1.209-0.194-2.561-0.843-3.602-0.514
   c-15.717,4.966-28.348,15.108-41.184,24.868c-1.033,0.786-1.627,2.211-2.203,3.444C358.055,293.985,359.508,294.821,360.348,295.391
   z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#D8A730" d="M240.446,369.147c8.947-10.53,16.744-21.837,19.509-35.594
   c2.323-11.558,1.757-23.081-5.334-33.256c-0.816-1.171-3.255-2.4-4.364-2.001c-19.261,6.941-34.831-3.528-50.744-11.032
   c-18.445-8.698-38.375-14.648-54.314-28.218c-4.829-4.111-9.035-10.144-6.981-15.397c3-7.673,12.224-6.427,18.926-6.74
   c11.188-0.522,16.61-9.37,24.777-14.205c6.343-3.755,10.33-12.542,19.723-10.656l-0.011-0.017c0.173,0.348,0.347,0.696,0.52,1.043
   c12.41,16.08,24.969,31.998,44.197,40.667c-3.117,0.044-3.064,1.712-1.913,3.842c1.433,2.653,2.818,5.341,4.426,7.888
   c8.897,14.094,9.732,31.047,16.028,45.837c7.195,16.902,10.848,31.999-3.335,46.667l0.018-0.014c-0.828,0.33-1.657,0.66-2.485,0.99
   l0.03-0.018c-0.501,0.172-1.003,0.344-1.504,0.515l0.035-0.02c-0.34,0.213-0.681,0.427-1.021,0.641
   C251.234,363.097,245.84,366.123,240.446,369.147z M233.092,296c1.524-0.488,2.559-0.82,3.595-1.152
   c-0.401-0.929-0.55-2.153-1.241-2.745c-12.375-10.606-25.536-19.962-41.188-25.205c-0.697-0.233-1.861,0.06-2.411,0.551
   c-0.373,0.333-0.368,1.691,0.006,2.171C202.549,283.354,216.846,291.349,233.092,296z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#D6DBE0" d="M393.572,208.109c-11.559,20.356-27.148,36.515-48.549,46.622
   c-16.248,1.984-20.631-10.009-25.393-21.555c-5.025-21.204,6.133-34.696,21.912-45.987c0.582-0.371,1.164-0.741,1.746-1.111
   c0.762-0.44,1.521-0.881,2.281-1.321c19.191-8.907,32.412-6.136,45.248,9.523C394.18,198.38,394.662,203.1,393.572,208.109z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#D6DBE0" d="M246.35,253.743c-19.228-8.67-31.787-24.587-44.197-40.667
   c-0.173-0.348-0.346-0.695-0.519-1.043l0.011,0.017c-5.181-10.467-0.228-17.63,7.58-24.121c12.047-10.014,24.772-10.582,38.26-3.362
   c0.706,0.378,1.441,0.7,2.163,1.049c26.372,19.878,30.369,31.239,20.193,57.396l0.046-0.041c-0.317,0.486-0.635,0.972-0.952,1.458
   C263.477,252.553,256.592,257.219,246.35,253.743z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#D8A730" d="M232.617,391.451c-14.605,13.03-31.376,21.817-50.586,25.825
   c10.146-5.543-0.515-5.617-2.154-6.639c-17.588-10.967-31.727-24.897-38.822-44.778c-2.331-6.53,3.983-6.601,7.209-6.228
   c20.386,2.352,40.641,0.896,60.919-1.024c6.201-0.587,5.304,4.877,7.664,7.971c5.565,7.297,14.232,8.964,22.804,3.166
   c-0.238,0.449-0.475,0.897-0.712,1.347c-1.195,2.34-2.391,4.68-3.586,7.02C233.565,382.378,229.292,386.135,232.617,391.451z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#D8A730" d="M269.841,243.011c10.175-26.157,6.178-37.518-20.193-57.396
   c7.502-7.268,17.557-5.261,26.493-7.046c22.632-4.522,45.061,0.076,67.521,2.405c2.775,0.288,2.053,2.131,1.908,3.783
   c-0.76,0.441-1.52,0.881-2.281,1.322c-0.582,0.37-1.164,0.74-1.746,1.111c-15.779,11.291-26.938,24.783-21.912,45.988
   c-1.201-1.176-2.549-2.236-3.578-3.545c-7.242-9.203-22.111-11.181-29.568-3.753C280.845,231.497,275.383,237.294,269.841,243.011z"
      />
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#815815" d="M180.795,167.179c1.088-0.87,2.177-1.74,3.265-2.61
   c18.834-14.505,39.749-25.037,62.23-32.572c-8.297,4.532-16.662,8.944-24.878,13.618c-23.68,13.472-44.223,30.849-62.324,51.093
   c-10.158,11.361-19.84,23.149-29.634,34.831c-1.323,1.577-2.046,3.656-3.047,5.502c-6.494,4.469-12.988,8.938-19.481,13.408
   c-0.011-0.335-0.022-0.67-0.033-1.005c-0.251-0.325-0.502-0.65-0.752-0.975c1.212-1.135,2.425-2.27,3.636-3.404
   c1.128-1.622,2.256-3.242,3.385-4.863c0.01-0.225,0.02-0.451,0.029-0.675c0,0,0.021,0.027,0.021,0.027
   c0.243-0.164,0.487-0.328,0.73-0.493c0.24-0.409,0.479-0.818,0.719-1.228c0.258-0.278,0.516-0.556,0.774-0.833
   c0.133-0.861,0.265-1.722,0.398-2.583c0.766-0.827,1.533-1.653,2.298-2.479c-0.167-0.488-0.334-0.978-0.501-1.467
   c0.113-0.042,0.218-0.101,0.309-0.174c0.197-0.018,0.399-0.035,0.602-0.053c0.177,0.023,0.353,0.046,0.529,0.069
   c0.298-0.052,0.595-0.104,0.893-0.156c0.369-0.427,0.739-0.854,1.108-1.281c0.166-0.367,0.333-0.734,0.499-1.102l-0.013,0.003
   c1.971-2.537,3.941-5.074,5.911-7.611c-0.014-0.255-0.027-0.51-0.041-0.766l0.012,0.001c0.174-0.159,0.348-0.317,0.522-0.477
   l-0.03,0.014c0.179,0.04,0.358,0.08,0.538,0.119c0.324-0.452,0.648-0.903,0.973-1.355c0.451-0.35,0.902-0.7,1.353-1.049
   c0.655-0.9,1.31-1.8,1.965-2.7c0.941-1.109,1.882-2.217,2.824-3.326c0.119-0.098,0.187-0.225,0.205-0.378
   c2.125-2.359,4.203-4.764,6.386-7.069c6.212-6.56,12.468-13.079,18.706-19.613l-0.03,0.029c0.346-0.419,0.693-0.838,1.039-1.257
   c4.898-4.332,9.797-8.664,14.696-12.995C177.987,168.623,179.391,167.9,180.795,167.179z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#E8C371" d="M334.246,137.265c7.326-1.282,13.635-6.666,21.602-5.355
   c-9.779,2.961-5.504,6.316-0.082,9.187c4.926,2.608,10.057,4.898,15.285,6.815c1.197,0.439,3.65-0.845,4.619-2.059
   c0.713-0.895-0.049-2.966-0.154-4.514c-0.082-0.779-0.164-1.559-0.246-2.338c0.299,0.056,0.596,0.111,0.895,0.168
   c0.723,1.806,1.441,3.611,2.162,5.414c0.002,1.263,0.004,2.526,0.006,3.789c-2.383,4.673-6.168,4.732-10.484,3.246
   c-5.938-2.045-11.914-3.972-17.84-6.053C344.355,143.578,337.176,144.438,334.246,137.265z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#D8A730" d="M334.246,137.265c2.93,7.173,10.109,6.313,15.762,8.299
   c5.926,2.082,11.902,4.008,17.84,6.053c4.316,1.487,8.102,1.428,10.484-3.246c-1.184,8.111-6.754,6.889-11.652,4.804
   c-11.691-4.973-25.219-4.171-36.037-11.652c-1.727-1.195-3.193-2.766-4.781-4.164c-0.014-0.413-0.027-0.825-0.043-1.236
   C328.707,135.918,332.035,132.461,334.246,137.265z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#815815" d="M238.939,371.091c0.238-0.449,0.475-0.898,0.712-1.347
   c0.265-0.199,0.53-0.398,0.795-0.597c5.394-3.025,10.788-6.05,16.182-9.075c0.34-0.214,0.681-0.427,1.021-0.641l-0.035,0.02
   c0.501-0.171,1.003-0.343,1.504-0.515l-0.03,0.018c0.828-0.33,1.657-0.66,2.485-0.99l-0.018,0.014
   c1.069-0.326,2.137-0.652,3.206-0.979c0.249-0.173,0.497-0.347,0.745-0.52l-0.076,0.111c0,0,0.111,0.076,0.111,0.075
   c0.938-0.254,1.876-0.507,2.814-0.761c0.71-0.177,1.42-0.354,2.131-0.532c0.452-0.006,0.904-0.012,1.356-0.019
   c0.185-0.007,0.369-0.015,0.554-0.023c4.042,2.407,8.506-2.472,12.501,0.726c-0.673,0.219-1.337,0.593-2.02,0.632
   c-6,0.339-11.652,1.847-16.879,4.854c-0.5-0.01-1-0.021-1.5-0.031c-0.471,0.117-0.943,0.234-1.414,0.352
   c-5.601,2.522-12.118,2.407-17.256,6.668C244.318,369.786,242.178,372.971,238.939,371.091z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#E8C371" d="M360.277,391.348c12.217,10.495,25.66,18.842,41.197,23.521
   c7.215,3.421,14.879,4.299,22.744,4.021c-1.404,2.115-2.916,2.092-18.738-0.281c-16.914-5.311-31.99-13.99-45.863-24.915
   c-0.811-0.639-2.121-0.645-3.201-0.944c-0.316-0.672-0.633-1.344-0.947-2.016c0.309-0.531,0.619-1.063,0.93-1.596
   C357.691,389.875,358.984,390.611,360.277,391.348z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#D8A730" d="M476.193,324.668c-4.139-6.799-3.875-11.78,5.385-13.049
   c6.193,2.461,2.986,7.145,2.088,10.744C482.695,326.241,479.594,326.797,476.193,324.668z M483.176,318.363
   c-0.096-1.723-0.037-3.718-2.211-3.48c-2.455,0.269-4.475,1.974-4.133,4.55c0.178,1.325,2.08,3.373,3.268,3.419
   C482.863,322.958,482.906,320.311,483.176,318.363z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M96.944,265.298c-6.939,3.728,0.952-4.668-2.859-3.498
   c1.047,0.455,2.094,0.909,3.142,1.364C97.132,263.876,97.038,264.587,96.944,265.298z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#D8A730" d="M180.465,417.452c-0.292,0.103-0.583,0.206-0.874,0.309
   C179.883,417.657,180.174,417.555,180.465,417.452z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M452.01,333.431c2.77-11.432,2.885-22.633-2.184-33.622
   c-1.141-2.474-3.141-2.767-5.016-3.708c3.408-0.906,5.344,0.254,6.664,3.691c4.043,10.535,4.094,21.241,2.125,32.124l0.016-0.019
   c-0.186,0.241-0.369,0.482-0.555,0.724C452.709,332.891,452.359,333.161,452.01,333.431z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#815815" d="M397.156,348.712c1.607-0.002,3.215-0.004,4.824-0.006
   C400.371,348.708,398.764,348.71,397.156,348.712z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M453.061,332.621c0.186-0.241,0.369-0.482,0.555-0.724
   C453.43,332.139,453.246,332.38,453.061,332.621z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#8B898A" d="M421.117,125.985c-0.002-0.093,0-0.186,0.004-0.279
   c0.672-1.869,1.346-3.737,2.018-5.606c8.025-19.237,27.654-30.157,40.504-21.965c10.703,6.824,17.916,17.3,23.916,28.116
   c14.896,26.857,20.805,55.598,15.6,86.237c-0.641,3.774-0.855,7.621-1.266,11.43c-1.516,4.136-3.045,8.272-4.545,12.418
   c-0.756,2.089,0.305,5.834-2.646,5.786c-1.902-0.032-4.41-2.706-5.527-4.794c-12.775-23.886-25.736-47.655-45.445-66.763
   c-4.414-4.28-9.453-8.264-14.879-11.086c-12.984-6.753-14.848-16.172-8.527-28.461C421.061,129.583,420.875,127.674,421.117,125.985
   z M489.695,224.523c0.598,4.909,0.896,7.831,1.352,10.728c0.094,0.594,0.768,1.535,1.184,1.54c0.66,0.008,1.625-0.468,1.947-1.033
   c7.117-12.536,9.354-26.15,8.691-40.367c-0.033-0.723-0.592-1.422-0.906-2.133c-0.373,0.262-1.014,0.47-1.08,0.795
   C498.582,205.307,480.84,211.429,489.695,224.523z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#C59426" d="M508.492,260.675c-2.486-4.545-4.99-9.08-7.42-13.653
   c-0.213-0.398,0-1.082,0.172-1.579c0.041-0.118,0.869-0.182,1.076,0.01c9.846,9.123,11.576-1.003,15.334-6.413
   c1.199-1.725,2.586-3.318,3.887-4.972c2.199,1.934,1.441,4.278,0.836,6.543c-1.268,4.74-2.611,9.458-3.924,14.186
   c0.215-1.564,0.617-3.131,0.592-4.691c-0.02-1.378-0.525-4.184-0.609-3.96C516.305,251.854,505.072,251.45,508.492,260.675z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#D8A730" d="M508.492,260.675c-3.42-9.225,7.813-8.821,9.943-14.529
   c0.084-0.224,0.59,2.582,0.609,3.96c0.025,1.56-0.377,3.127-0.592,4.691c-1.197,2.3-2.393,4.6-3.588,6.9
   c-1.748,0.351-3.498,0.702-5.248,1.053C509.242,262.058,508.867,261.366,508.492,260.675z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#C59426" d="M509.617,262.75c1.75-0.351,3.5-0.702,5.248-1.053
   c-1.145,1.074-2.287,2.147-3.43,3.221C510.828,264.195,510.223,263.472,509.617,262.75z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#C59426" d="M378.045,139.832c-0.15-0.224-0.301-0.448-0.449-0.669
   C377.746,139.388,377.896,139.61,378.045,139.832z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#C59426" d="M393.232,134.25c2.455-3.628,4.881-7.21,7.307-10.792
   c0.16,0.286,0.533,0.674,0.451,0.842c-2.004,4.127-1.75,9.384-6.172,12.251C394.803,136.562,394.043,135.43,393.232,134.25z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#AC7825" d="M423.139,120.1c-0.672,1.869-1.346,3.737-2.018,5.606
   C421.793,123.838,422.467,121.969,423.139,120.1z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#D6DBE0" d="M375.27,139.001c0.082,0.779,0.164,1.559,0.246,2.338
   c-0.252,3.455-1.699,5.893-5.545,4.78c-5.758-1.667-12.209-3.9-13.297-10.214c-0.496-2.88,5.225-2.1,8.211-1.772
   C368.811,134.563,372.855,135.161,375.27,139.001z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#815815" d="M246.331,131.98c0.335-0.128,0.669-0.257,1.004-0.385
   C247,131.723,246.666,131.852,246.331,131.98z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M191.232,154.946c0.512-2.557,1.023-5.114,1.535-7.671
   c3.038-1.068,6.561-1.54,8.915-3.451c1.238-1.004,1.153-3.99,3.751-4.527c1.107,0.118,2.215,0.235,3.323,0.353
   c-4.403,0.273,1.276,5.806-3.162,6.061l-0.104,0.135c-2.101,0.884-3.647,2.164-3.382,4.736
   C198.483,152.037,194.857,153.491,191.232,154.946z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M203.69,148.787c0.109,0.296,0.217,0.592,0.326,0.888
   c-0.498,0.259-0.995,0.519-1.492,0.777c0.316-0.627,0.632-1.254,0.948-1.881C203.544,148.644,203.617,148.715,203.69,148.787z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M160.879,183.567c-6.238,6.535-12.494,13.054-18.706,19.613
   c-2.183,2.305-4.26,4.71-6.386,7.068c-0.292,0.125-0.583,0.25-0.874,0.375c0.223,0.001,0.446,0.003,0.669,0.004
   c-0.941,1.108-1.883,2.217-2.823,3.326c-0.105-0.115-0.214-0.228-0.325-0.338c1.229-3.991-2.671,0.665-2.527-1.499
   c0.824-0.983,1.648-1.967,2.472-2.951c0.312,0.343,0.624,0.687,0.935,1.03c0.135-0.158,0.27-0.317,0.404-0.475
   c0.09-0.089,0.18-0.178,0.269-0.268c0.516-0.411,1.033-0.822,1.549-1.233c-1.841-1.652-3.683-3.304-5.524-4.956
   c7.642-8.386,15.255-16.797,22.982-25.104c0.608-0.654,2.007-0.571,3.04-0.83C158.304,178.901,155.749,184.22,160.879,183.567z
    M136.168,204.382c-0.315,1.049-0.527,1.365-0.452,1.581c0.199,0.573,0.522,1.104,0.795,1.651c0.137-0.37,0.401-0.75,0.377-1.109
   C136.858,206.04,136.597,205.589,136.168,204.382z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M130.011,203.266c1.841,1.651,3.683,3.304,5.524,4.956
   c-0.516,0.411-1.033,0.822-1.549,1.233c-0.129-0.04-0.257-0.08-0.386-0.12c0.039,0.129,0.078,0.258,0.117,0.387
   c-0.134,0.158-0.269,0.317-0.404,0.475c-0.312-0.343-0.623-0.687-0.935-1.03c-0.824,0.984-1.648,1.967-2.472,2.951
   c-1.292,2.573,1.821,0.806,1.95,1.979c-0.354,0.853-0.708,1.705-1.063,2.558c-0.451,0.35-0.902,0.699-1.353,1.049
   c-0.499-0.07-0.997-0.139-1.496-0.208c0.002-0.329,0.003-0.658,0.005-0.987c-0.653-0.244-1.306-0.487-1.959-0.731
   c-1.038-1.771-3.506,3.773-4-0.782C126.538,212.366,127.714,207.433,130.011,203.266z M131.355,205.707
   c0.091,0.089,0.183,0.178,0.274,0.267c-0.079-0.1-0.158-0.199-0.237-0.297c-0.109-0.183-0.219-0.365-0.329-0.547
   c-0.076,0.077-0.152,0.154-0.228,0.23C131.009,205.476,131.182,205.592,131.355,205.707z M128.334,212.747
   c-0.235-0.264-0.431-0.482-0.625-0.7c-0.075,0.189-0.248,0.41-0.2,0.559c0.08,0.25,0.302,0.455,0.464,0.678
   C128.083,213.121,128.192,212.958,128.334,212.747z M133.469,206.644c-0.047-0.043-0.095-0.085-0.142-0.127
   c-0.04,0.091-0.08,0.183-0.12,0.274c0.063,0.011,0.142,0.05,0.184,0.025C133.436,206.789,133.445,206.703,133.469,206.644z
    M130.823,214.313c-0.146,0.03-0.225,0.047-0.304,0.064c0.039,0.063,0.077,0.125,0.115,0.188
   C130.679,214.505,130.724,214.445,130.823,214.313z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M121.991,214.995c0.493,4.555,2.962-0.989,4,0.783
   c-0.025,0.249-0.051,0.498-0.077,0.747c-0.318,0.776-1.111,1.876-0.85,2.26c0.468,0.688,1.568,0.945,2.404,1.382
   c-1.97,2.537-3.94,5.075-5.911,7.611c-1.14-1.4-2.281-2.515-3.423-0.001l-0.021,0.022c-0.32-0.002-0.639-0.004-0.958-0.006
   l-0.023-0.022c0.022-0.176,0.045-0.352,0.067-0.529c-0.2-0.144-0.4-0.288-0.601-0.433c-0.308,0.527-0.615,1.053-0.923,1.58
   c-0.11-0.583-0.359-1.663-0.31-1.677c2.26-0.635,0.099-5.233,3.933-4.409c0.321,0.069,0.712-0.186,1.07-0.29
   c-0.245-0.682-0.489-1.363-0.734-2.045l-0.136-0.133C120.33,218.221,121.16,216.607,121.991,214.995z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M113.162,240.2c-1.128,1.621-2.257,3.242-3.385,4.863
   c-6.521,1.687-3.289-2.772-3.044-5.047c0.248-2.305,0.721-0.244,1.085,0.061c-0.17,0.813-0.341,1.627-0.511,2.441
   c-0.033,0.229-0.094,0.458-0.092,0.686c0.002,0.231,0.065,0.463,0.102,0.695c0.002-0.472,0.004-0.944,0.006-1.416
   c0.319-0.607,0.638-1.214,0.958-1.821c0.167-0.052,0.333-0.104,0.499-0.156c0.175-0.005,0.35-0.011,0.525-0.017
   C110.59,240.394,111.875,240.297,113.162,240.2z M109.246,242.503c0.377-0.143,0.596-0.226,0.815-0.309
   c-0.22-0.2-0.412-0.478-0.671-0.571c-0.158-0.057-0.418,0.172-0.632,0.272C108.923,242.1,109.087,242.305,109.246,242.503z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M114.661,237.832c-0.24,0.409-0.479,0.818-0.719,1.228
   c-0.243,0.165-0.487,0.329-0.73,0.493c0,0-0.021-0.027-0.021-0.027c-1.482-0.589-2.965-1.178-4.447-1.767
   c0.717-0.941,1.298-2.07,2.216-2.736c0.394-0.285,1.485,0.393,2.26,0.633l-0.023-0.032c0.301,0.291,0.601,0.582,0.902,0.873
   c0.147,0.128,0.294,0.258,0.442,0.386C114.58,237.199,114.62,237.516,114.661,237.832z M111.424,236.31
   c-0.059,0.018-0.118,0.036-0.177,0.053c0.018,0.055,0.025,0.146,0.056,0.155c0.049,0.015,0.115-0.026,0.173-0.043
   C111.459,236.42,111.441,236.365,111.424,236.31z M111.933,237.303l0.1,0.083l0.024-0.121L111.933,237.303z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M129.323,191.855c0.359-0.47,0.718-0.939,1.078-1.408
   c1.255-0.044,2.509-0.087,3.765-0.131c0.128,0.107,0.257,0.214,0.385,0.322c-0.975,1.938-2.464,2.814-4.649,2.253l0.029,0.03
   c-0.136-0.138-0.272-0.274-0.409-0.412c-0.109-0.108-0.219-0.217-0.329-0.326C129.255,192.082,129.298,191.972,129.323,191.855z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M115.833,234.415c-0.133,0.861-0.266,1.722-0.398,2.585
   c-0.298-0.038-0.597-0.077-0.895-0.117c-0.147-0.128-0.295-0.258-0.442-0.386c-0.301-0.291-0.602-0.582-0.902-0.873l0.023,0.032
   C112.582,232.064,114.212,233.25,115.833,234.415z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M134.32,187.033c0.804,0.136,1.608,0.271,2.412,0.406
   c0.08,0.081,0.161,0.161,0.242,0.241c-0.808,0.985-1.616,1.972-2.424,2.958c-0.128-0.107-0.256-0.214-0.385-0.322
   c0.061-1.104,0.122-2.207,0.183-3.311L134.32,187.033z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#8B898A" d="M117.63,230.469c0.167,0.489,0.334,0.979,0.501,1.467
   c-2.118,0.348-2.113-0.693-1.427-2.181C117.013,229.993,117.322,230.231,117.63,230.469z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M117.63,230.469c-0.309-0.238-0.618-0.476-0.926-0.714
   c-0.173-0.505-0.346-1.01-0.519-1.514c0.315-0.157,0.631-0.314,0.947-0.472l0.023,0.022c0.166,0.501,0.332,1.003,0.498,1.505
   c0.097,0.333,0.194,0.666,0.292,0.999C117.848,230.368,117.744,230.426,117.63,230.469z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M108.281,240.663c-0.319,0.606-0.639,1.214-0.958,1.821
   l-0.017,0.034c0.17-0.813,0.341-1.627,0.511-2.441C107.972,240.272,108.126,240.468,108.281,240.663z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#8B898A" d="M106.141,248.468c0.25,0.325,0.501,0.65,0.752,0.975
   c-0.575,0.092-1.151,0.183-1.727,0.274C105.491,249.301,105.816,248.884,106.141,248.468z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M127.124,199.716c-0.378-0.143-0.757-0.285-1.136-0.427
   c0.588-0.219,1.176-0.438,1.763-0.658C127.541,198.992,127.333,199.354,127.124,199.716z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M129.901,192.892c-0.1,0.299-0.198,0.598-0.298,0.896
   c-0.252-0.489-0.505-0.979-0.758-1.468c0.122-0.03,0.237-0.075,0.347-0.136c0.109,0.109,0.219,0.217,0.329,0.326
   c0.136,0.137,0.273,0.274,0.409,0.412L129.901,192.892z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M117.132,227.769c-0.316,0.158-0.632,0.315-0.947,0.472
   c-0.169,0.048-0.339,0.098-0.509,0.146c0.308-0.527,0.615-1.053,0.923-1.58c0.2,0.145,0.401,0.289,0.601,0.433
   C117.177,227.417,117.154,227.592,117.132,227.769z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M101.89,242.185c-0.045-0.04-0.089-0.079-0.134-0.118
   c0.085-0.048,0.17-0.096,0.255-0.144c0.016,0.06,0.059,0.133,0.039,0.176C102.029,242.143,101.946,242.158,101.89,242.185z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#8B898A" d="M131.733,193.971c-0.199,0.11-0.386,0.214-0.574,0.318
   c-0.059-0.136-0.205-0.339-0.161-0.396c0.121-0.156,0.321-0.252,0.489-0.371C131.558,193.65,131.627,193.777,131.733,193.971z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M134.349,187.006c-0.252-0.175-0.505-0.35-0.757-0.525
   c0.104-0.095,0.208-0.19,0.312-0.286c0.139,0.279,0.278,0.559,0.417,0.838L134.349,187.006z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M109.304,240.49c-0.175,0.006-0.35,0.012-0.525,0.017
   C108.955,240.501,109.129,240.496,109.304,240.49z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M130.265,188.325c0.249-0.027,0.498-0.055,0.747-0.082
   c-0.147,0.15-0.294,0.301-0.44,0.451C130.467,188.573,130.365,188.45,130.265,188.325z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M119.635,219.967c-0.052,0.052-0.1,0.111-0.161,0.15
   c-0.01,0.006-0.115-0.09-0.107-0.109c0.028-0.065,0.086-0.117,0.133-0.174C119.5,219.833,119.635,219.967,119.635,219.967z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M129.192,192.185c-0.11,0.061-0.225,0.106-0.347,0.136
   c-0.026-0.143,0.006-0.27,0.097-0.383c0.118-0.07,0.245-0.098,0.38-0.083C129.298,191.972,129.255,192.082,129.192,192.185z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M136.732,187.439c0.097-0.098,0.193-0.195,0.29-0.293
   c-0.016,0.178-0.032,0.355-0.048,0.534C136.893,187.601,136.812,187.521,136.732,187.439z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M148.017,155.532c0.264,0.248,0.529,0.496,0.793,0.743
   c-0.169,0.113-0.339,0.226-0.509,0.338c-0.085-0.366-0.172-0.733-0.258-1.1C148.043,155.514,148.017,155.532,148.017,155.532z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M147.604,160.005c-0.328,0.157-0.655,0.314-0.982,0.472
   c0.274-0.541,0.548-1.082,0.823-1.624c0.1,0.103,0.202,0.204,0.306,0.304C147.701,159.44,147.652,159.723,147.604,160.005z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M146.601,160.502c-0.163,0.163-0.326,0.326-0.489,0.489
   C146.275,160.828,146.438,160.665,146.601,160.502z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M147.444,158.854c0.122-0.035,0.242-0.071,0.364-0.106
   c-0.02,0.137-0.04,0.273-0.059,0.41C147.646,159.058,147.544,158.957,147.444,158.854z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M168.87,170.953c-0.75,0.964-1.39,1.787-2.03,2.61
   c-0.206-0.251-0.64-0.625-0.582-0.732c0.491-0.905,1.071-1.76,1.625-2.63C168.174,170.422,168.465,170.645,168.87,170.953z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M163.13,169.224c-0.521,0.287-1.042,0.574-1.563,0.861
   c0.356-0.486,0.713-0.972,1.069-1.459C162.8,168.825,162.965,169.024,163.13,169.224z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M161.888,182.339c-0.346,0.419-0.692,0.838-1.039,1.257
   C161.195,183.178,161.542,182.759,161.888,182.339z"/>
      <polygon fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" points="173.725,161.884 173.769,162.053 173.609,161.993 " />
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M211.631,142.248c-0.246-0.581-0.571-1.062-0.475-1.171
   c0.444-0.505,1.007-0.906,1.527-1.345c0.186,0.263,0.583,0.681,0.522,0.764C212.801,141.05,212.299,141.532,211.631,142.248z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M205.595,145.711c0.278,0.089,0.558,0.178,0.836,0.268
   c-0.044,0.06-0.106,0.178-0.127,0.171c-0.274-0.092-0.542-0.201-0.813-0.305C205.491,145.846,205.595,145.711,205.595,145.711z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M203.472,148.572c0.072,0.072,0.145,0.143,0.218,0.214
   C203.617,148.715,203.544,148.644,203.472,148.572z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M210.461,136.421c0.162-0.011,0.324-0.009,0.486,0.007
   C210.785,136.425,210.623,136.423,210.461,136.421z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M189.241,148.171c0.197,0.33,0.461,0.653,0.412,0.711
   c-0.502,0.592-1.047,1.15-1.625,1.668c-0.022,0.02-0.495-0.463-0.757-0.711C187.869,149.331,188.468,148.825,189.241,148.171z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M188.28,154.036c-0.256-0.423-0.513-0.847-0.768-1.271
   c0.417-0.119,0.834-0.237,1.252-0.355c-0.104,0.599-0.208,1.199-0.312,1.798L188.28,154.036z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M191.921,147.36c-0.523,0.186-1.046,0.372-1.57,0.559
   c0.378-0.339,0.756-0.678,1.135-1.016C191.63,147.055,191.776,147.208,191.921,147.36z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M188.72,156.459c-0.446-0.13-0.892-0.261-1.338-0.391
   C187.828,156.199,188.274,156.329,188.72,156.459z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M189.605,155.239c-0.191-0.051-0.382-0.103-0.574-0.154
   c0.134-0.167,0.269-0.335,0.399-0.503C189.487,154.8,189.546,155.02,189.605,155.239z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M188.452,154.208c-0.06,0.042-0.115,0.101-0.181,0.117
   c-0.029,0.007-0.12-0.084-0.112-0.11c0.019-0.065,0.078-0.119,0.121-0.178L188.452,154.208z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M180.987,165.905c0.168-0.336,0.336-0.672,0.505-1.007
   C181.324,165.233,181.156,165.569,180.987,165.905z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M107.231,210.487c0.077,0.076,0.153,0.152,0.231,0.228
   C107.385,210.64,107.308,210.563,107.231,210.487z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M101.856,230.808c0.063-0.392,0.126-0.783,0.189-1.175
   c0.269,0.353,0.538,0.707,0.806,1.06C102.52,230.731,102.188,230.769,101.856,230.808z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M101.856,230.808c0.332-0.039,0.664-0.077,0.996-0.115
   c0.059,0.109,0.106,0.223,0.142,0.341c-0.346,0.073-0.692,0.146-1.038,0.218C101.907,231.107,101.874,230.959,101.856,230.808z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M101.667,228.559c0.056-0.066,0.129-0.187,0.16-0.177
   c0.134,0.043,0.255,0.124,0.381,0.192c-0.047,0.066-0.116,0.195-0.137,0.188C101.929,228.712,101.798,228.633,101.667,228.559z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M100.598,229.6c0.031,0.131,0.063,0.262,0.094,0.392
   c-0.131-0.03-0.262-0.061-0.394-0.091C100.397,229.8,100.498,229.7,100.598,229.6z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M105.972,230.587c0.131,0.194,0.261,0.388,0.391,0.582
   c-0.232-0.095-0.464-0.189-0.697-0.284C105.768,230.786,105.87,230.687,105.972,230.587z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#B5BBC5" d="M94.017,303.53c-0.445,2.41-0.89,4.82-1.335,7.224
   c-0.919,2.559-1.793,5.142-2.774,7.684c-0.521,1.351-1.224,2.63-1.845,3.942c-0.201-1.707-0.547-3.412-0.572-5.121
   c-0.053-3.599,0.063-7.2,0.11-10.803c0.051-0.279,0.101-0.555,0.152-0.832c0.451-1.651,0.901-3.301,1.352-4.951
   c0.026-0.145,0.051-0.289,0.077-0.431c2.372-5.753,4.73-11.515,7.139-17.256c0.241-0.573,0.785-1.018,1.188-1.522
   c0.157,0.682,0.552,1.41,0.434,2.04C96.682,290.188,95.338,296.856,94.017,303.53z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M92.182,276.404c0.1-0.019,0.2-0.018,0.299,0.004
   c0.565-0.263,1.13-0.526,1.696-0.788c-0.086,0.304-0.099,0.819-0.271,0.878c-2.472,0.864,0.633,5.634-3.641,5.185
   C90.904,279.923,91.543,278.163,92.182,276.404z M92.27,277.141l0.111,0.079l0.021-0.113L92.27,277.141z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#E8C371" d="M89.105,300.673c-0.451,1.65-0.901,3.299-1.352,4.951
   C88.204,303.974,88.655,302.324,89.105,300.673z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M92.874,272.671c0.223-0.39,0.445-0.781,0.668-1.17
   c0.724,0.163,1.447,0.326,2.172,0.489C94.768,272.217,93.821,272.444,92.874,272.671z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M81.577,323.38c0.222-0.171,0.444-0.343,0.666-0.515
   c-0.106,0.284-0.212,0.567-0.318,0.852C81.809,323.604,81.693,323.492,81.577,323.38z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M90.316,288.523c0.043,0.201,0.087,0.401,0.13,0.602
   c-0.134-0.121-0.268-0.242-0.402-0.362C90.135,288.683,90.225,288.603,90.316,288.523z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M90.599,285.233l-0.021-0.015
   C90.578,285.218,90.599,285.233,90.599,285.233z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M92.559,271.029c-0.643-0.163-1.286-0.325-1.929-0.488
   c0.169-0.738,0.339-1.475,0.509-2.213C91.612,269.229,92.085,270.129,92.559,271.029z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M86.195,265.103c-0.128,0.25-0.257,0.501-0.385,0.751
   c-0.053-0.042-0.106-0.084-0.16-0.126c0.185-0.205,0.37-0.411,0.554-0.616L86.195,265.103z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M93.57,271.511c-0.333-0.16-0.667-0.32-1-0.48
   C92.904,271.19,93.237,271.351,93.57,271.511z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M90.644,270.511c-0.167,0.157-0.335,0.315-0.503,0.472
   C90.309,270.825,90.477,270.668,90.644,270.511z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M201.762,107.771c0.318,0.295,0.636,0.589,0.955,0.884
   c-0.137,0.046-0.348,0.171-0.398,0.124c-0.265-0.245-0.489-0.534-0.728-0.807C201.649,107.905,201.706,107.838,201.762,107.771z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M356.398,389.138c-0.311,0.532-0.621,1.064-0.932,1.597
   c-2.83,4.601-5.658,9.2-8.488,13.801l-0.039,0.039c-1.146,1.281-2.293,2.563-3.438,3.846l-0.049,0.043
   c-0.518,0.538-1.035,1.076-1.553,1.614c-0.111,0.031-0.182,0.101-0.215,0.209c-0.363,0.378-0.729,0.756-1.094,1.135
   c-2.145,1.96-4.291,3.92-6.436,5.879c-11.914,8.638-24.287,18.416-39.576,16.305c-26.599-3.672-44.732-20.296-57.283-43.422
   c-0.648-4.024-1.295-8.048-1.944-12.072c1.195-2.34,2.391-4.68,3.586-7.02c3.239,1.88,5.379-1.305,6.889-2.557
   c5.138-4.262,11.655-4.146,17.255-6.668c0.471-0.117,0.942-0.234,1.415-0.352c0.5,0.01,1,0.021,1.499,0.03
   c2.744,0.25,7.529-0.169,7.872,0.873c1.31,3.976-4.254,1.363-5.386,4.348c-0.594,1.566-3.324,2.316-5.075,3.456
   c-0.716,0.465-1.375-2.389-2.107-0.009c-0.17,0.554-0.544,1.044-0.824,1.564c-1.811-3.382-3.545,4.423-5.363,0.037
   c0.046,0.77-0.063,1.59,0.17,2.298c0.463,1.406,1.047,2.781,1.699,4.112c2.659,5.431,7.819,7.144,13.035,8.935
   c2.141,0.735,4.018,2.243,6.015,3.398c0.037-0.339,0.223-0.763,0.086-1.005c-0.74-1.313-1.56-2.583-2.353-3.869
   c1.352,0.75,2.633,1.876,4.075,2.148c1.499,0.283,3.153-0.261,4.739-0.443c0.927,2.502,2.874,2.492,5.003,2.032
   c-0.677,2.38,0.297,3.329,2.691,3.648c8.508,1.136,16.554-1.621,24.794-2.732l0.029,0.03c7.982,0.028,12.447-9.051,20.811-8.037
   c0.287-2.593,5.398-1.878,4.002-5.233c-1.922-4.619-6.283-6.65-10.586-8.694c-3.275-1.557-6.322-3.599-9.426-5.499
   c-0.186-0.113,0.033-0.881,0.063-1.345c3.303,0.476,6.592,1.093,9.908,1.4c9.893,0.917,17.855,6.346,26.162,10.994
   c0.584,1.379,1.168,2.758,1.752,4.137c0.023,1.306,0.045,2.612,0.066,3.918c-0.053,0.162-0.053,0.324,0,0.485
   C357.367,384.707,356.883,386.922,356.398,389.138z M257.203,382.131c0.028-0.062,0.091-0.137,0.074-0.182
   c-0.018-0.046-0.113-0.062-0.175-0.091c-0.028,0.062-0.091,0.137-0.074,0.181C257.047,382.086,257.142,382.102,257.203,382.131z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#D6DBE0" d="M249.41,438.644c-19.739-2.008-37.698-5.42-54.859-12.11
   c-3.501-1.365-5.79-5.915-10.593-3.704c-0.098,0.045-0.401-0.358-0.608-0.554c0.475-0.4,0.887-1,1.434-1.173
   c17.377-5.511,32.915-14.454,47.812-24.82c4.741-3.3,1.361,2.406,2.529,3.644c8.079,8.552,15.448,17.791,22.769,27.028
   c1.601,2.021,2.093,4.919,3.095,7.413C256.75,435.933,252.513,437.498,249.41,438.644z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#D6DBE0" d="M357.639,435.051c-8.666,0.436-13.225,0.838-17.779,0.803
   c-1.678-0.013-4.332-0.634-4.803-1.727c-0.82-1.9-1.949-4.137-0.047-6.665c7.654-10.176,14.996-20.585,22.566-30.824
   c0.352-0.474,2.055-0.53,2.699-0.115c10.564,6.812,21.26,13.457,31.438,20.815c2.566,1.855,3.143,5.408-1.691,7.654
   C378.357,430.411,366.234,433.874,357.639,435.051z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#8B898A" d="M237.297,390.183c12.551,23.126,30.684,39.75,57.283,43.422
   c15.289,2.111,27.662-7.667,39.576-16.305c-8.227,9.997-19.123,16.277-31.314,19.126c-18.237,4.261-31.146-7.325-43.978-17.589
   c-9.172-7.337-16.979-16.049-22.613-26.508c-0.183-0.473-0.366-0.945-0.543-1.419C236.242,390.668,236.77,390.425,237.297,390.183z"
      />
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#8B898A" d="M346.979,404.535c2.83-4.601,5.658-9.2,8.488-13.801
   c0.316,0.671,0.633,1.343,0.949,2.015C354.24,397.453,351.639,401.819,346.979,404.535z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#8B898A" d="M343.502,408.42c1.145-1.282,2.291-2.564,3.438-3.846
   C346.826,406.778,345.553,407.947,343.502,408.42z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#8B898A" d="M341.9,410.078c0.518-0.539,1.035-1.077,1.553-1.615
   C342.936,409.001,342.418,409.54,341.9,410.078z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#8B898A" d="M340.592,411.421c0.365-0.379,0.73-0.757,1.094-1.135
   C341.322,410.664,340.957,411.043,340.592,411.421z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#B5BBC5" d="M367.881,488.797c-0.166-0.159-0.369-0.322-0.354-0.344
   c0.111-0.174,0.258-0.33,0.42-0.462c0.016-0.014,0.303,0.216,0.283,0.263C368.158,488.442,368.014,488.604,367.881,488.797z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#8B898A" d="M231.493,487.583c-4.029-5.618-8.059-11.237-12.088-16.855
   c0.7-0.86,1.298-2.351,2.116-2.482c5.885-0.952,11.814-1.628,17.729-2.392c-0.072,0.169-0.144,0.338-0.216,0.507
   c-0.248,0.814-0.496,1.629-0.743,2.442c-0.031,0.195-0.063,0.39-0.094,0.584c0.16-0.086,0.32-0.172,0.481-0.258
   c2.912-1.368,5.824-2.737,8.736-4.106c25.378-8.168,51.191-9.942,77.442-5.256c0.502,0.131,1.006,0.262,1.508,0.393
   c1.131,0.208,2.262,0.416,3.393,0.625c2.033,0.726,4.127,1.319,6.09,2.201c6.842,3.072,15.166,3.34,19.896,10.436
   c-27.832-13.309-56.796-19.447-87.316-11.981c-10.125,2.477-19.692,7.239-29.509,10.972c-0.37,0.141-0.855,0.358-0.991,0.67
   c-0.785,1.795-1.481,3.629-2.207,5.45c0.387-2.411,0.899-4.813,1.1-7.238c0.08-0.967-0.619-2.876-0.812-2.856
   c-4.638,0.469-9.26,1.107-13.853,1.904c-0.172,0.03-0.414,2.2,0.054,2.954C225.198,478.123,228.376,482.834,231.493,487.583z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M355.742,473.421c-4.73-7.096-13.055-7.363-19.896-10.436
   c-1.963-0.882-4.057-1.476-6.09-2.201c7.822,1.963,15.643,3.925,23.463,5.887c0.121,0.043,0.242,0.088,0.363,0.131
   c0.021-0.171,0.045-0.342,0.066-0.513c-0.053-0.396-0.107-0.791-0.16-1.186c7.33,0.983,14.658,1.967,21.988,2.949
   c-0.441,0.896-0.881,1.792-1.322,2.688c-0.344,0.379-0.688,0.759-1.029,1.138c-3.674-7.117-10.43-3.484-15.814-4.279
   c-2.246-0.332-1.59,2.178-1.084,3.728c0.15,0.551,0.369,1.093,0.441,1.654c0.307,2.428,0.57,4.859,0.852,7.291
   C356.928,477.988,356.334,475.704,355.742,473.421z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M360.381,490.18c-0.629,0.17-1.256,0.339-1.885,0.509
   c0.469-0.32,0.938-0.641,1.404-0.962C360.061,489.877,360.221,490.028,360.381,490.18z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M239.249,465.854c-5.915,0.764-11.844,1.439-17.729,2.392
   c-0.818,0.132-1.416,1.622-2.116,2.482c-0.361-1.085-0.723-2.169-1.085-3.252l0.011-0.01c7.311-1.427,14.622-2.854,21.933-4.279
   C239.925,464.077,239.587,464.965,239.249,465.854z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M247.413,465.022c-2.913,1.369-5.824,2.738-8.736,4.106
   c-0.129-0.108-0.259-0.218-0.388-0.326c0.248-0.813,0.496-1.628,0.744-2.441C241.826,465.914,244.62,465.469,247.413,465.022z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M326.363,460.159c-0.502-0.131-1.006-0.262-1.508-0.393
   C325.357,459.897,325.861,460.028,326.363,460.159z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M374.154,470.741c0.441-0.896,0.881-1.793,1.322-2.688
   c3.701-0.665,7.4-1.365,11.117-1.94c0.328-0.052,0.781,0.707,1.178,1.09c-1.068,0.855-2.041,2.252-3.225,2.465
   C381.127,470.281,377.625,470.417,374.154,470.741z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M218.319,467.476c0,0,0.011-0.011,0.011-0.01L218.319,467.476z" />
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M223.19,338.281c5.912-6.537,6.933-12.94-0.522-19.112
   c4.798,1.843,9.698,3.459,14.367,5.585c5.507,2.507,3.748,6.397,0.159,8.462C232.957,335.654,227.894,336.657,223.19,338.281z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M222.761,319.199c-0.34-0.083-0.68-0.166-1.02-0.25
   C222.08,319.032,222.42,319.116,222.761,319.199z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M222.713,338.812c0.181-0.183,0.363-0.366,0.544-0.548
   C223.076,338.447,222.895,338.63,222.713,338.812z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M222.2,339.292c0.192-0.176,0.385-0.352,0.578-0.527
   C222.585,338.94,222.393,339.116,222.2,339.292z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M221.104,340.236c0.217-0.195,0.434-0.392,0.651-0.587
   C221.538,339.844,221.321,340.041,221.104,340.236z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#AC7825" d="M203.579,347.613c-0.189-0.047-0.378-0.094-0.566-0.141
   c0.167-0.049,0.333-0.098,0.498-0.146C203.567,347.414,203.591,347.509,203.579,347.613z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#E8C371" d="M109.836,315.276c0.413-0.114,1.578-0.811,2.589-0.633
   c1.884,0.333,3.997,0.837,3.776,3.585c-0.136,1.694-0.241,3.412-2.586,3.37C110.391,321.539,111.409,318.767,109.836,315.276z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#815815" d="M480.287,446.412c-3.098-0.495-7.811,0.922-6.902-2.979
   c1.068-4.589,6.328-5.921,10.871-6.27c4.621-0.354,12.246,2.79,11.178,4.521C492.387,446.624,485.332,445.641,480.287,446.412z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#815815" d="M466.234,461.604c-1.518-0.738-5.078,1.416-4.668-1.369
   c0.623-4.216,5.328-3.964,8.6-4.371c2.313-0.287,6.941,0.924,6.137,2.669C474.672,462.07,470,461.377,466.234,461.604z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#C59426" d="M237.297,390.183c-0.527,0.242-1.055,0.485-1.588,0.728
   c-1.035,0.181-2.064,0.36-3.092,0.54c-3.325-5.315,0.949-9.072,2.736-13.34C236.001,382.135,236.649,386.159,237.297,390.183z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#E8C371" d="M264.761,357c-1.068,0.326-2.137,0.652-3.206,0.979
   c14.184-14.668,10.531-29.765,3.335-46.667c-6.296-14.791-7.131-31.744-16.028-45.837c-1.607-2.547-2.993-5.235-4.426-7.888
   c-1.151-2.13-1.204-3.798,1.913-3.842c10.242,3.476,17.127-1.19,22.585-9.314c0.317-0.486,0.635-0.972,0.952-1.459l-0.046,0.041
   c5.542-5.717,11.004-11.514,16.644-17.132c7.457-7.428,22.326-5.45,29.568,3.753c1.029,1.31,2.377,2.37,3.578,3.545
   c4.762,11.545,9.145,23.539,25.393,21.554c-4.24,11.149-9.967,21.989-12.365,33.522c-3.35,16.104-11.754,30.934-13.068,47.007
   c-0.721,8.833,7.264,17.177,15.018,23.256c0.693,0.544,1.291,1.213,1.93,1.824c-2.934-1.164-5.865-2.328-8.799-3.492
   c-6.113-11.916-12.197-23.717-27.849-25.298c-19.107-1.931-27.135,10.959-34.349,25.117l-0.111-0.076l0.076-0.111
   C265.257,356.653,265.009,356.827,264.761,357z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M356.031,373.952c-8.307-4.648-16.27-10.078-26.162-10.994
   c-3.316-0.307-6.605-0.925-9.908-1.4c-0.029,0.464-0.248,1.232-0.063,1.345c3.104,1.899,6.15,3.942,9.426,5.499
   c4.303,2.044,8.664,4.076,10.586,8.694c1.396,3.355-3.715,2.64-4.002,5.233c-8.363-1.014-12.828,8.065-20.811,8.037l0.066-0.101
   l-0.096,0.07c-8.24,1.111-16.286,3.868-24.794,2.732c-2.394-0.319-3.369-1.269-2.692-3.649c0.337-0.314,0.637-0.853,1.015-0.913
   c11.144-1.806,22.301-3.529,33.444-5.349c4.729-0.772,1.52-5.132,3.066-7.48c0.412-0.624-1.189-2.573-1.865-3.914
   c0.025,1.278,0.059,2.556,0.066,3.834c0,0.077-0.209,0.155-0.32,0.232c-1.576-5.543-6.945-5.67-10.996-7.616
   c-6.014-2.891-12.298-2.135-18.569-1.034c-0.135,0.056-0.272,0.106-0.411,0.151c-5.635,0.473-11.178,0.451-16.447,3.676
   c-3.18,1.946-7.962,1.357-8.667,6.56c0,0-0.064,0.108-0.064,0.108l0.108-0.065c1.373,0.863,2.828,1.621,4.103,2.608
   c3.367,2.608,8.581,2.501,10.535,7.168c-1.586,0.183-3.24,0.727-4.739,0.443c-1.442-0.271-2.723-1.397-4.075-2.148
   c0.792,1.285,1.612,2.555,2.353,3.869c0.137,0.242-0.049,0.666-0.086,1.005c-1.997-1.155-3.874-2.663-6.015-3.398
   c-5.216-1.791-10.376-3.504-13.035-8.935c-0.651-1.331-1.236-2.706-1.699-4.112c-0.233-0.708-0.124-1.528-0.17-2.298
   c1.818,4.386,3.552-3.419,5.363-0.037c0.28-0.52,0.653-1.01,0.824-1.564c0.732-2.38,1.392,0.474,2.107,0.009
   c1.751-1.14,4.48-1.89,5.075-3.456c1.132-2.985,6.696-0.373,5.386-4.348c-0.343-1.042-5.128-0.622-7.872-0.873
   c5.228-3.006,10.88-4.514,16.88-4.853c0.683-0.039,1.347-0.413,2.018-0.632c2.292-0.189,4.586-0.377,6.881-0.565
   c2.752,0.845,5.65,2.899,7.838-0.943c0.33,0.034,0.66,0.067,0.989,0.101c1.366,0.604,2.686,1.567,4.104,1.747
   c5.041,0.639,10.193,0.607,15.156,1.579c7.33,1.436,14.535,3.513,21.793,5.317C347.309,365.686,352.662,368.479,356.031,373.952z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#E8C371" d="M360.348,295.391c-0.84-0.569-2.293-1.405-2.232-1.536
   c0.576-1.232,1.17-2.658,2.203-3.444c12.836-9.76,25.467-19.901,41.184-24.868c1.041-0.329,2.393,0.32,3.602,0.514
   c-0.316,1.186-0.256,2.741-1.004,3.503C391.887,282.005,377.809,291.315,360.348,295.391z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#815815" d="M341.656,363.294c-7.258-1.805-14.463-3.882-21.793-5.317
   c-4.963-0.972-10.115-0.94-15.156-1.579c-1.419-0.18-2.738-1.143-4.103-1.747c4.856,0.292,9.711,0.6,14.567,0.864
   c0.949,0.052,1.91-0.069,2.865-0.11c-0.143-0.412-0.283-0.824-0.426-1.235c2.281,0.557,4.563,1.114,6.846,1.671
   c1.094,0.336,2.188,0.672,3.281,1.007c2.934,1.164,5.865,2.328,8.799,3.492c1.541,0.813,3.078,1.625,4.617,2.437
   c0.158,0.19,0.318,0.38,0.477,0.57L341.656,363.294z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#C59426" d="M356.398,389.138c0.484-2.215,0.969-4.431,1.451-6.646
   c0-0.162,0-0.323,0-0.485c-0.021-1.306-0.043-2.612-0.066-3.918c2.566,4.093,4.555,8.295,2.494,13.259
   C358.984,390.611,357.691,389.875,356.398,389.138z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M341.631,363.347c-0.158-0.19-0.318-0.38-0.477-0.57
   C341.313,362.967,341.473,363.157,341.631,363.347z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#E8C371" d="M233.092,296c-16.247-4.651-30.543-12.646-41.238-26.38
   c-0.374-0.48-0.379-1.837-0.006-2.171c0.55-0.492,1.714-0.785,2.411-0.551c15.651,5.243,28.813,14.599,41.188,25.205
   c0.691,0.592,0.839,1.816,1.241,2.745C235.651,295.18,234.616,295.512,233.092,296z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#E8C371" d="M261.573,357.965c-0.828,0.33-1.657,0.66-2.485,0.99
   C259.917,358.625,260.745,358.295,261.573,357.965z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#E8C371" d="M259.119,358.937c-0.501,0.172-1.003,0.344-1.504,0.515
   C258.116,359.28,258.617,359.108,259.119,358.937z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#E8C371" d="M257.649,359.432c-0.34,0.213-0.681,0.427-1.021,0.641
   C256.968,359.859,257.309,359.646,257.649,359.432z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#C59426" d="M201.634,212.032c0.173,0.348,0.346,0.695,0.519,1.043
   C201.98,212.728,201.807,212.38,201.634,212.032z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#C59426" d="M341.543,187.189c0.582-0.371,1.164-0.741,1.746-1.111
   C342.707,186.448,342.125,186.818,341.543,187.189z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#D8A730" d="M269.887,242.97c-0.317,0.487-0.635,0.973-0.952,1.459
   C269.252,243.942,269.57,243.456,269.887,242.97z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M117.653,229.296c-0.166-0.502-0.332-1.003-0.498-1.505
   c0.319,0.002,0.639,0.004,0.958,0.006l0.021-0.022c1.142-2.513,2.283-1.399,3.423,0.001l0.013-0.003
   c-0.166,0.368-0.333,0.734-0.499,1.102c-0.369,0.427-0.739,0.854-1.108,1.281c-0.297,0.052-0.595,0.104-0.893,0.156
   c-0.176-0.023-0.353-0.046-0.529-0.069C118.245,229.926,117.949,229.611,117.653,229.296z M119.739,227.903
   c0.019-0.069,0.037-0.137,0.056-0.206c-0.122-0.014-0.243-0.039-0.364-0.033c-0.021,0.001-0.037,0.127-0.055,0.195
   C119.497,227.879,119.617,227.898,119.739,227.903z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M125.914,216.524c0.025-0.249,0.051-0.498,0.077-0.747
   c0.653,0.243,1.306,0.486,1.959,0.73c-0.401,0.093-0.801,0.187-1.203,0.279c0.399,0.236,0.799,0.472,1.198,0.708
   c0.499,0.07,0.998,0.139,1.496,0.208c-0.324,0.452-0.648,0.903-0.97,1.354c0.003-0.129,0.003-0.258,0.003-0.386
   c-0.181,0.089-0.362,0.179-0.543,0.268l0.03-0.014c-0.174,0.159-0.348,0.318-0.522,0.477c0,0-0.012-0.001-0.012-0.001
   C126.923,218.441,126.418,217.483,125.914,216.524z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M125.914,216.524c0.504,0.959,1.009,1.917,1.514,2.876
   c0.013,0.255,0.027,0.51,0.041,0.766c-0.836-0.437-1.937-0.694-2.404-1.382C124.803,218.4,125.595,217.3,125.914,216.524z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M130.793,216.654c0.354-0.853,0.708-1.705,1.063-2.558
   c0.193-0.16,0.385-0.32,0.578-0.48c0.111,0.11,0.219,0.223,0.325,0.338C132.104,214.854,131.448,215.753,130.793,216.654z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M135.582,210.628c-0.223-0.001-0.446-0.003-0.669-0.004
   c0.291-0.125,0.583-0.25,0.874-0.375C135.769,210.403,135.701,210.53,135.582,210.628z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M114.54,236.883c0.298,0.04,0.596,0.079,0.895,0.117
   c-0.258,0.276-0.516,0.554-0.774,0.832C114.62,237.516,114.58,237.199,114.54,236.883z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M121.071,228.875c0.166-0.367,0.333-0.734,0.499-1.102
   C121.403,228.141,121.237,228.508,121.071,228.875z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#8B898A" d="M119.07,230.312c0.298-0.052,0.596-0.104,0.893-0.156
   C119.665,230.208,119.368,230.26,119.07,230.312z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#8B898A" d="M117.653,229.296c0.296,0.315,0.592,0.63,0.888,0.946
   c-0.203,0.018-0.405,0.035-0.602,0.053C117.847,229.962,117.75,229.628,117.653,229.296z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M127.931,218.939c0.181-0.089,0.362-0.179,0.543-0.268
   c0,0.128,0,0.257-0.003,0.386C128.289,219.019,128.11,218.979,127.931,218.939z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M127.439,219.401c0.174-0.159,0.348-0.317,0.522-0.477
   C127.787,219.084,127.613,219.243,127.439,219.401z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M113.211,239.552c0.243-0.164,0.487-0.328,0.73-0.493
   C113.698,239.224,113.455,239.388,113.211,239.552z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#D6DBE0" d="M324.457,355.841c-2.283-0.557-4.564-1.114-6.846-1.671
   c-15.169-3.377-30.261-3.756-45.216,1.163c-0.185,0.008-0.369,0.016-0.553,0.024c-0.452,0.006-0.904,0.012-1.356,0.018
   c-0.71,0.177-1.421,0.354-2.13,0.532c4.254-6.283,6.615-13.984,13.545-18.264C298.395,327.458,313.273,333.832,324.457,355.841z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#B5BBC5" d="M324.457,355.841c-11.184-22.009-26.062-28.382-42.556-18.199
   c-6.93,4.279-9.291,11.981-13.545,18.264c-0.939,0.254-1.876,0.507-2.815,0.761c7.213-14.157,15.241-27.047,34.348-25.117
   c15.652,1.581,21.736,13.382,27.849,25.298C326.645,356.513,325.551,356.177,324.457,355.841z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M272.396,355.333c14.955-4.919,30.046-4.541,45.216-1.163
   c0.143,0.412,0.283,0.824,0.426,1.236c-0.955,0.041-1.916,0.162-2.865,0.11c-4.855-0.264-9.71-0.572-14.567-0.864
   c-0.331-0.033-0.661-0.066-0.991-0.1c-1.468,0.154-2.936,0.308-4.403,0.461c-0.693,0.107-1.387,0.214-2.08,0.322
   c-0.452,0.053-0.903,0.106-1.354,0.159c-2.295,0.188-4.589,0.376-6.881,0.565C280.901,352.861,276.437,357.739,272.396,355.333z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M264.498,361.514c-0.472,0.117-0.944,0.234-1.415,0.352
   C263.555,361.748,264.026,361.63,264.498,361.514z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M270.486,355.374c0.452-0.006,0.905-0.011,1.356-0.018
   C271.39,355.362,270.938,355.368,270.486,355.374z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#B5BBC5" d="M264.761,357c0.249-0.173,0.497-0.347,0.745-0.52
   C265.257,356.653,265.009,356.827,264.761,357z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#E8C371" d="M483.176,318.363c-0.27,1.948-0.313,4.595-3.076,4.488
   c-1.188-0.046-3.09-2.094-3.268-3.419c-0.342-2.576,1.678-4.281,4.133-4.55C483.139,314.645,483.08,316.641,483.176,318.363z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#B5BBC5" d="M489.695,224.523c-8.855-13.094,8.887-19.216,11.188-30.47
   c0.066-0.325,0.707-0.533,1.08-0.795c0.314,0.711,0.873,1.41,0.906,2.133c0.662,14.217-1.574,27.831-8.691,40.367
   c-0.322,0.564-1.287,1.041-1.947,1.033c-0.416-0.005-1.09-0.946-1.184-1.54C490.592,232.354,490.293,229.432,489.695,224.523z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M132.434,213.616c-0.193,0.16-0.385,0.32-0.578,0.48
   c-0.128-1.173-3.242,0.594-1.949-1.979C129.763,214.281,133.663,209.625,132.434,213.616z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M136.168,204.382c0.429,1.207,0.69,1.657,0.721,2.123
   c0.024,0.36-0.241,0.739-0.377,1.109c-0.273-0.547-0.597-1.078-0.795-1.651C135.641,205.747,135.853,205.432,136.168,204.382z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M132.379,209.167c0.312,0.343,0.623,0.687,0.935,1.03
   C133.002,209.854,132.691,209.51,132.379,209.167z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M128.334,212.747c-0.142,0.211-0.252,0.374-0.361,0.537
   c-0.162-0.223-0.384-0.428-0.464-0.678c-0.048-0.149,0.125-0.37,0.2-0.559C127.904,212.265,128.099,212.483,128.334,212.747z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M133.469,206.644c-0.024,0.06-0.034,0.146-0.078,0.171
   c-0.042,0.025-0.121-0.014-0.184-0.025c0.04-0.091,0.08-0.183,0.12-0.274C133.375,206.558,133.422,206.601,133.469,206.644z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M131.354,205.708c-0.172-0.116-0.345-0.232-0.518-0.349
   c0.076-0.077,0.152-0.154,0.228-0.23c0.11,0.182,0.219,0.365,0.329,0.547L131.354,205.708z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#8B898A" d="M127.945,217.495c-0.399-0.236-0.798-0.472-1.198-0.708
   c0.401-0.093,0.802-0.187,1.203-0.279C127.948,216.837,127.947,217.166,127.945,217.495z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M130.823,214.313c-0.099,0.132-0.144,0.191-0.189,0.251
   c-0.038-0.062-0.076-0.125-0.115-0.188C130.598,214.361,130.677,214.344,130.823,214.313z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M131.393,205.676c0.079,0.099,0.158,0.198,0.237,0.297
   c-0.092-0.089-0.183-0.178-0.275-0.266C131.354,205.708,131.393,205.676,131.393,205.676z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M133.718,209.722c-0.039-0.129-0.078-0.258-0.117-0.387
   c0.128,0.04,0.257,0.08,0.386,0.12C133.897,209.543,133.808,209.633,133.718,209.722z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M117.199,227.24c-0.2-0.144-0.4-0.288-0.601-0.433
   C116.799,226.952,116.999,227.096,117.199,227.24z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M109.246,242.503c-0.159-0.198-0.323-0.403-0.488-0.608
   c0.214-0.1,0.475-0.329,0.632-0.272c0.259,0.093,0.451,0.372,0.671,0.571C109.842,242.277,109.623,242.36,109.246,242.503z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M107.323,242.484c-0.002,0.472-0.004,0.943-0.006,1.416
   c-0.037-0.232-0.1-0.464-0.102-0.695c-0.002-0.229,0.059-0.457,0.092-0.686L107.323,242.484z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M113.196,235.624c0.3,0.292,0.601,0.582,0.902,0.873
   C113.797,236.206,113.497,235.915,113.196,235.624z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M111.424,236.31c0.017,0.055,0.035,0.11,0.053,0.165
   c-0.059,0.017-0.125,0.058-0.173,0.043c-0.031-0.01-0.038-0.101-0.056-0.155C111.306,236.346,111.365,236.328,111.424,236.31z"/>
      <polygon fill-rule="evenodd" clip-rule="evenodd" fill="#291913" points="111.933,237.303 112.058,237.264 112.033,237.385 " />
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M129.93,192.922c-0.136-0.138-0.273-0.275-0.409-0.412
   C129.658,192.647,129.794,192.784,129.93,192.922z"/>
      <polygon fill-rule="evenodd" clip-rule="evenodd" fill="#291913" points="92.27,277.141 92.402,277.107 92.381,277.22 " />
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M267.897,377.566c0.705-5.203,5.487-4.613,8.667-6.56
   c5.27-3.226,10.812-3.203,16.447-3.676c-1.628,4.198-6.198,3.171-9.092,5.121c-1.592,1.073-4.674,2.821-4.538,3.125
   c1.409,3.149,3.51,5.89,7.179,6.75c0.896,0.21,2.268,0.675,2.69,0.267c4.098-3.958,9.034-1.942,13.66-2.192
   c2.877-0.156,5.826-0.348,8.548-1.164c0.828-0.248,1.82-3.424,1.352-3.901c-1.809-1.846-2.91-4.195-6.848-4.04
   c-4.119,0.163-8.355-2.622-12.54-4.118c6.271-1.101,12.555-1.856,18.569,1.034c4.051,1.946,9.42,2.073,10.996,7.616
   c0.111-0.078,0.32-0.156,0.32-0.232c-0.008-1.278-0.041-2.556-0.066-3.834c0.676,1.341,2.277,3.29,1.865,3.914
   c-1.547,2.348,1.662,6.708-3.066,7.48c-11.143,1.82-22.3,3.542-33.444,5.349c-0.377,0.061-0.678,0.599-1.015,0.913
   c-2.129,0.461-4.076,0.472-5.003-2.031c-1.955-4.667-7.168-4.561-10.535-7.169c-1.275-0.988-2.73-1.746-4.103-2.608L267.897,377.566
   z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M257.203,382.131c-0.061-0.029-0.156-0.045-0.174-0.092
   c-0.017-0.043,0.046-0.119,0.074-0.181c0.062,0.029,0.157,0.045,0.175,0.091C257.294,381.995,257.23,382.069,257.203,382.131z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#D8A730" d="M357.85,382.007c0,0.162,0,0.323,0,0.485
   C357.797,382.331,357.797,382.169,357.85,382.007z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M238.677,469.129c-0.161,0.086-0.321,0.172-0.481,0.258
   c0.031-0.194,0.063-0.389,0.094-0.584C238.418,468.911,238.547,469.021,238.677,469.129z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#291913" d="M353.648,466.289c-0.021,0.171-0.045,0.342-0.066,0.513
   c-0.121-0.043-0.242-0.088-0.363-0.131C353.361,466.544,353.506,466.416,353.648,466.289z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#8B898A" d="M293.423,367.179c4.185,1.497,8.42,4.281,12.54,4.118
   c3.938-0.156,5.04,2.194,6.848,4.04c0.469,0.478-0.523,3.653-1.352,3.901c-2.723,0.816-5.671,1.008-8.548,1.164
   c-4.626,0.25-9.563-1.766-13.66,2.192c-0.423,0.408-1.794-0.057-2.69-0.267c-3.668-0.86-5.77-3.601-7.179-6.75
   c-0.136-0.304,2.946-2.053,4.538-3.125c2.895-1.95,7.464-0.923,9.092-5.121C293.151,367.286,293.288,367.235,293.423,367.179z
    M288.694,378.928c-0.17-0.143-0.326-0.336-0.385-0.311c-0.135,0.059-0.222,0.226-0.329,0.349c0.125,0.074,0.248,0.202,0.375,0.205
   C288.459,379.173,288.566,379.026,288.694,378.928z M281.495,375.636l-0.066-0.129l-0.071,0.126L281.495,375.636z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#815815" d="M291.776,355.494c0.451-0.053,0.903-0.106,1.353-0.157
   c0.692-0.107,1.387-0.215,2.082-0.323c1.468-0.153,2.935-0.307,4.403-0.461C297.426,358.393,294.528,356.339,291.776,355.494z"/>
      <polygon fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" points="267.941,377.61 267.833,377.675 267.897,377.566 " />
      <polygon fill-rule="evenodd" clip-rule="evenodd" fill="#291913" points="315.068,390.335 315.164,390.265 315.098,390.365 " />
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M119.738,227.91c-0.121-0.012-0.241-0.031-0.361-0.051
   c0.018-0.068,0.034-0.194,0.055-0.195c0.121-0.005,0.242,0.019,0.364,0.033C119.776,227.766,119.758,227.834,119.738,227.91z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M118.134,227.775l-0.021,0.022L118.134,227.775z" />
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#3C3F4C" d="M295.21,355.013c-0.694,0.108-1.389,0.216-2.082,0.323
   C293.824,355.228,294.517,355.121,295.21,355.013z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" d="M288.694,378.928c-0.128,0.098-0.235,0.245-0.339,0.243
   c-0.126-0.002-0.25-0.13-0.375-0.205c0.107-0.123,0.194-0.29,0.329-0.349C288.369,378.591,288.524,378.785,288.694,378.928z"/>
      <polygon fill-rule="evenodd" clip-rule="evenodd" fill="#635F69" points="281.495,375.636 281.358,375.633 281.429,375.506 " />
    </Svg>

  );
};

export default Icon;
