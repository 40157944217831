import { useIsMounted } from "@zinuswap/hooks";
import { AtomBox } from "@zinuswap/ui/components/AtomBox";
import throttle from "lodash/throttle";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import BottomNav from "../../components/BottomNav";
import { Box } from "../../components/Box";
import Flex from "../../components/Box/Flex";
import ZinuPrice from "../../components/ZinuPrice/ZinuPrice";
import Footer from "../../components/Footer";
import LangSelector from "../../components/LangSelector/LangSelector";
import VMenuItems from "../../components/MenuItems/VMenuItems";
import { SubMenuItems } from "../../components/SubMenuItems";
import { useMatchBreakpoints } from "../../contexts";
import Logo from "./components/Logo";
import { MENU_HEIGHT, MOBILE_MENU_HEIGHT, TOP_BANNER_HEIGHT, TOP_BANNER_HEIGHT_MOBILE } from "./config";
import { MenuContext } from "./context";
import { NavProps } from "./types";
import MobLogo from "./components/MobLogo";
import { HamburgerCloseIcon, HamburgerIcon, IconButton } from "../../components";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: calc(100vh - ${MENU_HEIGHT}px);

  ${({ theme }) => theme.mediaQueries.sm} {
    display: grid;
    grid-template-columns: 240px 1fr;
    min-height: 100vh;
  }
`;

const StyledNav = styled.nav<{ showMobMenu: boolean }>`
  position: absolute;
  z-index: 10;
  left: ${({ showMobMenu }) => (showMobMenu ? '0' : '-240px')};
  -webkit-animation: ${({ showMobMenu }) => (showMobMenu ? 'pushmobmenu' : 'popmobmenu')} 0.2s linear 1;
  animation: ${({ showMobMenu }) => (showMobMenu ? 'pushmobmenu' : 'popmobmenu')} 0.2s linear 1;
  display: flex;
  flex-direction: column;
  width: 240px;
  height: 100%;
  background-color: ${({ theme }) => theme.nav.background};
  transform: translate3d(0, 0, 0);

  ${({ theme }) => theme.mediaQueries.sm} {
    position: relative;
    display: flex;
    width: 100%;
    left: 0;
  }

  @keyframes pushmobmenu {
    0% {
      left: -240px;
    }
    100% {
      left: 0;
    }
  }
  @keyframes popmobmenu {
    0% {
      left: 0px;
    }
    100% {
      left: -240px;
    }
  }
`;

const StyledTopNav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.background};
  // border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
  transform: translate3d(0, 0, 0);

  padding-left: 16px;
  padding-right: 16px;

  ${({ theme }) => theme.mediaQueries.sm} {
    justify-content: flex-end;
  }
`;

const FixedContainer = styled.div<{ showMenu: boolean; height: number }>`
  position: fixed;
  top: ${({ showMenu, height }) => (showMenu ? 0 : `-${height}px`)};
  transition: top 0.2s;
  height: ${({ height }) => `${height}px`};
  width: 100%;
  z-index: 20;

  ${({ theme }) => theme.mediaQueries.sm} {
    width: calc(100% - 240px);
  }
`;

const TopBannerContainer = styled.div<{ height: number }>`
  height: ${({ height }) => `${height}px`};
  min-height: ${({ height }) => `${height}px`};
  max-height: ${({ height }) => `${height}px`};
  width: 100%;
`;

const BodyWrapper = styled(Box)`
  position: relative;
  display: flex;
  max-width: 100vw;
`;

const Inner = styled.div`
  flex-grow: 1;
  transition: margin-top 0.2s, margin-left 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate3d(0, 0, 0);
  max-width: 100%;
`;

const MobLogoWrap = styled.div`
  display: flex;
  gap: 10px;

  ${({ theme }) => theme.mediaQueries.sm} {
    display: none;
  }
`;

const Menu: React.FC<React.PropsWithChildren<NavProps>> = ({
  linkComponent = "a",
  banner,
  rightSide,
  isDark,
  toggleTheme,
  currentLang,
  setLang,
  zinuPriceUsd,
  links,
  subLinks,
  footerLinks,
  activeItem,
  activeSubItem,
  langs,
  buyZinuLabel,
  children,
}) => {
  const { isMobile } = useMatchBreakpoints();
  const isMounted = useIsMounted();
  const [showMenu, setShowMenu] = useState(true);
  const [showMobMenu, setShowMobMenu] = useState(false);
  const refPrevOffset = useRef(typeof window === "undefined" ? 0 : window.pageYOffset);

  const topBannerHeight = isMobile ? TOP_BANNER_HEIGHT_MOBILE : TOP_BANNER_HEIGHT;

  const totalTopMenuHeight = isMounted && banner ? MENU_HEIGHT + topBannerHeight : MENU_HEIGHT;

  useEffect(() => {
    const handleScroll = () => {
      const currentOffset = window.pageYOffset;
      const isBottomOfPage = window.document.body.clientHeight === currentOffset + window.innerHeight;
      const isTopOfPage = currentOffset === 0;
      // Always show the menu when user reach the top
      if (isTopOfPage) {
        setShowMenu(true);
      }
      // Avoid triggering anything at the bottom because of layout shift
      else if (!isBottomOfPage) {
        if (currentOffset < refPrevOffset.current || currentOffset <= totalTopMenuHeight) {
          // Has scroll up
          setShowMenu(true);
        } else {
          // Has scroll down
          setShowMenu(false);
        }
      }
      refPrevOffset.current = currentOffset;
    };
    const throttledHandleScroll = throttle(handleScroll, 200);

    window.addEventListener("scroll", throttledHandleScroll);
    return () => {
      window.removeEventListener("scroll", throttledHandleScroll);
    };
  }, [totalTopMenuHeight]);

  // Find the home link if provided
  const homeLink = links.find((link) => link.label === "Home");

  return (
    <MenuContext.Provider value={{ linkComponent }}>
      <AtomBox
        asChild
        minHeight={{
          xs: "auto",
          md: "100vh",
        }}
      >
        <Wrapper>
          <StyledNav showMobMenu={showMobMenu}>
            <Logo href={homeLink?.href ?? "/"} />
            <AtomBox>
              <VMenuItems items={links} activeItem={activeItem} activeSubItem={activeSubItem} ml="24px" mr="24px" />
            </AtomBox>
          </StyledNav>
          <BodyWrapper mt={!subLinks ? `${totalTopMenuHeight + 1}px` : "0"}>
            <FixedContainer showMenu={showMenu} height={totalTopMenuHeight}>
              <StyledTopNav>
                <MobLogoWrap>
                  <IconButton scale="sm" variant="text" onClick={()=>{setShowMobMenu(!showMobMenu)}}>
                    {showMobMenu ? <HamburgerCloseIcon width="32px" /> : <HamburgerIcon width="32px" />}                    
                  </IconButton>
                  <MobLogo href={homeLink?.href ?? "/"} />
                </MobLogoWrap>
                <Flex alignItems="center" height="100%">
                  <AtomBox mr="12px" display={{ xs: "none", lg: "block" }}>
                    <ZinuPrice showSkeleton={false} zinuPriceUsd={zinuPriceUsd} />
                  </AtomBox>
                  <Box mt="4px">
                  </Box>
                  {rightSide}
                </Flex>
              </StyledTopNav>
            </FixedContainer>
            <Inner>{children}</Inner>
          </BodyWrapper>
        </Wrapper>
      </AtomBox>
    </MenuContext.Provider>
  );
};

export default Menu;
