import { Pool } from '@zinuswap/uikit'
import { SerializedWrappedToken } from '@zinuswap/token-lists'
import { bscTestnetTokens } from '@zinuswap/tokens'
import { PoolCategory } from '../types'


export const livePools: Pool.SerializedPoolConfig<SerializedWrappedToken>[] = [
  {
    sousId: 1000,
    stakingToken: bscTestnetTokens.zinu,
    earningToken: bscTestnetTokens.zinu,
    contractAddress: '0xD492FF719e10FFb48fE17aB7B62910B3FA1FDD98', // SOLVED-SD,
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '10',
    isFinished: false,
  },
  {
    sousId: 1001,
    stakingToken: bscTestnetTokens.zinu,
    earningToken: bscTestnetTokens.zinu,
    contractAddress: '0xD492FF719e10FFb48fE17aB7B62910B3FA1FDD98', // SOLVED-SD
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '10',
    isFinished: false,
  },
].map((p) => ({
  ...p,
  stakingToken: p.stakingToken.serialize,
  earningToken: p.earningToken.serialize,
}))

// known finished pools
const finishedPools = [].map((p) => ({
  ...p,
  isFinished: true,
  stakingToken: p.stakingToken.serialize,
  earningToken: p.earningToken.serialize,
}))

export default [...livePools, ...finishedPools] as Pool.SerializedPoolConfig<SerializedWrappedToken>[]
