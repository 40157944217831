import BigNumber from 'bignumber.js'
import { vaultPoolConfig } from 'config/constants/pools'
import { BIG_ZERO } from '@zinuswap/utils/bigNumber'
import { getApy } from '@zinuswap/utils/compoundApyHelpers'
import { getBalanceNumber, getFullDisplayBalance, getDecimalAmount } from '@zinuswap/utils/formatBalance'
import memoize from 'lodash/memoize'
import { Token } from '@zinuswap/sdk'
import { Pool } from '@zinuswap/uikit'
import { ZINU_DECIMALS } from 'config/constants'

// min deposit and withdraw amount
export const MIN_LOCK_AMOUNT = new BigNumber(10000) // CHECKED-SD

export const ENABLE_EXTEND_LOCK_AMOUNT = new BigNumber(100000000000000) // CHECK-SD

export const convertSharesToZinu = (
  shares: BigNumber,
  zinuPerFullShare: BigNumber,
  decimals = 18, // CHECKED-SD
  decimalsToRound = 3,
  fee?: BigNumber,
) => {
  const sharePriceNumber = getBalanceNumber(zinuPerFullShare, decimals)
  const amountInZinu = new BigNumber(shares.multipliedBy(sharePriceNumber)).minus(fee || BIG_ZERO)
  const zinuAsNumberBalance = getBalanceNumber(amountInZinu, decimals)
  const zinuAsBigNumber = getDecimalAmount(new BigNumber(zinuAsNumberBalance), decimals)
  const zinuAsDisplayBalance = getFullDisplayBalance(amountInZinu, decimals, decimalsToRound)
  return { zinuAsNumberBalance, zinuAsBigNumber, zinuAsDisplayBalance }
}

export const convertZinuToShares = (
  zinu: BigNumber,
  zinuPerFullShare: BigNumber,
  decimals = 18, // CHECKED-SD
  decimalsToRound = 3,
) => {
  const sharePriceNumber = getBalanceNumber(zinuPerFullShare, decimals)
  const amountInShares = new BigNumber(zinu.dividedBy(sharePriceNumber))
  const sharesAsNumberBalance = getBalanceNumber(amountInShares, decimals)
  const sharesAsBigNumber = getDecimalAmount(new BigNumber(sharesAsNumberBalance), decimals).decimalPlaces(0,1)
  const sharesAsDisplayBalance = getFullDisplayBalance(amountInShares, decimals, decimalsToRound)
  return { sharesAsNumberBalance, sharesAsBigNumber, sharesAsDisplayBalance }
}

const MANUAL_POOL_AUTO_COMPOUND_FREQUENCY = 0

export const getAprData = (pool: Pool.DeserializedPool<Token>, performanceFee: number) => {
  const { vaultKey, apr } = pool

  //   Estimate & manual for now. 288 = once every 5 mins. We can change once we have a better sense of this
  const autoCompoundFrequency = vaultKey
    ? vaultPoolConfig[vaultKey].autoCompoundFrequency
    : MANUAL_POOL_AUTO_COMPOUND_FREQUENCY

  if (vaultKey) {
    const autoApr = getApy(apr, autoCompoundFrequency, 365, performanceFee) * 100
    return { apr: autoApr, autoCompoundFrequency }
  }
  return { apr, autoCompoundFrequency }
}

export const getZinuVaultEarnings = (
  account: string,
  zinuAtLastUserAction: BigNumber,
  userShares: BigNumber,
  pricePerFullShare: BigNumber,
  earningTokenPrice: number,
  fee?: BigNumber,
) => {
  const hasAutoEarnings = account && zinuAtLastUserAction?.gt(0) && userShares?.gt(0)
  const { zinuAsBigNumber } = convertSharesToZinu(userShares, pricePerFullShare, ZINU_DECIMALS) // CHECKED-SD
  const autoZinuProfit = zinuAsBigNumber.minus(fee || BIG_ZERO).minus(zinuAtLastUserAction)
  const autoZinuToDisplay = autoZinuProfit.gte(0) ? getBalanceNumber(autoZinuProfit, ZINU_DECIMALS) : 0 // CHECKED-SD

  const autoUsdProfit = autoZinuProfit.times(earningTokenPrice)
  const autoUsdToDisplay = autoUsdProfit.gte(0) ? getBalanceNumber(autoUsdProfit, ZINU_DECIMALS) : 0 // CHECKED-SD
  return { hasAutoEarnings, autoZinuToDisplay, autoUsdToDisplay }
}

export const getPoolBlockInfo = memoize(
  (pool: Pool.DeserializedPool<Token>, currentBlock: number) => {
    const { startBlock, endBlock, isFinished } = pool
    const shouldShowBlockCountdown = Boolean(!isFinished && startBlock && endBlock)
    const blocksUntilStart = Math.max(startBlock - currentBlock, 0)
    console.log('endBlock', endBlock, 'currentBlock', currentBlock)
    const blocksRemaining = Math.max(endBlock - currentBlock, 0)
    const hasPoolStarted = blocksUntilStart === 0 && blocksRemaining > 0
    const blocksToDisplay = hasPoolStarted ? blocksRemaining : blocksUntilStart
    return { shouldShowBlockCountdown, blocksUntilStart, blocksRemaining, hasPoolStarted, blocksToDisplay }
  },
  (pool, currentBlock) => `${pool.startBlock}#${pool.endBlock}#${pool.isFinished}#${currentBlock}`,
)
