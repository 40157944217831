import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 612  197" {...props}>
      <path
        fill="#FFFFFF"
        d="M545.258,2.76c22.416,0,43.832,0,66.338,0c0,42.72,0,85.439-0.212,128.285
        c0.034,1.184,0.059,1.727-0.038,2.578c-4.792,23.12-17.355,40.505-38.254,51.668c-10.076,5.382-19.698,9.306-31.009,10.793
        c-0.769,0.102-2.167,0.348-3.38,0.348c-9.944,0-19.889,0-29.975-0.226c-4.896-0.622-9.091-1.801-13.482-2.912
        c-30.104-7.617-50.546-25.312-57.414-56.408c-1.717-7.772-1.719-15.925-2.458-24.176c0.057-28.099,0.066-55.925,0.061-83.75
        c0-3.76-0.074-7.519-0.032-11.548c0.124-5.063,0.166-9.857,0.208-14.651c22.459,0,44.917,0,67.639,0.271
        c0.374,1.884,0.484,3.498,0.539,5.385c-0.063,32.857-0.068,65.442-0.068,98.027c0,0.59,0.144,7.438,0.505,9.977
        c1.358,9.547,6.845,15.85,15.541,17.152c12.87,1.929,22.544-3.258,24.677-17.512c0.351-2.344,0.481-4.74,0.484-7.112
        c0.033-33.92,0.023-67.84,0.044-101.761C544.972,5.711,545.158,4.236,545.258,2.76z"
      />
      <path
        fill="#FFFFFF"
        d="M359.334,2.76c21.465,0,42.931,0,64.659,0.279c0.273,25.091,0.257,150.148-0.03,187.927
        c-0.86,0.122-66.737,0.072-67.657-0.143c-0.248-0.247-0.457-0.375-0.688-0.649c-0.021-0.146-0.266-0.313-0.307-0.527
        c-14.604-25.344-29.169-50.474-43.733-75.604c-0.213,0.065,0.861,51.326,1.61,76.904c-1.386,0.188-29.201,0.32-29.704,0.176
        c-1.32-5.367-5.025-6.373-9.64-5.976c-0.538,0.046-1.385-0.127-1.623-0.497c-2.468-3.85-4.844-7.758-7.226-11.662
        c-0.144-0.235-0.134-0.577-0.158-0.873c-0.495-6.109-0.473-12.349-5.142-17.503c4.419-1.686,8.251-3.146,12.083-4.608
        c-0.083-0.22-0.167-0.439-0.25-0.659c-1.625,0-3.251,0-4.876,0c-0.04-0.302-0.08-0.604-0.12-0.905
        c4.066-1.07,8.133-2.141,12.2-3.211c-0.046-0.202-6.988-0.194-10.412,0.014c-0.075-0.416-0.15-0.831-0.225-1.247
        c4.801-3.081,11.273-1.205,16.815-4.711c-1.558,0-2.252-0.072-2.927,0.012c-3.739,0.466-7.467,1.031-11.213,1.415
        c-0.849,0.086-1.757-0.416-2.638-0.647c0.686-0.699,1.251-1.722,2.082-2.029c1.652-0.611,13.813-3.208,18.336-4.225
        c-1.916-0.273-13.292,1.975-17.301,2.18c-1,0.052-1.6-0.81-1.414-0.953c0.918-0.706,1.837-1.538,2.586-1.698
        c2.582-0.641,15.454-3.276,18.134-5.427c-1.197,0.199-17.067,2.452-17.355,2.155c2.038-1.324,4.152-2.378,6.51-3.395
        c2.263-0.363,13.146-1.754,13.561-1.844c-0.499-4.043-0.79-8.126-1.545-12.121c-1.172-6.198-1.988-12.184,0.825-18.306
        c5.674-12.348,4.216-24.763-0.587-37.007c-2.252-5.74-6.294-9.793-12.218-11.757c-4.932-1.635-5.364-1.316-5.818,3.713
        c-0.008,0.089-0.084,0.172-0.234,0.464c-0.578-0.125-1.354-0.076-1.83-0.43c-2.668-1.982-4.402-0.845-5.693,1.637
        c-1.595,3.067-3.2,6.136-4.593,9.297c-0.809,1.836-1.847,2.695-3.876,2.217c-2.212-0.521-4.43-1.062-6.674-1.394
        c-2.235-0.331-3.05-1.32-3.018-3.688c0.136-9.925-0.055-46.39-0.11-54.695c22.26,0,44.52,0,66.869,0.165
        c15.23,27.09,30.371,54.017,45.529,80.932C360.484,84.189,359.608,15.446,359.334,2.76"
      />
      <path
        fill="#FFFFFF"
        d="M3.576,2.76c50.466,0,100.932,0,151.581,0.266c0.218,15.25,0.333,30.234,0.195,45.217
        c-0.019,1.977-0.844,4.347-2.108,5.852c-17.212,20.492-34.57,40.861-51.886,61.267c-4.589,5.408-9.155,10.836-14.207,16.816
        c2.047,0,3.39,0.001,4.732,0c20.745-0.014,41.49,0.008,62.235-0.085c2.564-0.012,3.43,0.724,3.415,3.387
        c-0.104,17.468-0.021,34.938-0.101,52.406c-0.005,1.147-0.853,2.291-1.584,3.392c-49.066-0.045-97.856-0.048-146.647-0.04
        c-1.48,0-2.961,0.104-4.654,0.074c-1.458-0.138-2.704-0.19-3.95-0.243c0-18.685,0-37.369,0.187-56.207
        c0.339-0.28,62.764-73.926,63.104-74.607c-0.814-0.045-38.296-0.048-56.559-0.011c-3.934,0.008-3.991,0.002-3.995-3.911
        C3.318,39.458,3.489,3.744,3.576,2.76z"
      />
      <path
        fill="#FFFFFF"
        d="M190.186,111.235c0.471,5.458,0.324,11.058,1.542,16.344c2.542,11.029,10.19,17.83,20.398,21.929
        c3.829,1.538,7.808,2.702,11.883,4.092c-0.308,0.632-0.467,1.214-0.818,1.637c-3.469,4.182-4.82,9.034-4.591,14.381
        c0.096,2.25-0.782,3.774-2.523,5.312c-1.797,1.585-3.245,3.746-4.326,5.914c-1.113,2.232-2.39,3.297-4.991,3.26
        c-5.011-0.071-6.958,1.849-7.433,6.901c-11.122,0-22.261,0-33.57,0c-0.046-0.979-0.051-186.602-0.048-187.967
        C188.32,2.76,210.977,2.76,233.914,2.76c0.288,18.352,0.282,36.705,0.324,55.056c0.005,2.512-0.885,3.805-3.976,3.096
        c-4.032-0.099-7.022-0.987-8.129-4.978c-0.041-0.147-0.701-0.122-1.074-0.146c-0.001,0.032,0.061,0.045,0.078-0.139
        c-1.6-2.374-2.835-5.137-4.966-6.55c-1.411-0.935-4.105,0.067-6.232,0.203c-0.075-1.368-0.154-2.818-0.247-4.524
        c-6.963,0.211-12.53,2.908-15.666,8.807c-4.676,8.798-7.169,18.334-5.429,28.364c0.806,4.643,1.542,9.133,0.362,13.993
        c0.391,3.274,0.859,6.322,1.34,9.63C190.34,107.21,190.188,110.904,190.186,111.235z"
      />
      <path
        fill="#FFFFFF"
        d="M228.833,61.754c-0.661,1.015-1.105,2.332-2.022,2.991c-3.775,2.714-7.542,5.5-11.59,7.761
        c-9.18,5.128-15.724,12.111-18.277,22.641c-1.303,5.373-3.439,10.544-5.36,15.718c-0.212-0.341-0.262-0.592-0.247-1.102
        c-0.005-1.666-0.075-3.074-0.132-4.706c-0.033-1.176-0.328-2.198-0.084-3.069c3.412-12.214,9.071-23.017,19.542-30.764
        c1.272-0.941,2.452-2.014,3.624-3.082c0.411-0.375,0.67-0.917,0.999-1.382c-12.379,7.936-21.22,18.526-24.636,33.223
        c-0.218-0.153-0.437-0.308-0.655-0.461c0-1.078,0-2.156,0.051-3.473c1.189-3.763,1.726-7.652,3.598-10.73
        c3.374-5.548,7.305-10.812,11.456-15.818c4.011-4.837,8.583-9.211,12.933-13.764c1.013-1.06,2.103-1.273,3.088,0.1
        c0,0-0.062-0.014-0.083,0.2c-0.808,2.024-1.595,3.834-2.48,5.869c1.073-0.533,2.047-1.124,3.098-1.5
        c0.635-0.227,1.428-0.288,2.081-0.132C225.455,60.681,227.136,61.25,228.833,61.754z"
      />
      <path
        fill="#FFFFFF"
        d="M285.818,85.514c-4.494-5.03-8.661-10.05-13.204-14.704c-2.107-2.158-3.642-4.084-2.771-7.228
        c0.603-2.177,0.709-4.555,1.639-6.565c2.007-4.342,6.583-5.315,10.43-2.476c3.636,2.684,5.855,6.477,6.249,10.784
        c0.51,5.581,0.27,11.248,0.042,16.865C288.155,83.379,286.764,84.514,285.818,85.514z"
      />
      <path
        fill="#FFFFFF"
        d="M270.914,111.107c-4.474,1.795-8.83,2.206-12.725-0.452c-1.683-1.148-3.404-3.38-3.547-5.239
        c-0.094-1.219,2.395-3.159,4.105-3.888c3.981-1.694,8.12-3.155,12.329-4.145c4.697-1.104,7.391,2.127,6.113,6.787
        C276.288,107.458,274.203,109.676,270.914,111.107z"
      />
      <path
        fill="#FFFFFF"
        d="M205.201,106.701c-0.434-0.806-1.001-1.442-0.978-2.058c0.08-2.166-0.359-4.926,0.788-6.302
        c0.997-1.195,4.069-1.515,5.883-1.005c4.459,1.252,8.763,3.107,13.046,4.916c2.365,0.999,3.083,3.174,1.445,5.083
        c-1.529,1.783-3.6,3.471-5.773,4.258C214.6,113.406,208.64,111.194,205.201,106.701z"
      />
      <path
        fill="#FFFFFF"
        d="M194.182,73.104c0.247-6.065,0.68-11.77,4.769-16.43c3.984-4.541,8.354-4.83,12.388-0.571
        c0.518,0.546,0.731,2.146,0.326,2.687c-5.45,7.255-11.036,14.409-17.165,22.333C194.374,78.088,194.277,75.74,194.182,73.104z"
      />
    </Svg>
  );
};

export default Icon;
