import { ChainId, ERC20Token, WETH9 } from '@zinuswap/sdk'
import { USDC, USDT, WBTC_ETH, ZINU_ETHEREUM } from '@zinuswap/tokens'

export const ethereumTokens = {
  zinu: ZINU_ETHEREUM,
  weth: WETH9[ChainId.ETHEREUM],
  usdt: USDT[ChainId.ETHEREUM],
  usdc: USDC[ChainId.ETHEREUM],
  wbtc: WBTC_ETH,
  syrup: new ERC20Token(
    ChainId.GOERLI,
    '0x85E5e0AF2BADD5641f658CA4239320a33D6a88fE',
    18,
    'SYRUP',
    'SyrupBar Token',
    'https://zinustaking.com/',
  ),
}
