import { FixedNumber } from '@ethersproject/bignumber'
import { getFullDecimalMultiplier } from './getFullDecimalMultiplier'

export const FIXED_ZERO = FixedNumber.from(0)
export const FIXED_ONE = FixedNumber.from(1)
export const FIXED_TWO = FixedNumber.from(2)

export const masterChefAddresses = {
  97: '0x1F8ffa3F84a4F13f9e5bA960787B01E2E57e5777', // SOLVED-SD
  56: '0x47CCc3b57cf9cfE8f863a3EEc5a2B563EFbC7E9a', // TODO-SD
  1: '0x15f9355a26461F2Ce955d2B4e7eB9D313D3F6d45', // SOLVED-SD
  5: '0xD349c5b4Ce55294e1104d68A47E0F63972de4D64', // SOLVED-SD
}
