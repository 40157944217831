import get from "lodash/get";
import { DefaultTheme } from "styled-components";

const getThemeValue = (theme: DefaultTheme, path: string, fallback?: string | number): string => {
  if (fallback) {
    return get(theme, path, fallback+'');
  }
  return get(theme, path);
}
  

export default getThemeValue;
