import { Pool } from '@zinuswap/uikit'
import { SerializedWrappedToken } from '@zinuswap/token-lists'
import { bscTokens } from '@zinuswap/tokens'
import { PoolCategory } from '../types'


export const livePools: Pool.SerializedPoolConfig<SerializedWrappedToken>[] = [
  {
    sousId: 0,
    stakingToken: bscTokens.zinu,
    earningToken: bscTokens.zinu,
    contractAddress: '0x3eceE3d4152581Be5773D170159E9eA63a2b7dD0', // TODO-SD
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '10',
  },

  // {
  //   sousId: 1,
  //   stakingToken: bscTokens.zinu,
  //   earningToken: bscTokens.wbnb,
  //   contractAddress: '0x645129f6cB30b5A679aCF4E9718c52D13C5EfDea', // SOLVED-SD
  //   poolCategory: PoolCategory.CORE,
  //   tokenPerBlock: '0.00001',
  //   version: 3,
  //   depositFeeBP: 4,
  // },
  {
    sousId: 1,
    stakingToken: bscTokens.zinu,
    earningToken: bscTokens.zinu,
    contractAddress: '0x3eceE3d4152581Be5773D170159E9eA63a2b7dD0', // SOLVED-SD
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '10'
  },
  // {
  //   sousId: 1,
  //   stakingToken: bscTokens.zinu,
  //   earningToken: bscTokens.zinu,
  //   contractAddress: '0xD492FF719e10FFb48fE17aB7B62910B3FA1FDD98', // TODO-SD
  //   poolCategory: PoolCategory.CORE,
  //   tokenPerBlock: '10',
  // },
].map((p) => ({
  ...p,
  stakingToken: p.stakingToken.serialize,
  earningToken: p.earningToken.serialize,
}))

// known finished pools
const finishedPools = [].map((p) => ({
  ...p,
  isFinished: true,
  stakingToken: p.stakingToken.serialize,
  earningToken: p.earningToken.serialize,
}))

export default [...livePools, ...finishedPools] as Pool.SerializedPoolConfig<SerializedWrappedToken>[]













