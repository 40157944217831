import BigNumber from 'bignumber.js'
import { multicallv2, multicallv3 } from 'utils/multicall'
import zinuAbi from 'config/abi/zinu.json'
import zinuVaultAbi from 'config/abi/zinuVaultV2.json'
import { getZinuVaultAddress } from 'utils/addressHelpers'
import { BIG_ZERO } from '@zinuswap/utils/bigNumber'
import { ZINU } from '@zinuswap/tokens'



export const fetchPublicVaultData = async (zinuVaultAddress, chainId) => {
  try {
    const zinuVaultV2 = getZinuVaultAddress(chainId)


    const calls = ['getPricePerFullShare', 'totalShares', 'totalLockedAmount'].map((method) => ({
      abi: zinuVaultAbi,
      address: zinuVaultAddress,
      name: method,
    }))

    const zinuBalanceOfCall = {
      abi: zinuAbi,
      address: ZINU[chainId].address, // SOLVED-SD
      name: 'balanceOf',
      params: [zinuVaultV2],
    }

    const [[sharePrice], [shares], totalLockedAmount, [totalZinuInVault]] = await multicallv3({
      calls: [...calls, zinuBalanceOfCall],
      allowFailure: true,
      chainId
    })
    
    const totalSharesAsBigNumber = shares ? new BigNumber(shares.toString()) : BIG_ZERO
    const totalLockedAmountAsBigNumber = totalLockedAmount ? new BigNumber(totalLockedAmount[0].toString()) : BIG_ZERO
    const sharePriceAsBigNumber = sharePrice ? new BigNumber(sharePrice.toString()) : BIG_ZERO
    return {
      totalShares: totalSharesAsBigNumber.toJSON(),
      totalLockedAmount: totalLockedAmountAsBigNumber.toJSON(),
      pricePerFullShare: sharePriceAsBigNumber.toJSON(),
      totalZinuInVault: new BigNumber(totalZinuInVault.toString()).toJSON(),
    }
  } catch (error) {
    return {
      totalShares: null,
      totalLockedAmount: null,
      pricePerFullShare: null,
      totalZinuInVault: null,
    }
  }
}

export const fetchPublicFlexibleSideVaultData = async (zinuVaultAddress, chainId) => {
  try {
    const calls = ['getPricePerFullShare', 'totalShares'].map((method) => ({
      abi: zinuVaultAbi,
      address: zinuVaultAddress,
      name: method,
    }))

    const zinuBalanceOfCall = {
      abi: zinuAbi,
      address: ZINU[chainId].address,
      name: 'balanceOf',
      params: [zinuVaultAddress],
    }

    const [[sharePrice], [shares], [totalZinuInVault]] = await multicallv3({
      calls: [...calls, zinuBalanceOfCall],
      allowFailure: true,
      chainId
    })

    const totalSharesAsBigNumber = shares ? new BigNumber(shares.toString()) : BIG_ZERO
    const sharePriceAsBigNumber = sharePrice ? new BigNumber(sharePrice.toString()) : BIG_ZERO
    return {
      totalShares: totalSharesAsBigNumber.toJSON(),
      pricePerFullShare: sharePriceAsBigNumber.toJSON(),
      totalZinuInVault: new BigNumber(totalZinuInVault.toString()).toJSON(),
    }
  } catch (error) {
    return {
      totalShares: null,
      pricePerFullShare: null,
      totalZinuInVault: null,
    }
  }
}

export const fetchVaultFees = async (zinuVaultAddress, chainId) => {
  try {
    const calls = ['performanceFee', 'withdrawFee', 'withdrawFeePeriod'].map((method) => ({
      address: zinuVaultAddress,
      name: method,
    }))

    const [[performanceFee], [withdrawalFee], [withdrawalFeePeriod]] = await multicallv2({ abi: zinuVaultAbi, calls, chainId })

    return {
      performanceFee: performanceFee.toNumber(),
      withdrawalFee: withdrawalFee.toNumber(),
      withdrawalFeePeriod: withdrawalFeePeriod.toNumber(),
    }
  } catch (error) {
    return {
      performanceFee: null,
      withdrawalFee: null,
      withdrawalFeePeriod: null,
    }
  }
}

export default fetchPublicVaultData
