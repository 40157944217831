import { multicallAddresses } from '@zinuswap/multicall'

export default {
  masterChef: {
    97: '', // SOLVED-SD
    56: '', // TODO-SD
    1: '', // SOLVED-SD
    5: '', // SOLVED-SD
  },
  masterChefV1: {
    97: '', // SOLVED-SD
    56: '', 
    1: '', // SOLVED-SD
    5: '', // SOLVED-SD
  },
  sousChef: {
    97: '',
    56: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
  },
  multiCall: multicallAddresses,
  zinuVault: {
    56: '0x1F83F8088Dd52a6e1a72F5ABE3D3CB709501B68E', // TODO-SD
    97: '0x2A481cDD59B43e325C58A5912C4Fba144a86956E', // SOLVED-SD
    1: '0xA39391aaf379c9791755ad827d9F583d6AED1460', // SOLVED-SD
    5: '0xa4473cEc2eC6b02F2bCf1B7658d54aB3d907C302', // SOLVED-SD
  },
  zinuFlexibleSideVault: {
    56: '',
    97: '', // SOLVED-SD
    1: '', // SOLVED-SD
    5: '', // SOLVED-SD
  },
  // TODO: multi
  zap: {
    56: '',
    97: '',
  },
  bZinuFarmBooster: {
    56: '',
    97: '',
  },
  bZinuFarmBoosterProxyFactory: {
    56: '',
    97: '',
  },
}
