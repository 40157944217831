import { Pool } from '@zinuswap/uikit'
import { SerializedWrappedToken } from '@zinuswap/token-lists'
import { ethereumTokens } from '@zinuswap/tokens'
import { PoolCategory } from '../types'


export const livePools: Pool.SerializedPoolConfig<SerializedWrappedToken>[] = [
  {
    sousId: 2000,
    stakingToken: ethereumTokens.zinu,
    earningToken: ethereumTokens.zinu,
    contractAddress: '0x3eceE3d4152581Be5773D170159E9eA63a2b7dD0',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '30',
    isFinished: false,
  },
  {
    sousId: 2001,
    stakingToken: ethereumTokens.zinu,
    earningToken: ethereumTokens.zinu,
    contractAddress: '0x3eceE3d4152581Be5773D170159E9eA63a2b7dD0',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '30',
    isFinished: false,
  },
].map((p) => ({
  ...p,
  stakingToken: p.stakingToken.serialize,
  earningToken: p.earningToken.serialize,
}))

// known finished pools
const finishedPools = [].map((p) => ({
  ...p,
  isFinished: true,
  stakingToken: p.stakingToken.serialize,
  earningToken: p.earningToken.serialize,
}))

export default [...livePools, ...finishedPools] as Pool.SerializedPoolConfig<SerializedWrappedToken>[]
